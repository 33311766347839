#vrtx-structured-event {
  h1,
  .vrtx-introduction {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  #breadcrumbs .event-back-link a {
    padding-left: 0;
    text-decoration: underline;
    &:before {
      background: none;
    }
    &:hover,
    &:focus {
      color: var(--linkColor);
      text-decoration-color: var(--linkColor);
    }
  }

  #vrtx-content {
    .vrtx-introduction + .vrtx-introduction-image:not(.small-intro-image),
    .vrtx-introduction-image + .vrtx-introduction {
      margin-top: 0;
    }
    .vrtx-byline {
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: 0;
    }
    .time-and-place-columns {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .column  {
        flex: 1 0 24%; /* explanation below */
        margin: 0 0 10px 0;
        height: fit-content;
        height: -moz-fit-content;
        border-left: 3px solid var(--green2);
        padding: 0 20px;
        .header,
        .value,
        .urls {
          display: block;
        }
        .header {
          margin-bottom: 5px;
        }

        .urls {
          margin-top: 10px;
        }
      }
    }
    #vrtx-main-content .vrtx-introduction-image {
      max-width: 690px !important;
      margin: 50px auto;
      img {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    /*  a:not(.button):not(.vrtx-link-check),
    #vrtx-main-content .only-links li a:hover,
    #vrtx-main-content .only-links li a:focus {
      color: var(--linkColor);
      text-decoration-color: var(--linkColor);
    }*/

    #vrtx-main-content .only-links li a {
      color: var(--textColor);
      text-decoration-color: var(--textColor);
    }

    .button {
      background-color: var(--eventBoxColor);
      &:hover, &:focus {
        background-color: var(--eventBoxColorHover);
      }
      &.orange {
        background-color: transparent;
        &:focus, &:hover {
          background-color: var(--btnPrimaryBgHover);
          border-color: var(--btnPrimaryBgHover);
        }
      }
    }
  }
}

// Responsive only
@media @responsiveBreakpoint {
  #vrtx-structured-event {
    #vrtx-content {
      .time-and-place-columns {
        display: block;
        a {
          position: relative;
          display: block;
          bottom: 0;
          margin: 0 0 10px 0;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
