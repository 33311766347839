// For use on www.uio.no frontpage only

.frontpage-uio-orange-img-left,
.frontpage-uio-orange-img-right {
  background-color: #FFEBD9;
}
.frontpage-uio-blue-img-left,
.frontpage-uio-blue-img-right {
  background-color: #E6ECFF;
}
.frontpage-uio-green-img-left,
.frontpage-uio-green-img-right {
  background-color: #CEFFDF;
}
.frontpage-uio-yellow-img-left,
.frontpage-uio-yellow-img-right {
  background-color: #FFFEA7;
}

.frontpage-uio-orange-img-left,
.frontpage-uio-blue-img-left,
.frontpage-uio-green-img-left,
.frontpage-uio-yellow-img-left,
.frontpage-uio-orange-img-right,
.frontpage-uio-blue-img-right,
.frontpage-uio-green-img-right,
.frontpage-uio-yellow-img-right {
  &.vrtx-frontpage-box {
    margin-left: -195px;
    display: grid;
    &:after {
      display: none; // remove float clearing
    }

    .mobile-image {
      display: none;
    }
  
    &.vrtx-frontpage-box-picture {
      .vrtx-frontpage-box-picture {
        a {
          display: block;
        }
        margin: 0 0 20px 0;
        max-width: 100%;
    
        &:last-child {
          margin-bottom: 0;
        }
      }
      .vrtx-frontpage-box-picture {
        display: grid; // control img height (or safari stretches to viewport)
        margin: 0;
        img {
          display: block;
          max-width: none;
          object-fit: cover;
          height: 100%;
          width: 100%;
          aspect-ratio: 16/9;
        }
      }
      .vrtx-box-content {
        align-self: center;
        justify-self: center;
        max-width: 70%;
        font-size: 2.6rem;
        line-height: 1.5;
        padding: 40px 0;

        > h2:first-child {
          margin-bottom: 40px;
        }
      }
      .read-more {
        text-decoration-color: currentColor;

        &:before {
          text-decoration: currentColor solid #000;
        }
      }
    }
  }
}

.frontpage-uio-orange-img-left,
.frontpage-uio-blue-img-left,
.frontpage-uio-green-img-left,
.frontpage-uio-yellow-img-left {
  &.vrtx-frontpage-box {
    grid-template-columns: 3fr 2fr; // 3fr=837px width when desktop as wanted by AKS
  }
}
.frontpage-uio-orange-img-right,
.frontpage-uio-blue-img-right,
.frontpage-uio-green-img-right,
.frontpage-uio-yellow-img-right {
  &.vrtx-frontpage-box {
    grid-template-columns: 2fr 3fr; // 23fr=837px width when desktop as wanted by AKS
    .vrtx-frontpage-box-picture {
      order: 2;
    }
  }
}

@media @desktopSmallBreakpoint {
  .frontpage-uio-orange-img-left,
  .frontpage-uio-blue-img-left,
  .frontpage-uio-green-img-left,
  .frontpage-uio-yellow-img-left,
  .frontpage-uio-orange-img-right,
  .frontpage-uio-blue-img-right,
  .frontpage-uio-green-img-right,
  .frontpage-uio-yellow-img-right {
    &.vrtx-frontpage-box {
      margin-left: -25px;
      margin-right: -20px;

      &.vrtx-frontpage-box-picture {
        .vrtx-frontpage-box-picture {
          img {
            aspect-ratio: 4/3;
          }
        }
      }
    }
  }

  .frontpage-uio-orange-img-left,
  .frontpage-uio-blue-img-left,
  .frontpage-uio-green-img-left,
  .frontpage-uio-yellow-img-left {
    &.vrtx-frontpage-box {
      grid-template-columns: 2.5fr 3fr;
    }
  }
  .frontpage-uio-orange-img-right,
  .frontpage-uio-blue-img-right,
  .frontpage-uio-green-img-right,
  .frontpage-uio-yellow-img-right {
    &.vrtx-frontpage-box {
      grid-template-columns: 3fr 2.5fr;
    }
  }
}

@media @responsiveBreakpoint {
  #vrtx-frontpage #main {
    .frontpage-uio-orange-img-left,
    .frontpage-uio-blue-img-left,
    .frontpage-uio-green-img-left,
    .frontpage-uio-yellow-img-left,
    .frontpage-uio-orange-img-right,
    .frontpage-uio-blue-img-right,
    .frontpage-uio-green-img-right,
    .frontpage-uio-yellow-img-right {
      &.vrtx-frontpage-box {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: 1fr;

        .mobile-image {
          display: block;
          margin-left: -15px;
          margin-right: -15px;

          img {
            aspect-ratio: 4/3;
            display: block;
          }
        }

        &.vrtx-frontpage-box-picture {
          &:not(.sub-header-box-no-mobile-image) {
            .vrtx-frontpage-box-picture {
              display: none;
            }
          }
        }
        
        .vrtx-box-content {
          max-width: none;
          padding: 30px 15px 40px 15px;

          .mobile-image:first-child {
            margin-top: -30px;
          }
          .mobile-image:last-child {
            margin-bottom: -40px;
            margin-top: 40px;
          }
        }
      }
    }
  }
}

// UiO institute / faculty / centre frontpage-img-big / frontpage-img

.vrtx-frontpage-box.sub-header-box-big,
.vrtx-frontpage-box.sub-header-box {
  .mobile-image {
    display: none;
  }
  &.vrtx-frontpage-box-picture {
    max-height: 1240px;
    .mobile-image,
    .vrtx-frontpage-box-picture {
      position: relative;

      &:after {
        content: "";
        height: 140px;
        width: 140px;
        position: absolute;
        bottom: 30px;
        right: 25px;
        background: url("@{imagePath}sidebar/seal.svg") no-repeat 0 0;
        background-size: 100% auto;
      }
    }
    &.sub-header-white-box-content {
      .vrtx-more a,
      .vrtx-box-content a,
      .vrtx-box-content {
        color: #fff;
      }
      .vrtx-more a {
        box-shadow: 0 0.03em 0 0 #fff;

        &:before,
        &:after {
          background-image: url("@{imagePath}arrows/arrow-big-white.svg");
        }
      }
    }
    &.sub-header-white-text-logo {
      .mobile-image,
      .vrtx-frontpage-box-picture {
        &:after {
          background-image: url("@{imagePath}sidebar/seal-white.svg");
        }
      }
    }
    &.sub-header-white-seal {
      .mobile-image,
      .vrtx-frontpage-box-picture {
        &:after {
          background-image: url("@{imagePath}sidebar/seal-white.svg");
        }
      }
    }
    &.sub-header-box-no-seal {
      .mobile-image,
      .vrtx-frontpage-box-picture {
        &:after {
          display: none;
        }
      }
    }
  }
}

body.sub-header-white-text-logo-header:not(.sidebar-menu-wrapper-visible) {
  .uio-app-name {
    .uio-host {
      color: #fff;
    }
  }
}

.vrtx-frontpage-box.sub-header-box-big,
.vrtx-frontpage-box.sub-header-box {
  &.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
    margin: 0 0 30px 0;

    &:only-child {
      margin-bottom: 0;
    }
  }
}

.vrtx-frontpage-box.sub-header-box-big {
  &.vrtx-frontpage-box-picture {
    margin-left: -195px;
    max-width: 1415px;
    .vrtx-frontpage-box-picture {
      img {
        width: 100%;
        object-fit: cover;
        object-position: 50% 0;
        height: 515px;
      }
    }
    .vrtx-box-content,
    .vrtx-more {
      margin-left: 195px;
    }
  }
  &.motive-left {
    &.vrtx-frontpage-box-picture {
      .vrtx-frontpage-box-picture {
        img {
          object-position: 20% 0;
        }
      }
    }
  }
  &.motive-right {
    &.vrtx-frontpage-box-picture {
      .vrtx-frontpage-box-picture {
        img {
          object-position: 80% 0;
        }
      }
    }
  }
}

// Extra specificity to remove link underline
.main .vrtx-frontpage-box.sub-header-box {
  .vrtx-box-content {
    ul:not(.only-links):not(.items) li a:not(.button):not(.button-large):not(.button-large-blue) {
      .textUnderlineSwitch;
    }
  }
}

// Collaboration links

ul.collaboration-part-of {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px 40px;

  li {
    a {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
    display: inline-block;
    margin-bottom: 0;
    margin-left: 0;

    &:before {
      display: none;
    }

    // Title
    &:first-child {
      margin-left: 0;
      max-width: 180px;
    }

    &.collaboration-part-of-image {
      a {
        height: 100px;
        display: block;

        img {
          height: 100% !important;
          width: auto !important;
          max-width: auto;
        }
      }
    }
    &.collaboration-part-of-uio a {
      display: block;
      overflow: hidden;
      text-indent: -10000px;
      width: 126px;
      height: 25px;
      background: url("@{imagePath}header/uio-logo-no.svg") no-repeat 0 0;
      background-size: auto 100%;
    }
    &.collaboration-part-of-subunit {
      margin-top: -10px;

      a {
        padding: 5px 10px;
        background: #f7f9ff;

        .textUnderlineSwitch;
      }
    }
  }
}

body.en {
  ul.collaboration-part-of {
    li {
      &.collaboration-part-of-uio a {
        width: 96px;
        background-image: url("@{imagePath}header/uio-logo-en.svg");
        background-size: auto 100%;
      }
    }
  }
}

@media @desktopSmallBreakpoint {
  .vrtx-frontpage-box.sub-header-box-big.vrtx-frontpage-box-picture {
    margin-left: 0;
  }

  body#vrtx-frontpage {
    #main .vrtx-frontpage-box.sub-header-box,
    #main .vrtx-frontpage-box.sub-header-box-big {
      &.vrtx-frontpage-box-picture {
        .mobile-image,
        .vrtx-frontpage-box-picture {
          position: relative;
          &:after {
            height: 70px;
            width: 70px;
            bottom: 20px;
            right: 20px;
          }
        }
      }
    }
  }

  .vrtx-frontpage-box.sub-header-box-big,
  .vrtx-frontpage-box.sub-header-box {
    &.vrtx-frontpage-box-picture.sub-header-white-box-content {
      .vrtx-more a,
      .vrtx-box-content a,
      .vrtx-box-content {
        color: @textColor;
      }
      .vrtx-more a {
        box-shadow: 0 0.03em 0 0 @textColor;

        &:before,
        &:after {
          background-image: url("@{imagePath}arrows/arrow-big.svg");
        }
      }
    }
  }

  body.sub-header-white-text-logo-header:not(.sidebar-menu-wrapper-visible) {
    .uio-app-name {
      .uio-host {
        color: @textColor;
      }
    }
  }

  #main .vrtx-frontpage-box.sub-header-box-big,
  #main .vrtx-frontpage-box.sub-header-box {
    padding-left: 0;
    padding-right: 0;
    .vrtx-box-content {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  #main .vrtx-frontpage-box.sub-header-box-big,
  #main .vrtx-frontpage-box.sub-header-box {
    &.vrtx-frontpage-box-picture {
      .vrtx-frontpage-box-picture {
        min-height: 0;
        position: static;
      }
    }
    .vrtx-box-content {
      position: static;
    }
    .vrtx-box-content,
    .vrtx-more {
      min-width: 0;
      max-width: none;
      margin-left: 0;
    }
  }
}

@media @desktopSmallOnlyBreakpoint {
  #main .vrtx-frontpage-box.sub-header-box,
  #main .vrtx-frontpage-box.sub-header-box-big {
    .vrtx-box-content {
      padding-left: 25px;
      padding-right: 20px;
    }
  }
  .vrtx-frontpage-box.sub-header-box,
  .vrtx-frontpage-box.sub-header-box-big {
    .vrtx-box-content,
    .vrtx-more {
      margin-left: 25px;
      max-width: none;
      min-width: 0;
    }
  }
}

@media @responsiveBreakpoint {
  #vrtx-frontpage #main {
    .vrtx-frontpage-box.sub-header-box-big,
    .vrtx-frontpage-box.sub-header-box {
      padding-left: 0;
      padding-right: 0;

      .mobile-image {
        display: block;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 30px;

        img {
          display: block;
        }
      }
      &.vrtx-frontpage-box-picture {
        &:not(.sub-header-box-no-mobile-image) {
          .vrtx-frontpage-box-picture {
            display: none;
          }
        }
      }
    }

    .vrtx-frontpage-box.sub-header-box-big {
      &.vrtx-frontpage-box-picture {
        .vrtx-frontpage-box-picture {
          img {
            height: 350px;
          }
        }
      }
    }
  }

  #main ul.collaboration-part-of {
    display: block;

    li {
      margin-left: 0;
      display: block;
      margin-bottom: 20px;

      &.collaboration-part-of-subunit {
        margin-top: 15px;
      }
    }
  }
}


// Edit
.vrtx-editor-frontpage {
  .frontpage-uio-orange-img-left,
  .frontpage-uio-blue-img-left,
  .frontpage-uio-green-img-left,
  .frontpage-uio-yellow-img-left,
  .frontpage-uio-orange-img-right,
  .frontpage-uio-blue-img-right,
  .frontpage-uio-green-img-right,
  .frontpage-uio-yellow-img-right,
  .vrtx-frontpage-box.sub-header-box-big,
  .vrtx-frontpage-box.sub-header-box {
    &.vrtx-frontpage-box {
      margin-left: 0px;
    }
  }
}