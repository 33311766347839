.vrtx-comments {
  background: var(--alternateRowColor);
  padding: @verticalGap 0;
  margin: @verticalGap 0;

  >* {
    max-width: @gridWidth;
    min-width: @gridMinWidth;
    padding-right: @contentGapRight;
    padding-left: @sidebarWidth;
    margin-left: auto;
    margin-right: auto;
  }

  #comments-header-left {
    display: inline-block;
    font-size: 3.6rem;
    line-height: 4.2rem;
    letter-spacing: .38px;
    margin-bottom: 40px;

    a {
      .textUnderlineSwitch;
    }
  }
  #comments-header-feedHref {
    float: right;
  }

  .vrtx-comment {
    margin-bottom: 80px;

    .comment-info {
      .baseFontSize;
      color: #333;
      margin-bottom: 10px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .add-comment {
    .add-comment-header {
      font-size: 2.7rem;
      line-height: 3.5rem;
      letter-spacing: 0.39px;
      border-top: 1px solid #000;
      padding-top: 20px;
      margin-bottom: 30px;
    }
    .button:not(.feedback-yes):not(.feedback-no) {
      padding-right: 19px;
      &:after {
        display: none;
      }
    }
    #add-comment-login,
    #add-comment-webid {
      float: left;
    }
    #add-comment-login {
      .button {
        display: inline;
        margin-right: 10px;
      }
    }
    #add-comment-webid {
      padding-left: 25px;
      border-left: 1px solid #000;
      margin-left: 55px;

      p {
        margin: 0;
      }
    }

    &:after {
      .clearfix();
    }

    .submit {
      margin-top: 20px;
    }
  }

  #submit-comment-button {
    margin-right: 10px;
  }

  .button,
  input[type="submit"],
  input[type="button"],
  button {
    width: auto;
  }
  .vrtx-comments-delete-all,
  .comment-delete-button {
    float: right;
    position: relative;
    top: -20px;
  }
  .vrtx-comments-delete-all {
    margin-left: 25px;
    top: -15px;
  }
}

@media @desktopSmallOnlyBreakpoint {
  .vrtx-comments >* {
    min-width: @responsiveBreakpointPx;
  }
}

@media @responsiveBreakpoint {

  .vrtx-comments {
    padding-top: ~"calc(@{verticalGap} / 2)";
    padding-bottom: ~"calc(@{verticalGap} / 2)";
    margin-top: ~"calc(@{verticalGap} / 2)";
    margin-bottom: ~"calc(@{verticalGap} / 2)";

    >* {
      max-width: none;
      min-width: 0;
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 0;
      margin-right: 0;
    }
    #add-comment-login,
    #add-comment-webid,
    #comments-header-left,
    #comments-header-feedHref {
      float: none;
    }

    #comments-header-left {
      font-size: 3rem;
      line-height: 4rem;
      letter-spacing: 0.33px;
    }

    #comments-header-feedHref {
      margin: -40px 0 40px;
    }

    .vrtx-comment {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #000;

      &:last-child {
        border-bottom: none;
      }
    }

    .add-comment {
      .add-comment-header {
        font-size: 2.6rem;
        line-height: 3.2rem;
        letter-spacing: 0.37px;
        border-top: none;
        padding-top: 0;
      }
      #add-comment-webid {
        margin-top: 10px;
        margin-left: 0;
        padding-left: 0;
        border-left: 0;
        clear: left;
      }
    }
  }
}

// Is alwasy before #footer-wrapper if not blog context, remove space under
#vrtx-structured-article:not(.blog) {
  .vrtx-comments {
    margin-bottom: 0;
  }
}
