table {
  width: 100%;
  border: 1px solid var(--colorNeutralTint80);
  margin: 40px 0;

  a {
    .breakWord();
  }

  caption {
    margin: 0 0 20px 0;
  }
  th,
  td {
      padding: 16px 20px;
      border: 1px solid var(--colorNeutralTint80);
      vertical-align: top;
  }
  th {
      color: #000;
      border-width: 0 1px 1px 0;
  }
  td {
      border-width: 0 1px 1px 0;
  }
  th > *,
  td > * {
    &:first-child { margin-top: 0;    }
    &:last-child  { margin-bottom: 0; }
  }

  // TODO: add minimal class to these tables?
  &.vrtx-person-listing,
  &.vrtx-unit-listing,
  &#vrtx-course-description-listing-results,
  &#vrtx-course-group-listing-results,
  &#vrtx-student-exchange-agreement-listing-results,
  &#vrtx-student-exchange-university-agreement-listing-results,
  &#vrtx-helseforsk-listing-results,
  &#vrtx-personal-data-listing-results,
  &.minimal {
    border-width: 0;

    caption {
      margin-bottom: 0;
    }
    th,
    td {
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }
    }
    th {
      border-width: 0 0 1px 0;
    }
    td {
      border-width: 0 0 1px 0;
    }

    &.horizontal th {
      border-width: 0 0 1px 0;
    }
  }

  &.zebra,
  &.uio-zebra {
    tr:nth-child(odd) td {
      background-color: @tableZebraGreyBg;
    }
  }

  &.table-fixed-layout,
  &.fixed-layout {
    table-layout: fixed;
    word-wrap: break-word;
  }

  // Used for org.maps, e.g. here: https://www.uio.no/studier/om/studier-og-grader/
  &.table-no-padding {
    line-height: 0;
    font-size: 1px;
    width: auto;

    td, th {
      line-height: 0;
      padding: 0;
    }
  }
  &.no-border,
  &[border='0'] {
    &,
    th,
    td {
      border: none;
    }
  }
  &.vertical {
    th {
      border-width: 0 1px 1px 0;
    }
    thead th[scope='row'] {
      border-width: 0 1px 1px 0;
    }
    tbody th[scope='row'] {
      border-width: 0 1px 1px 0;
      width: 1%;
      white-space: nowrap;
    }

    &.minimal {
      th {
        border-width: 0 0 1px 0;
      }
      thead th[scope='row'] {
        border-width: 0;
      }
      tbody th[scope='row'] {
        border-width: 0 1px 0 0;
      }
      thead th:nth-child(2),
      tbody th[scope='row'] + td {
        padding-left: 15px;
      }
      td {
        border-width: 0 0 0 0;
      }
    }
  }

  &.retro,
  &#vacation-table {
    font-size: 1.4rem;
    line-height: 2rem;

    td, th {
      padding: 7px;
    }
  }

  &#vrtx-course-description-listing-results {
    th {
      white-space: nowrap;
    }
    // responsive handling. element shown below breakpoint only.
    .vrtx-study-results-responsive-info {
      display: none;
    }
  }
}

// Responsive table for Utvekslingsavtalelisting (student exchange agreement listing)
#vrtx-student-exchange-agreement-listing-results {
  span.responsive-content {
    display: none;
  }
}

.table-wide-overflow-wrapper {
  display: block;
  overflow: visible;
  overflow-x: scroll;
  max-width: none !important;
  margin-left: ~"calc(calc(calc(calc(var(--content-width) - calc(100%))/ 2)) * -1)";
  margin-right: 0 !important;
  position: relative !important;
  width: var(--content-wrap-width);
}
@media only screen and (min-width: 1071px) and (max-width: 1179px) {
  .table-wide-overflow-wrapper {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1180px) and (max-width: 1215px) {
  .table-wide-overflow-wrapper {
    margin-left: ~"calc(calc(calc(calc(100%) - 690px - calc(calc(100%) - 1000px))/ 2) * -1)";
  }
}

.table-wide-no-scroll-wrapper {
  position: absolute;
  left: 0;
  padding: 0 15px;
  overflow-x: auto;
}

.table-wide-overflow.table-fixed-first-col {
  position: relative;

  td, th {
    white-space: nowrap;

    &:first-child {
      width: 200px;
      position: sticky;
      left: 0;
      margin-top: 0;
      top: auto;
      background: rgb(219, 222, 226);
    }
  }
}

iframe {
  max-width: 100%;
}

 @media @responsiveBreakpoint {
  // Responsive table for Utvekslingsavtalelisting
  #vrtx-student-exchange-agreement-listing-results {
    span.responsive-content {
      display: inline-block;
    }
    margin: 20px 0;
    thead, tbody, tr, th, td {
      display: block;
    }
    thead {
      display: none;
    }
    tr {
      border-bottom: 1px solid var(--colorNeutralTint80);
      padding: 20px 0;
    }
    td {
      border: none;
      padding: 0;
      &:first-child {
        padding-bottom: 10px;
      }
    }
  }
  
  .table-wrapper-responsive {
    overflow: visible;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 40px;
    /* margin-right: -15px; */

    &.no-scroll {
      margin-right: 0px;
    }
    table {
      margin-bottom: 0px;
    }
  }

  html:not(.print-hidden) #main table.responsive-table-accordions-title {
    display: block;
    border: none;
    margin: 0 -15px;
    width: auto !important;

    tbody,
    tr,
    td {
      display: block;
    }
    td {
      background: transparent;
      padding: 0;
      border-width: 0;
    }
    tr.accordion {
      //border-top: 1px solid #ccc;
      position: relative;
      z-index: 1;

      td {
        display: inline-block;
      }
    }
    .accordion-wrapper {
      tr {
        padding-right: 30px;
      }
    }
    .accordion-content td {
      padding: 0 0 15px 0;
    }
    thead {
      display: none;
    }
  }

  #main table {
    &.align-left,
    &[align="left"] {
      margin-right: 0;
    }

    &.align-right,
    &[align="right"] {
      margin-left: 0;
    }

    &#vrtx-course-description-listing-results {
      border-top-width: 1px;
      // hide table head and credits column
      thead,
      td.vrtx-course-description-credits {
        display: none;
      }
      // show credits below name
      .vrtx-study-results-responsive-info {
        display: block;
      }
    }
  }

  /* Fallback with iScroll */

  .table-overflow-wrapper-outer-responsive {
    position: relative;
    overflow: hidden;
    width: 100% !important;
  }

  .table-overflow-wrapper-responsive {
    overflow: hidden;
    position: absolute;
    bottom: 0px;
    left: 0px;
    top: 0px;
    width: 100% !important;
    z-index: 1;

    .scroller {
      overflow: auto;
      position: absolute;
      z-index: 1;
    }
  }

  .no-touchevents .table-overflow-wrapper-responsive {
    overflow: auto !important;
  }

  /* IFRAME */

  /* Native scrollable iframes */

  .iframe-wrapper-responsive {
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .nettskjema-iframe {
      width: 100% !important;
    }
  }

  /* Fallback with iScroll */
  .iframe-overflow-wrapper-outer-responsive {
    position: relative;
    overflow: hidden;
    width: 100% !important;
  }

  .iframe-overflow-wrapper-responsive {
    overflow: hidden;
    position: absolute;
    bottom: 0px;
    left: 0px;
    top: 0px;
    width: 100% !important;
    z-index: 1;

    .scroller {
      overflow: auto;
      width: 100% !important;
      position: absolute;
      z-index: 1;
    }
  }

  .no-touchevents .iframe-overflow-wrapper-responsive {
    overflow: auto !important;
  }

  /* Facebook */
  iframe[src*='www.facebook.com'] {
    width: 100%;
  }
}
