.ui-accordion.ui-widget {
  clear: both;
  margin: 40px 0;

  .ui-accordion-header {
    border: none;
    border-bottom: 1px solid #9b9b9b;
    color: @textColor;
    background: none;
    margin: 0;
    padding: 15px 60px 15px 0;
    position: relative;
    .transition(padding, .2s, ease-in-out);
    clear: left;

    &:first-child {
      border-top: 1px solid #9b9b9b;
    }
    .ui-icon {
      display: block;
      width: .6em;
      height: .3em;
      position: absolute;
      right: 0;
      top: 50%;
      line-height: 0;

      &:after {
        height: 100%;
        width: 100%;
        content: "+";
        display: block;
        .transition(all, .2s, ease-in-out);
      }
    }

    &.ui-state-hover,
    &.ui-state-focus {

      &:not(.ui-accordion-header-active) {
        .textUnderline;
        .ui-icon:after {
          color: @linkColor;
        }
      }
    }
    &.ui-state-hover {
      cursor: pointer;
    }

    &.ui-accordion-header-active {
      border-bottom: 1px solid transparent; /* Remove but avoid animation jump */

      .ui-icon {
        &:after {
          content: "–";
        }
      }
    }
    &.accordion-links {
      .ui-icon {
        float: right;
        margin-top: .1em;
        margin-bottom: 0;
      }
    }
  }
  h3.ui-accordion-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ui-accordion-content {
    border: none;
    background: none;
    color: @textColor;
    padding: 0 0 30px 0;

    >*:first-child {
      margin-top: 10px;
    }
    >*:last-child {
      margin-bottom: 0;
    }

    &:after {
      .clearfix();
    }
  }
}

#vrtx-frontpage .ui-accordion.ui-widget .ui-accordion-header {
  margin-bottom: 0;
}
