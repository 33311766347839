#vrtx-hinn-programme-presentation, #vrtx-hinn-exchange-destination {
  #vrtx-hinn-studieprogram-content, #vrtx-hinn-exchange-content {
    a:not(.item-title):not(.button):not(.button-large):not(.vrtx-button):not(.vrtx-field-contact) {
      color: var(--linkColor);
      .textUnderline(var(--linkColor));
      &:hover,
      &:focus {
        color: var(--textColor);
        .textUnderlineHover(var(--textColor));
      }
    }
    .vrtx-hinn-infobox {
      a:not(.item-title):not(.button):not(.button-large):not(.vrtx-button):not(.vrtx-field-contact) {
        color: var(--textColor);
        .textUnderline(var(--textColor));
        &:hover,
        &:focus {
          color: var(--linkColor);
          .textUnderlineHover(var(--linkColor));
        }
      }
    }
  }
}
#vrtx-hinn-programme-presentation {
  .vrtx-field-studyStart {
    border: 5px solid var(--btnPrimaryBg);
    padding: 0 50px 35px 50px;
    margin-bottom: 50px;
  }

  #vrtx-hinn-studieprogram-content {
    .vrtx-field-videoInclude,
    .expandable-sections,
    .vrtx-field-gobi {
      margin-bottom: 50px;
    }

    .vrtx-field-videoInclude {
      iframe {
        min-width: 100%;
      }
    }

    .vrtx-hinn-programme-application {
      margin: 50px 0;
    }
  }
}

#vrtx-hinn-programme-presentation,
#vrtx-hinn-exchange-destination {
  #main {
    #vrtx-hinn-studieprogram-content,
    #vrtx-hinn-exchange-content {
      div:not(.vrtx-field-marketing):not(.vrtx-field-marketing
          div):not(.vrtx-field-contact):not(.half-width):not(.vrtx-field-stories):not(.vrtx-field-stories
          div):not(.toggle-more):not(.vrtx-hinn-layout-horisontal):not(.vrtx-contact-component
          div):not(.vrtx-field-programmes) {
        max-width: 690px !important;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .vrtx-field-stories {
      & > *:not(:has(.vrtx-feed)) {
        max-width: 690px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .vrtx-hinn-facts-horizontal {
      background: var(--infoBoxColorDark);
      display: block;
      color: white;
      padding: 26px 40px 20px 40px;
      margin-bottom: 60px;

      a {
        color: white;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      * {
        display: inline-block;
      }
      dt {
        font-weight: normal;
        &::after {
          content: ":";
          display: inline;
          margin-right: 3px;
        }
      }
      dd {
        margin-right: 20px;
        font-family: var(--mediumFontStack);
      }
    }

    .accordion-wrapper {
      margin: 0;
    }
    .ui-accordion-header:nth-of-type(even),
    .expandable-sections
      .accordion-wrapper:nth-of-type(even)
      .ui-accordion-header {
      border-top: none;
      border-bottom: none;
    }
    .expandable-sections
      .accordion-wrapper:last-of-type
      .ui-accordion-header:not(.ui-state-active) {
      border-bottom: 1px solid var(--accordionBorderColor);
    }
    .expandable-sections .ui-accordion-content-active {
      border-bottom: 1px solid var(--accordionBorderColor);
    }
    .vrtx-field-outcome,
    .vrtx-field-info {
      .accordion-wrapper .accordion {
        border-top: none;
      }
    }
    .vrtx-field-marketing,
    .vrtx-field-contact,
    .vrtx-field-stories {
      margin-top: 50px;
    }
    .vrtx-field-marketing {
      padding: 20px 0 20px 0;
    }
    .vrtx-field-stories {
      padding: 20px 15px 20px 15px;
    }
    .vrtx-field-marketing,
    .vrtx-field-stories {
      background: var(--alternateRowColor);

      .vrtx-feed {
        margin-bottom: 20px;
        .feed-title  {
          display: none;
        }
        .items li {
          width: 50%;
          float: left;
          &:nth-last-child(2), &:last-child {
            margin-bottom: 40px;
          }
          a.vrtx-image:first-child {
            float: none;
            margin: 0 0 20px 0;
            width: auto;
          }
          *,
          a.item-title,
          .published-date,
          .item-description,
          span.category {
            margin-left: 0;
          }
          .published-date {
            color: var(--textColor);
          }
          .categories {
            padding-top: 20px;
            span.category {
              float: left;
              margin-right: 10px;
              margin-top: 10px;
              a {
                color: white;
                background: var(--green5);
                padding: 6px 10px;
                display: block;
                &:hover,
                &:focus {
                  background: var(--green5Hover);
                  color: white;
                  text-decoration-color: white;
                }
              }
            }
          }

          &:first-of-type {
            padding-right: 30px;
          }
          &:last-of-type:not(.item-1) {
            padding-left: 30px;
          }
          &.item-1.item-last {
            width: 100%;
            a.vrtx-image,
            a.item-title,
            .published-date,
            .item-description,
            .categories {
              width: 50%;
              float: left;
            }
            a.vrtx-image {
              padding-right: 30px;
            }
            a.item-title,
            .published-date,
            .item-description,
            .categories {
              padding-left: 30px;
            }
          }
        }
      }
    }
    .vrtx-field-faculty {
      padding-top: 20px;
    }

    .vrtx-field-contact {
      background: var(--tilesColor);
      padding: 1px 50px 50px 50px;
      a:not(.item-title):not(.button):not(.button-large):not(.vrtx-button):not(.vrtx-field-contact) {
        color: var(--textColor);
        .textUnderline(var(--textColor));
        &:hover,
        &:focus {
          color: var(--linkColor);
          .textUnderlineHover(var(--linkColor));
        }
      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
      // new
      .vrtx-contact-component {
        position: relative;
        width: 50%;
        float: left;
        &:after {
          content: "";
          display: table;
          clear: both;
        }
      }
    }
    .vrtx-hinn-layout-horisontal {
      .half-width {
        width: 50%;
        max-width: 50%;
        min-width: 50%;
        display: inline-block;
        margin: 50px 0;
        &:first-of-type  {
          float: left;
          padding-right: 30px;
        }
        &:last-of-type {
          padding-left: 30px;
        }
        .vrtx-hinn-linklist li {
          float: none;
        }
      }
    }
  }
}

#vrtx-hinn-programme-presentation {
  .vrtx-hinn-programme-application {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    p {
      margin: 0;
    }
    .button {
      background-color: var(--green5);
      padding-left: 30px;
      padding-right: 60px;
      margin-top: 0;
      margin-bottom: 10px;
      &:hover, &:focus{
        background-color: var(--green5Hover);
        padding-left: 40px;
        padding-right: 50px;
      }
    }
  }
  #main {
    .vrtx-hinn-facts-horizontal {
      background: var(--tilesColor);
      color: var(--textColor);
      a, * {
        color: var(--textColor)
      }
    }
    &:has(.vrtx-hinn-programme-application) {
       .vrtx-hinn-facts-horizontal {
         margin-bottom: 30px;
       }
    }

    .vrtx-field-aboutStudies {
      .expandable-sections {
        margin-bottom: 0;
      }

      ~div {
        .ui-accordion.ui-widget .ui-accordion-header:first-child {
          border-top: none;
        }
      }
    }
  }
}

body
  .main
  #vrtx-content
  #vrtx-main-content
  #vrtx-hinn-studieprogram-content
  .vrtx-field-studyPlans
  .vrtx-hinn-linklist {
  flex-direction: column;
  li:first-child {
    width: 100%;
    // margin-bottom: 17.5px;
    // Green buttonlink to study-plans
    // a {
    //   font-size: 2.2rem;
    //   line-height: 3.1rem;
    //   font-family: var(--mainFontStack);
    //   letter-spacing: normal;
    //   max-width: 450px;
    //   width: 100%;
    //   display: block;
    //   padding: 20px 50px 18px 20px;
    //   background: var(--green5);
    //   color: white;
    //   text-decoration: none;
    //   position: relative;
    //   border-bottom: none;
    //   .transition(padding, 0.2s, ease-in-out);
    //   &:before {
    //     display: none;
    //   }
    //   &:after {
    //     content: "";
    //     background: var(--img-arrow-big-white) no-repeat 0 0;
    //     background-size: auto 0.7em;
    //     position: absolute;
    //     top: 29px;
    //     right: 20px;
    //     height: 1em;
    //     width: 1.2em;
    //     left: unset;
    //   }
    //   &:hover {
    //     padding-left: 30px;
    //     background: var(--green5Hover);
    //   }
    // }
  }
}

#vrtx-hinn-exchange-destination {
  #vrtx-hinn-exchange-content {
    .vrtx-hinn-infobox {
      .vrtx-introduction-image {
        margin-bottom: 0;
        img {
          max-width: none;
          min-width: 100%;
        }
        .vrtx-imagetext {
          padding: 10px 40px 0 40px;
          background: pink;
          display: inline-block;
          background: var(--tilesColor);
          margin: 0;
          width: 100%;
          .vrtx-photo {
            float: right;
          }
        }
      }
    }

    .vrtx-facts-container.toggle-container {
      margin-top: 0;
      padding: 30px 60px 50px 60px;
      background: var(--tilesColor);
      max-height: 390px;
      .toggle-txt,
      .toggle-more a  {
        text-decoration: none;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      &.expanded {
        max-height: none;
        .vrtx-hinn-field-description {
          opacity: 1;
        }
        .toggle-more {
          margin-left: -40px;
          padding: 50px 40px 20px 40px;
          a {
            opacity: 1;
          }
        }
        .toggle-txt {
          opacity: 0;
        }
        &.tabbed {
          .toggle-more {
            a {
              opacity: 0;
            }
          }
          .toggle-txt {
            opacity: 1;
            &::before {
              content: "-";
            }
          }
        }
      }
      
      .toggle-more {
        padding: 24px 40px 60px 40px;
        background: linear-gradient(
          rgba(226, 221, 209, 0.24),
          rgba(226, 221, 209, 0.9),
          var(--tilesColor),
          var(--tilesColor),
          var(--tilesColor),
          var(--tilesColor)
        );
        background: var(--tilesColor);
        a {
          opacity: 0;
        }
      }
      .vrtx-hinn-field-description {
        opacity: 0;
      }
      .toggle-txt {
        position: absolute;
        bottom: 60px;
        left: 60px;
        border: 1px solid;
        border-radius: 25px;
        padding: 7px 20px 7px 35px;
        pointer-events: none;
        z-index: 1;
        &:before {
          content: "+";
          font-size: 2.5rem;
          font-family: var(--mainFontStack);
          font-weight: 300;
          display: inline-block;
          position: absolute;
          left: 15px;
          top: 1px;
        }
      }
    }
  }

  a.button {
    margin: 40px 0;
  }
}

// @desktopSmallBreakpoint - max-width: 1070px
@media @desktopSmallBreakpoint {
  #vrtx-hinn-programme-presentation,
  #vrtx-hinn-exchange-destination {
    #main {
      .vrtx-hinn-facts-horizontal {
        max-width: 100% !important; //override uio code
        margin-left: 15px !important; //override uio code
        margin-right: 15px !important; //override uio code
      }
    }
  }
  #vrtx-hinn-programme-presentation,
  #vrtx-hinn-exchange-destination {
    #vrtx-hinn-studieprogram-content,
    #vrtx-hinn-exchange-content {
      margin-left: auto !important;
    }
  }
}

// @responsiveBreakpoint - max-width: 877px
@media @responsiveBreakpoint {
  #vrtx-hinn-programme-presentation,
  #vrtx-hinn-exchange-destination {
    #main {
      .vrtx-hinn-facts-horizontal {
        padding: 20px 25px;
      }
      .vrtx-field-marketing,
      .vrtx-field-stories {
        display: inline-block;
        width: 100%;
        padding-top: 0;
        padding-bottom: 50px;
        .vrtx-feed .items {
          li {
            width: 100%;
            &:first-of-type,
            &:last-of-type,
            &:last-of-type:not(.item-1) {
              padding-right: 0;
              padding-left: 0;
            }
            &.item-1.item-last {
              width: 100%;
              a.vrtx-image,
              a.item-title,
              .published-date,
              .item-description,
              .categories {
                width: 100%;
                float: none;
              }
              a.vrtx-image {
                padding-right: 0;
              }
              a.item-title,
              .published-date,
              .item-description,
              .categories {
                padding-left: 0;
              }
            }
          }
        }
      }
      .vrtx-hinn-layout-horisontal .half-width {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin: 0;
        &:first-of-type,
        &:last-of-type {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .vrtx-field-contact {
        padding: 1px 15px 50px 15px;
      }

      .vrtx-facts-container.toggle-container,
      .vrtx-hinn-infobox .vrtx-introduction-image .vrtx-imagetext {
        padding-left: 15px;
        padding-right: 15px;
      }
      .vrtx-hinn-infobox {
        .vrtx-introduction-image {
          width: 100%;
          min-width: 100%;
        }
      }

      .vrtx-facts-container.toggle-container {
        a.toggle-txt {
          bottom: 40px;
          left: 20px;
        }
        &.expanded {
          padding-bottom: 0;
          .toggle-more {
            padding: 50px 40px 40px 40px;
          }
        }
      }

      #vrtx-hinn-studieprogram-content,
      #vrtx-hinn-exchange-content {
        div:not(.vrtx-field-marketing):not(.vrtx-field-marketing
            div):not(.vrtx-field-contact):not(.half-width):not(.vrtx-field-stories):not(.vrtx-field-stories
            div):not(.toggle-more):not(.vrtx-hinn-layout-horisontal):not(.vrtx-hinn-infobox):not(.vrtx-contact-component
            div) {
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
        .vrtx-hinn-content,
        .vrtx-hinn-layout-horisontal,
        .vrtx-field-programmes {
          padding-left: 15px;
          padding-right: 15px;
        }
        .vrtx-hinn-infobox {
          overflow: hidden;
          border-left: 15px solid white;
          border-right: 15px solid white;
        }
        .vrtx-field-contact {
          // new
          .vrtx-contact-component {
            width: 100%;
            float: none;
          }
        }
      }
    }
  }
}
