body#error {
  background: var(--tilesColor);
  h1 {
    display: inline;
  }
}

// Responsive only
@media @responsiveBreakpoint {
}
