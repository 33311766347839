.easycruit-feed .vrtx-feed,
.jobbnorge-feed .vrtx-feed,
.easycruit-feed-no-department .vrtx-feed,
.jobbnorge-feed-no-department .vrtx-feed,
.easycruit-feed-no-top-department .vrtx-feed,
.jobbnorge-feed-no-top-department .vrtx-feed {
  .item-description {
    .location,
    .language,
    .deadline {
      margin-top: 4px;
    }
    .location,
    .language {
      display: none;
    }
    .deadline {
      color: @publishedDateGrey;
    }
    .location,
    .language,
    .deadline {
      clear: left;
    }
    .department,
    .topDepartmentName {
      display: inline;
      font-size: 0;
      line-height: 0;

      span {
        font-size: 1.9rem;
        line-height: 2.7rem;
      }
    }
    .department + .topDepartmentName {
      span:before {
        content: "- ";
        padding-left: .5ex;
      }
    }
  }
}

.easycruit-feed-no-department,
.jobbnorge-feed-no-department {
  .vrtx-feed .item-description .department,
  .vrtx-feed .item-description .topDepartmentName {
    display: none;
  }
}

.easycruit-feed-no-top-department,
.jobbnorge-feed-no-top-department {
  .vrtx-feed .item-description .topDepartmentName {
    display: none;
  }
}

.vrtx-frontpage-box[class*='third-box-'],
.vrtx-frontpage-box[class*='half-box-'] {
  &.easycruit-feed .vrtx-feed,
  &.jobbnorge-feed .vrtx-feed,
  &.easycruit-feed-no-department .vrtx-feed,
  &.jobbnorge-feed-no-department .vrtx-feed,
  &.easycruit-feed-no-top-department .vrtx-feed,
  &.jobbnorge-feed-no-top-department .vrtx-feed {
    a.item-title {
      font-size: 1.9rem;
    }
  }
}
