#vrtx-searchview,
#vrtx-personsearch,
#vrtx-unitsearch {
  h1 {
    margin-bottom: 25px;
  }
  form.vrtx-big-search {
    &,
    input[type="text"],
    input[type="search"] {
      max-width: none;
    }
  }

  #vrtx-additional-content {
    margin-top: 215px;

    .vrtx-frontpage-box {
      margin-bottom: 0;
    }
    ul {
      margin: 0 0 30px 0;
      padding: 0 0 30px 0;
      border-bottom: 1px solid #000;

      li {
        margin-left: 0;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          display: none;
        }
        > a {
          display: inline-block;
          padding: 0 15px;
        }
        > span { // Selected
          display: inline-block;
          font-weight: bold;
          background-color: #ebedfc;
          color: #444;
          display: block;
          padding: 7px 15px;
        }
      }
    }
  }
  #main .vrtx-paging-feed-wrapper .vrtx-paging-wrapper {
    max-width: 100%;
  }
}

#vrtx-advanced-search-wrapper {
  display: none;
  margin-bottom: 20px;
  overflow: hidden;

  >*:first-child {
    margin-top: 0;
  }
  input[type="text"] {
    width: 390px;
  }
  .vrtx-toggle-advanced-search {
    display: inline-block !important;
    margin-left: 20px;
  }
  #vrtx-advanced-search-submit-hide {
    margin-top: 20px;

    .submit-button-link {
      width: auto;
    }
  }
}

#vrtx-show-advanced-search {
  display: inline-block;
  position: absolute;
  top: 20px;
  left: ~"calc(100% + 100px)";
  white-space: nowrap;
}

.vrtx-search-open-facets-box {
  margin: 10px 0 20px -10px;

  ul {
    margin: 0 0 15px 0;
    display: inline-block;

    li {
      margin: 0;

      &:before {
        display: none;
      }
      > a {
        padding: 2px 15px 3px 30px;
        display: inline-block;
        color: @textColor;
        border: 1px solid transparent;
        background: transparent url("@{vortexStaticResources}list-remove-red.png") no-repeat 10px 1.2ex;

        &:focus,
        &:hover {
          text-decoration: none;
          border: 1px solid @borderColor;
        }
      }
    }
  }
}

#vrtx-search-did-you-mean {
  margin-top: 10px;
}

.pre-results {
  display: none;
}

.hits,
.vrtx-person-search-no-hits,
.vrtx-unit-search-no-hits {
  display: block;
  margin: 50px 0 0;
  font-size: 2.3rem;
  line-height: 3.1rem;
  letter-spacing: 0.4px;
}

.vrtx-search-quick-links,
.vrtx-search-results-container {
  h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .vrtx-search-quick-link,
  .vrtx-search-results {
    margin: 40px 0 0 0;
  }
}

.vrtx-search-quick-links {
  .vrtx-search-quick-link {
    .summary {
      .baseFontSize;

      .vrtx-search-restricted {
        color: @red;
      }
    }
    .footer {
      color: @publishedDateGrey;
      .baseFontSize;
    }
  }
}

.vrtx-search-results-container {
  div.vrtx-search-results {
    .result {
      h2 {
        margin-bottom: 10px;

        a {
          font-size: 2.3rem;
          line-height: 3.1rem;
        }
      }
      .snippet {
        .baseFontSize;

        .vrtx-search-restricted {
          color: @red;
        }
      }
      .footer {
        color: @publishedDateGrey;
        .baseFontSize;
        margin-top: 5px;
      }
    }
  }
}

#main .vrtx-topuioresult {
  display: block;
  border-bottom: 1px solid @borderColor;
  padding-bottom: 20px;

  &:before {
    display: block;
    content: " ";
    border-top: 1px solid @borderColor;
    padding-top: 20px;
  }
  .result > .url + h2 {
    margin-top: 5px;
  }
  .footer > .vrtx-search-scope {
    margin: 10px 0 0 0;

    li {
      margin: 0;

      &:before {
        display: none;
      }
    }
  }
}

#main .vrtx-has-search-scope {
  .vrtx-search-scope {
    margin: 0;
    padding: 0;
    clear: both;
  }

  .vrtx-paging-feed-wrapper {
    margin-bottom: 20px;
  }
}

.vrtx-additional-search-info {
  display: none;
}

@media @responsiveBreakpoint {

  #vrtx-searchview, #vrtx-personsearch, #vrtx-unitsearch {
    #vrtx-additional-content {
      margin-top: 20px !important; // override js adjustments
    }
    .toggle-more-opts {
      width: 100%;
    }
  }
  #vrtx-show-advanced-search {
    position: static;
    margin-top: 20px;
    display: none;
  }
}
