#vrtx-person-position {
  font-size: 2.7rem;
  line-height: 3.5rem;
  letter-spacing: 0.39px;
  margin: -40px 0 45px 0;
}

#vrtx-person-contact-info-wrapper {
  .vrtx-person-image {
    float: left;
    width: 126px;
    height: auto;
    margin-right: 30px;
  }
  .vrtx-person-contactinfo {
    margin-left: 156px;

    &:after {
      .clearfix();
    }
  }
}

#vrtx-person-contact-info-extras {
  margin-top: 30px;
  border-top: 1px solid #000;
  padding-top: 20px;
  clear: both;
  margin-bottom: 40px;

  #vrtx-press-photo + #vrtx-person-vcard {
    margin-left: 30px;
  }
}

// TODO: cleanup css under:
.vrtx-person-contact-info-line .vrtx-label,
.vrtx-person-other-units .vrtx-label,
.vrtx-unit-contact-info-line .vrtx-label,
.vrtx-unit-org-unit-number .vrtx-label {
  font-weight: 700;
  float: left;
}

.vrtx-person-postal-address .vrtx-label,
.vrtx-person-visiting-address .vrtx-label {
  font-weight: 700;
}

.vrtx-person-other-units {
  clear: left;
}

.vrtx-person-contact-info-line .vrtx-address-line,
.vrtx-person-contact-info-line .vrtx-label,
.vrtx-person-contact-info-line .vrtx-login,
.vrtx-person-contact-info-line .vrtx-value,
.vrtx-person-other-units .vrtx-address-line,
.vrtx-person-other-units .vrtx-label,
.vrtx-person-other-units .vrtx-login,
.vrtx-person-other-units .vrtx-value,
.vrtx-person-postal-address .vrtx-address-line,
.vrtx-person-postal-address .vrtx-label,
.vrtx-person-postal-address .vrtx-login,
.vrtx-person-postal-address .vrtx-value,
.vrtx-person-visiting-address .vrtx-address-line,
.vrtx-person-visiting-address .vrtx-label,
.vrtx-person-visiting-address .vrtx-login,
.vrtx-person-visiting-address .vrtx-value,
.vrtx-unit-contact-info-line .vrtx-address-line,
.vrtx-unit-contact-info-line .vrtx-label,
.vrtx-unit-contact-info-line .vrtx-login,
.vrtx-unit-contact-info-line .vrtx-value,
.vrtx-unit-org-unit-number .vrtx-address-line,
.vrtx-unit-org-unit-number .vrtx-label,
.vrtx-unit-org-unit-number .vrtx-login,
.vrtx-unit-org-unit-number .vrtx-value {
  display: block;
}

.vrtx-person-contact-info-line .vrtx-login,
.vrtx-person-contact-info-line .vrtx-value,
.vrtx-person-other-units .vrtx-login,
.vrtx-person-other-units .vrtx-value,
.vrtx-person-postal-address .vrtx-login,
.vrtx-person-postal-address .vrtx-value,
.vrtx-person-visiting-address .vrtx-login,
.vrtx-person-visiting-address .vrtx-value,
.vrtx-unit-contact-info-line .vrtx-login,
.vrtx-unit-contact-info-line .vrtx-value,
.vrtx-unit-org-unit-number .vrtx-login,
.vrtx-unit-org-unit-number .vrtx-value {
  margin-left: 140px;
}

.vrtx-person-other-units .vrtx-value {
  margin-left: 170px;
}
.vrtx-person-visiting-address {
  padding-right: 40px;
}
.vrtx-person-visiting-address+.vrtx-person-postal-address {
  padding-left: 40px;
}
.vrtx-person-postal-address, .vrtx-person-visiting-address {
  float: left;
  width: 50%;
  margin: 20px 0;
  max-width: 230px;
}

@media @responsiveBreakpoint {

  #vrtx-person-position {
    margin: -15px 0 25px;
  }

  #vrtx-person-contact-info-wrapper {
    .vrtx-person-image {
      float: none;
    }
    .vrtx-person-contactinfo {
      margin-left: 0;
    }
  }

  .vrtx-person-contact-info-line .vrtx-label,
  .vrtx-person-other-units .vrtx-label,
  .vrtx-unit-contact-info-line .vrtx-label,
  .vrtx-unit-org-unit-number .vrtx-label {
    float: none;
  }
  .vrtx-person-contact-info-line .vrtx-login,
  .vrtx-person-contact-info-line .vrtx-value,
  .vrtx-person-other-units .vrtx-login,
  .vrtx-person-other-units .vrtx-value,
  .vrtx-person-postal-address .vrtx-login,
  .vrtx-person-postal-address .vrtx-value,
  .vrtx-person-visiting-address .vrtx-login,
  .vrtx-person-visiting-address .vrtx-value,
  .vrtx-unit-contact-info-line .vrtx-login,
  .vrtx-unit-contact-info-line .vrtx-value,
  .vrtx-unit-org-unit-number .vrtx-login,
  .vrtx-unit-org-unit-number .vrtx-value {
    margin-left: 0;
  }

  .vrtx-person-contactinfo>div,
  .vrtx-unit-phone>div,
  .vrtx-unit-postal-address>div,
  .vrtx-unit-visiting-address>div {
    margin-top: 10px;
  }

  .vrtx-person-postal-address,
  .vrtx-person-visiting-address,
  .vrtx-unit-phone,
  .vrtx-unit-postal-address,
  .vrtx-unit-visiting-address {
    float: none;
    width: auto;
    max-width: none;
    margin-bottom: 0;
  }

  .vrtx-person-visiting-address {
    padding-right: 0;
  }

  .vrtx-person-visiting-address+.vrtx-person-postal-address {
    padding-left: 0;
  }
}
