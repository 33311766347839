@factContainerBgColor: #e6ecff;
@factContainerBgColorAltRed: #fb6666;
@factContainerBgColorAltBlack: #18191c;

img:not([src$='spacer.gif']) {
  max-width: 100%;
  height: auto;
  &.border {
    border: 1px solid #eaeaea;
  }
}

.vrtx-facts-container,
.vrtx-container {
  margin: 30px 0;
}

p.image {
  margin: 0;
  img {
    margin: 30px 0;
  }
}

.vrtx-container {
  p img {
    display: block;
    margin: 0 0 10px 0;
  }
}

p.image-p-list {
  margin: 10px 0 5px 0;
  img {
    margin: 0;
  }
  a {
    display: inline-block;
    margin-left: 5px;
    vertical-align: top;
  }
}

.vrtx-container,
.vrtx-image-listing-include-container-description,
.vrtx-imagetext,
.vrtx-img-container,
figcaption {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: .3px;
  color: #444;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

figure {
  display: table !important;
  margin: 30px 0;
}

figcaption {
  display: table-caption;
  caption-side: bottom;
}

figcaption,
.vrtx-imagetext {
  margin: 10px 0 0 0;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

p.image-center {
  img {
    display: block !important;
  }
}

.align-center,
p.image-center > img,
.image-center > img,
.image-center figure {
  margin-left: auto;
  margin-right: auto;
}

.left-img,
.image-left,
p.image-left-parent,
p img.image-left,
.align-left,
.vrtx-media-left,
.vrtx-media-player.vrtx-container-left,
.vrtx-container-left {
  margin-top: 6px;
  margin-right: 30px;
  margin-bottom: 30px;
  float: left;
}

.vrtx-introduction-image.small-intro-image,
.right-img,
p.image-right-parent,
p img.image-right,
.image-right,
.align-right,
.vrtx-media-right,
.vrtx-media-player.vrtx-container-right,
.vrtx-container-right {
  margin-top: 6px;
  margin-left: 30px;
  margin-bottom: 30px;
  float: right;
}

p.image-left-parent,
p.image-right-parent {
  img {
    margin: 0;
    float: none;
  }
}

h2,
h3 {
  + .left-img,
  + .image-left,
  + p img.image-left,
  + .align-left,
  + .vrtx-media-left,
  + .vrtx-media-player.vrtx-container-left,
  + .vrtx-container-left,
  + .right-img,
  + p img.image-right,
  + .image-right,
  + .align-right,
  + .vrtx-media-right,
  + .vrtx-media-player.vrtx-container-right,
  + .vrtx-container-right {
    margin-top: 8px;
  }
}

*[align="left"] {
  margin: 0 30px 30px 0;
}

*[align="right"] {
  margin: 0 0 30px 30px;
}

img:not([src$="spacer.gif"]) {
  max-width: 100%;
  height: auto;
}

#vrtx-additional-content {
  img.scale-right-column {
    width: 100%;
  }
}

#main .image-tiles {
  clear: both;
  float: left;
  width: 100%;

  h2 {
    margin-top: 0;
  }

  img {
    margin: 0;
  }
}

/*
@keyframes introImageLoad {
  0%     { opacity: 0.0; }
  100%   { opacity: 1.0; }
}
*/

// introduction image

.vrtx-introduction-image {
  max-width: min(1000px, 100%);
  img {
    display: block; // removed bottom spacing (previously fixed by line-height)
  }
  /*
  animation-name: introImageLoad;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  */
}

.vrtx-introduction .vrtx-introduction-image {
  margin-left: 0;
}

// Facts

.vrtx-facts-container {
  width: 35%;
  padding: 30px 40px;
  clear: right;
  background: @factContainerBgColor;
  > *:first-child {
    margin-top: 0px;
    font-size: 2.8rem;
    line-height: 3.6rem;
    letter-spacing: 0.4px;
  }
  &.info-red {
    background-color: @factContainerBgColorAltRed;

    &.toggle-container .toggle-more {
      background: linear-gradient(
        fade(@factContainerBgColorAltRed, 0) 0%;
        fade(@factContainerBgColorAltRed, 100%) 35%;
        @factContainerBgColorAltRed 35%,
        @factContainerBgColorAltRed 100%
      );
    }
  }
  &.info-black {
    background-color: @factContainerBgColorAltBlack;
    color: #fff;

    *,
    a {
      color: #fff;
    }

    ul.only-links li a:not(.button):not(.button-large) {
      color: #fff;
      &:before {
        background-image: url("@{imagePath}arrows/arrow-big-white.svg");
      }
    }

    &.toggle-container .toggle-more {
      background: linear-gradient(
        fade(@factContainerBgColorAltBlack, 0) 0%;
        fade(@factContainerBgColorAltBlack, 100%) 35%;
        @factContainerBgColorAltBlack 35%,
        @factContainerBgColorAltBlack 100%
      );

      a {
       color: #fff;
      }
    }
  }
  &.toggle-container {
    position: relative;
    max-height: 320px;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    .toggle-more {
      padding: 60px 40px 50px 40px;
      left: 0;
      width: 100%;
      position: absolute;
      bottom: 0;
      margin: 0;
      cursor: pointer;
      background: linear-gradient(
        fade(@factContainerBgColor, 0) 0%;
        fade(@factContainerBgColor, 100%) 35%;
        @factContainerBgColor 35%,
        @factContainerBgColor 100%
      );
      a {
        padding-left: 7px;
        padding: 20px 40px 50px 40px;
        position: absolute;
        width: 100%;
        left: 27px;
        bottom: 0;
      }

      &::before {
        content: "+";
        font-size: 2.8rem;
        font-family: @mainFontStack;
        font-weight: 300;
        display: inline-block;
      }
    }
    &.expanded {
      max-height: none;
      transition: max-height 1s ease-in-out;
      padding: 50px 40px 0 40px;
      .toggle-more {
        background: transparent;
        padding: 40px 40px 50px 40px;
        position: relative;
        margin-left: -40px;
        display: block;
        width: 200%;
        a {
          position: relative;
          padding-left: 0;
        }
        &::before {
          content: "-";
          position: absolute;
        }
      }
    }
  }
  &.info-fullwidth-wide,
  &.info-fullwidth {
    width: 100%;
    padding: 50px 60px;
    margin-left: 0;
    margin-right: 0;
    float: none;

    &.toggle-container {
      .toggle-more {
        padding-left: 60px;
        padding-right: 60px;
        a {
          padding-left: 60px;
          padding-right: 60px;
        }
      }
      &.expanded {
        padding-left: 60px;
        padding-right: 60px;
        .toggle-more {
          padding-left: 60px;
          padding-right: 60px;
          margin-left: -60px;
          a {
            padding-left: 0;
          }
        }
      }
    }
  }
  &.info-fullwidth-wide {
    // max-width: @h1IntroWidth;
    margin-left: 0;
  }
  &.full-width-img {
    max-height: 600px;
    position: relative;
    >*:first-child {
      width: 100%;
      height: auto;
      max-height: 385px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;
    }
    img:first-of-type {
      width: 100%;
    }
  }
  &.one-third-img-right {
    display: flex;
    flex-direction: row;
    padding: 0;

    >*:first-child {
      flex: 1 1 60px;
      order: 2;
      //line-height: 0;
      margin: 0;
      display: block !important;

      figcaption {
        display: block;
      }

      img {
        display: block; // removed bottom spacing (previously fixed by line-height)
        max-width: none;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    > figure:first-child {
      img {
        height: auto;
      }
    }
    .two-third-text-left {
      flex: 1.5 1.5 60px;
      order: 1;
      padding: 70px 60px 70px 60px;

      >*:first-child {
        margin-top: 0;
      }
      >*:last-child {
        margin-bottom: 0;
      }
    }
  }

  table {
    border: none;
    td, th {
      border: none;
      padding: 0 40px 20px 0;
    }
  }
}

// New: figure and facts container
body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
  #vrtx-main-content,
body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
  #vrtx-main-user {
  > figure.image-left,
  > p.image-left-parent,
  > .vrtx-facts-container.vrtx-container-left:not(.info-fullwidth) {
    &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
      margin-left: 0;
      margin-right: 30px;
      margin-top: 8px;
    }
  }
  > figure.image-right,
  > p.image-right-parent,
  > .vrtx-facts-container.vrtx-container-right:not(.info-fullwidth) {
    &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
      margin-left: 30px;
      margin-right: 0;
      margin-top: 8px;
    }
  }
  h2,
  h3 {
    + figure.image-left,
    + p.image-left-parent,
    + .vrtx-facts-container.vrtx-container-left:not(.info-fullwidth),
    + figure.image-right,
    + p.image-right-parent,
    + .vrtx-facts-container.vrtx-container-right:not(.info-fullwidth) {
      &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
        margin-top: 8px;
      }
    }
  }
}

@media only screen and (min-width: 1180px) {
  // New: figure and facts container
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    #vrtx-main-content,
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    .vrtx-article-body,
    .article-body,
    #vrtx-main-user {
    > figure.image-left,
    > p.image-left-parent,
    > .vrtx-facts-container.vrtx-container-left:not(.info-fullwidth) {
      &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
        margin-left: -80px;
        z-index: 1;
      }
    }
    .vrtx-introduction-image.small-intro-image {
      &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
        margin-right: ~"calc(calc(calc(calc(100%) - @{bodytextContentWidth} - calc(calc(100%) - @{h1IntroWidth})) / 2) - 80px + calc(calc(100%) - @{h1IntroWidth}))";
      }
    }
    > figure.image-right,
    > p.image-right-parent,
    > .vrtx-facts-container.vrtx-container-right:not(.info-fullwidth) {
      &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
        margin-right: -80px;
        z-index: 1;
      }
    }
  }
}

// Responsive

@media @responsiveBreakpoint {

  .vrtx-container,
  .vrtx-image-listing-include-container-description,
  .vrtx-imagetext,
  .vrtx-img-container,
  figcaption {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .vrtx-imagetext {
    margin-top: 10px;
  }

  .left-img, .image-left, p.image-left-parent, p img.image-left, .align-left, .vrtx-media-left, .vrtx-media-player.vrtx-container-left, .vrtx-container-left,
  .align-right, .image-right, .right-img, .vrtx-container-right, .vrtx-introduction-image, .vrtx-media-player.vrtx-container-right, .vrtx-media-right, p img.image-right, p.image-right-parent {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }

  // New: figure and facts container
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    #vrtx-main-content,
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    .vrtx-article-body,
    .article-body,
    #vrtx-main-user {
    > p.image-left-parent,
    > figure.image-left,
    .vrtx-introduction-image.small-intro-image,
    .vrtx-introduction-image.medium-intro-image,
    > p.image-right-parent,
    > figure.image-right {
      &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
        margin-left: 0;
        margin-right: 0;
        float: none;
        max-width: 100% !important;
      }
    }
    > .vrtx-facts-container.vrtx-container-left:not(.info-fullwidth),
    > .vrtx-facts-container.vrtx-container-right:not(.info-fullwidth) {
      &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        float: none;
        width: 100%;
      }
    }
    h2,
    h3 {
      + figure.image-left,
      + p.image-left-parent,
      + .vrtx-facts-container.vrtx-container-left:not(.info-fullwidth),
      + figure.image-right,
      + p.image-right-parent,
      + .vrtx-facts-container.vrtx-container-right:not(.info-fullwidth) {
        &:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
          margin-top: 6px;
        }
      }
    }
  }

  .vrtx-facts-container {
    &.one-third-img-right {
      flex-direction: column;

      >*:first-child {
        flex: 1 1 0;
        order: 1;
      }
      .two-third-text-left {
        flex: 1 1 0;
        order: 2;
        padding: 20px 15px;
      }
    }
  }
}
