@imageListingWidth: 194px;
@imageListingHeight: 128px;

.vrtx-image-listing {
  .vrtx-image-entry {
    margin: 0 30px 30px 0;
    float: left;
    position: relative;

    &:before {
      display: none;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(3n+1) {
      clear: left;
    }

    .vrtx-image-container {
      border: 1px solid #aaa;
      .borderRadius(5px);
      padding: 7px;
      margin: 0 0 7px 0;

      a {
        overflow: hidden;
        display: block;

        /* 4:3 format */
        width: @imageListingWidth;
        height: @imageListingHeight;
      }
      img {
        max-height: @imageListingHeight;
        height: auto;
      }
    }

    .vrtx-image-title a {
      width: @imageListingWidth;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .vrtx-image-info {
      margin: 0 0 0 8px;
      width: @imageListingWidth;
    }

    .vrtx-image-creation-time {
      color: @publishedDateGrey;
    }

    .duration {
      font-size: 1.4rem;;
      position: absolute;
      top: ~"calc(@{imageListingHeight} - 19px)";
      left: 8px;
      text-align: right;
      padding: 1px 5px;
      background: #fff;
      width: @imageListingWidth;
      opacity: 0.9;
      color: #000;
    }
  }
}

@media @responsiveBreakpoint {

  .vrtx-image-listing .vrtx-image-entry {
    position: relative;

    &:nth-child(n) {
      margin-right: 0;
      clear: left;
    }
  }
}
