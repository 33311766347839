/* special.less */
html.special-page-config,
html.special-page-config-header-minimal,
html.special-page-config-header-minimal.special-page-config-header-minimal,
html.special-page-config.special-page-config-header-minimal {
  body.sidebar-menu-wrapper-visible {
    #head-wrapper {
      min-height: inherit;
      background: var(--tilesColor);
    }
  }
}

html.special-page-config,
html.special-page-config-header-minimal {
  #main #vrtx-main-content > .grid-container {
    z-index: 1;
  }
}

html.special-page-config-header-minimal #main #vrtx-main-content {
  .grid-container.special-logo-video-grid-container,
  .grid-container.special-logo-image-grid-container {
    max-height: 700px;
    margin-top: 106px !important;
    overflow: hidden;
    opacity: 1;
    .row {
      max-height: 100%;
    }
  }
}

html.special-page-config,
html.special-page-config-header-minimal {
  body:not(.ritmo) {
    #vrtx-main-content .special-logo-video-grid-container + .grid-container,
    #vrtx-main-content > .grid-container:first-child {
      .vrtx-frontpage-box:first-child
        .vrtx-box-content
        > p:first-child:first-letter {
        font-size: 4rem;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        font-family: var(--mainFontStack);
        color: var(--textColor);
      }
    }
  }
}

html.special-page-config-header-minimal.special-page-config-header-minimal,
html.special-page-config.special-page-config-header-minimal,
html.special-page-config,
html.special-page-config-header-minimal {
  .vrtx-frontpage-box:not(.white-bg) {
    .vrtx-box-content p {
      margin-bottom: 25px;
    }
  }

  #marketing-container.grid-container {
    z-index: 1;
    min-height: 152px;
    .marketing-buttons {
      z-index: 10;
      position: absolute;
    }
  }
  #marketing-customize-container.grid-container  {
    background: white;
  }
  #head-wrapper {
    min-height: auto;
    #head {
      margin-top: 0;
    }
  }

  .special-page-logo-intro-over.special-page-logo-black-text-over,
  .special-page-logo-intro-over {
    .special-logo-video-grid-container {
      margin-top: 80px;
    }
    h1,
    #vrtx-frontpage-introduction .vrtx-introduction,
    #vrtx-frontpage-introduction .vrtx-introduction * {
      color: var(--textColor);
    }
    h1 {
      margin-top: 200px !important;
    }
  }

  #main {
    width: 100%;
    min-width: 0;
    max-width: none;
    padding-left: 0;
    min-width: 0;
    max-width: none;
    margin: 0 0 40px;
    margin-bottom: 40px;
    #total-main {
      #vrtx-content {
        float: left;
        h1,
        .vrtx-introduction {
          text-align: center;
          span,
          p {
            background: white;
          }
        }
        h1 {
          margin-bottom: 40px;
          span {
            padding: 15px 20px;
          }
        }
        #vrtx-frontpage-introduction {
          padding: 0;
          margin-bottom: 100px;
          .vrtx-introduction p {
            padding: 10px 15px;
            display: inline;
          }
        }
        #vrtx-main-content {
          .vrtx-frontpage-box:not(.special-pinned-big) {
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: var(--textColor);
            }
          }

          .special-logo-video-wide,
          .special-logo-image {
            :not(.special-logo-video):not(.vrtx-frontpage-box.white-bg) {
              h2,
              .vrtx-box-content {
                color: white;
              }
            }
          }

          .special-pinned-big.special-logo-text-right,
          .special-pinned-big.special-logo-text-left {
            max-width: 1400px;

            .vrtx-box-content {
              width: 30%;
            }
          }

          .grid-container.special-pinned-big-grid-container
            .vrtx-frontpage-box
            .vrtx-box-content {
            font-size: 2rem;
          }

          .special-logo-video-wide {
            .vrtx-box-content p {
              text-shadow: 0px 1px 20px black;
              color: white;
              font-family: var(--mediumFontStack);
            }
          }

          // fix iframe ratio issue:
          .vrtx-frontpage-box.fix-ratio {
            .vrtx-media-player:not(.fix-ratio) {
              position: relative;
              padding-bottom: 56.25%;
              height: 0;
              overflow: hidden;
              iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          // blockquote
          blockquote {
            &:before {
              content: "";
            }
            &:not(:first-child) {
              margin-top: 50px;
            }
            &:not(:last-child) {
              margin-bottom: 50px;
            }
            font-size: 2.3rem;
            padding-left: 0;
            p {
              font-family: var(--tertiaryFontStack) !important;
              font-weight: 600;
              &:last-child {
                font-weight: 400;
              }
              &:first-of-type::before {
                content: var(--img-quote-open);
                padding-right: 15px;
                top: 0px;
              }
              &:last-of-type::after {
                content: var(--img-quote-close);
                padding-left: 15px;
                margin-top: 12px;
                position: absolute;
              }
            }
          }

          .vrtx-frontpage-box.white-bg {
            .vrtx-box-content {
              background: white;
              padding: 30px;
              h2,
              * {
                color: var(--textColor);
              }
              background: white;
            }
          }

          .vrtx-frontpage-box.special-lead.special-pinned {
            .vrtx-box-content {
              width: 50%;
            }
          }
        }
      }
    }
  }

  #footer-wrapper {
    #footers .col-3-5 .column a,
    #footers .col-1-1 .content a {
      &:hover,
      &:focus {
        text-decoration: none !important;
      }
    }
    .footer-social-links-wrap {
      a,
      h2,
      * {
        color: white;
        text-decoration-color: white;
        &:hover,
        &:focus {
          text-decoration-color: var(--textColor);
        }
      }
    }
  }
}

// Published date
html.special-page-config,
html.special-page-config-header-minimal {
  #vrtx-frontpage-introduction .vrtx-authors {
    margin-top: 40px;
    border-top: none;
    padding-top: 0px;
    margin-bottom: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 1070px) {
  html.special-page-config,
  html.special-page-config-header-minimal {
    #vrtx-frontpage-introduction .vrtx-authors {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media @responsiveBreakpoint {
  html.special-page-config-header-minimal
    body:not(.marketing-container-fixed)
    #main
    #vrtx-main-content {
    .grid-container.special-logo-video-grid-container,
    .grid-container.special-logo-image-grid-container {
      top: 0 !important;
      max-height: 700px;
      margin-top: 106px !important;
    }
  }
  html.special-page-config-header-minimal
    body.marketing-container-fixed
    #main
    #vrtx-main-content {
    .grid-container.special-logo-video-grid-container,
    .grid-container.special-logo-image-grid-container {
      margin-top: 55px !important;
    }
  }
  html.special-page-config-header-minimal.special-page-config-header-minimal,
  html.special-page-config.special-page-config-header-minimal,
  html.special-page-config,
  html.special-page-config-header-minimal {
    #marketing-container.grid-container {
      padding-bottom: 135px;
    }
    #vrtx-content h1 span {
      background: transparent;
      line-height: 1.5;
    }
    body .vrtx-frontpage-box.special-lead {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .vrtx-frontpage-box:not(.white-bg):not(.special-lead):not(.special-logo-video-wide) {
      h2,
      .vrtx-box-content *:not(strong) {
        padding-left: 15px;
        padding-right: 15px;
        * {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .special-logo-video-wide {
      padding-left: 0;
      padding-right: 0;
      .vrtx-box-content p {
        padding: 0 !important;
        font-size: 1.8rem !important;
        font-family: "Graphik" !important;
        text-shadow: none !important;
        color: var(--textColor) !important;
      }
    }

    .vrtx-contact-component {
      a:not(.name a):not(.contact a) {
        left: 15px;
      }
      a:not(.name a) + .name,
      a:not(.name a) + .name + .title,
      a:not(.name a) + .name + .title + .contact {
        padding-left: 20px !important;
      }
    }

    .special-logo-video-wide {
      .vrtx-box-content {
        * {
          color: var(--textColor);
        }
      }
    }

    .vrtx-frontpage-box.white-bg {
      .vrtx-box-content {
        padding: 15px !important;
      }
    }

    #main {
      .grid-container .vrtx-frontpage-box blockquote {
        padding-left: 50px;
      }
      #total-main #vrtx-content #vrtx-main-content {
        .special-pinned-big.special-logo-text-left .vrtx-box-content,
        .special-pinned-big.special-logo-text-right .vrtx-box-content {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}
