@color-facts-text: white;

#vrtx-hinn-researchgroup,
#vrtx-structured-project {
  .vrtx-hinn-facts-horizontal {
    background-color: var(--green6);
    color: @color-facts-text;
    max-width: 690px;
    min-width: 100%;
    padding: 25px 40px;
    margin-bottom: 40px;
    div {
      line-height: 1.5;
      padding: 2px 0;
      hyphens: manual;
      dt {
        font-family: var(--mainFontStack);
        display: inline;
      }
      dd {
        font-family: var(--mediumFontStack);
        margin: 0;
        clear: left;
        display: inline;
        a {
          hyphens: manual;
          color: @color-facts-text;
          .textUnderline(@color-facts-text);
          &:hover,
          &:focus {
            .textUnderlineHover(@color-facts-text);
          }
        }
      }
    }
  }
  &.not-for-ansatte #main {
    .vrtx-facts-container,
    .vrtx-facts-container * {
      background: var(--green6);
      color: @color-facts-text;
      a {
        .textUnderline(@color-facts-text);
      }
      margin: 0;
      p {
        margin-top: 10px;
      }
      &.expanded .toggle-more {
        padding: 40px 0px 60px;
        width: 100%;
      }
    }
    .vrtx-facts-container {
      margin: 60px 0;
      .toggle-more {
        -webkit-mask-image: linear-gradient(0deg, var(--green6) 60%, transparent);
        mask-image: linear-gradient(0deg, var(--green6) 60%, transparent);
        background: linear-gradient(0deg, var(--green6) 60%, transparent);
        background: var(--green6);
        color: @color-facts-text;
        a {
          text-decoration: none;
        }
      }
    }
  }
  .vrtx-groups-related-to-project,
  .vrtx-projects {
    &.vrtx-frontpage-box > h2 {
      margin-top: 50px;
    }
  }
  .vrtx-project-contactinfo.vrtx-frontpage-box {
    margin-left: 0 !important;
    margin-bottom: 30px;
  }
  div[class*="vrtx-field-researchAbout"] {
    .accordion-wrapper {
      margin: 0;
    }
    .accordion-content[aria-hidden*="false"] {
      border-bottom: 1px solid var(--accordionBorderColor);
    }
  }
}

#vrtx-structured-project {
  #vrtx-hinn-project-content {
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
    .vrtx-hinn-content {
      .vrtx-middle-image {
        width: 100%;
      }
    }
  }
  #vrtx-content {
    .vrtx-byline {
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: 0;
      .time-and-place-columns {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        .column  {
          flex: 1 0 24%;
          margin: 0 0 10px 0;
          height: fit-content;
          height: -moz-fit-content;
          border-left: 2px solid var(--green6);
          padding: 0 20px;
          .header,
          .value,
          .urls {
            display: block;
          }
          .header {
            margin-bottom: 5px;
            font-family: var(--mediumFontStack);
          }
          .urls {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media @responsiveBreakpoint {
  #vrtx-hinn-researchgroup,
  #vrtx-structured-project {
    #vrtx-content {
      .vrtx-byline {
        .time-and-place-columns {
          flex-direction: column;
          .column  {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
