@autocompletePersonImageWidth: 80px;

body .ac_results {
  margin: 5px 0 0 0;
  z-index: 2;
}

/* Autocomplete reset css
 * http://stackoverflow.com/questions/17200335/jquery-remove-ui-autocomplete-styles-from-dropdown-results
 */
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
.ui-autocomplete, .ui-autocomplete:hover,
.ui-menu-item, .ui-menu-item:hover,
.ui-menu-item a, .ui-menu-item a:hover,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active,
.ui-menu .ui-menu-item a {
  background: #ffffff none no-repeat;
  padding: 0;
  margin: 0;
  display: block;
  border: 0;
  border-collapse: collapse;
}

.grid-container .ui-autocomplete {
  margin: 0 !important;
}

.ui-corner-all {
  .borderRadius(@radius: 0);
}

body .ac_results,
body .ui-autocomplete,
body .ui-autocomplete:hover {
  background: #fff;
  display: block;
  border: 1px solid @borderColor;
  border-top: none;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 240px;
  margin: 0;

  li,
  .ui-menu-item {
    color: @textColor;
    padding: 12px 20px 12px 20px;
    border-bottom: 1px solid @borderColor;
    overflow: hidden;

    &:before {
      display: none;
    }

    &:last-child {
      border-bottom: none;
    }

    &.ac_over,
    &.ui-state-focus {
      color: #fff;
      background: @linkColor;
      border-bottom: 2px solid @linkColor;

      .vrtx-person-search-position,
      .vrtx-person-search-tag {
        color: #fff;
      }

      .vrtx-person-search-tag-match {
        background: @textColor;
      }
    }
  }

  .vrtx-person-search-img {
    float: left;
    clear: left;
    margin: 4px 20px 0 0;
    overflow: hidden;
    width: @autocompletePersonImageWidth;
    height: @autocompletePersonImageWidth; // Square image wrapper
    .centerImageInContainer(50%, 55%);

    img {
      width: @autocompletePersonImageWidth;
    }
  }

  .vrtx-person-search-info {
    &.has-img {
      margin-left: ~"calc(@{autocompletePersonImageWidth} + 20px)";
    }

    > * {
      display: block;
    }
  }

  .vrtx-person-search-tag-match {
    background: @linkColor;
    color: #fff;
    padding: 2px 0;
  }

  .vrtx-person-search-tag-match-start {
    padding-left: 4px;
  }

  .vrtx-person-search-tag-match-end {
    padding-left: 4px;
  }
}
