// The class conference is added in local.js with the function addConferenceClassToEventFrontpage()
#vrtx-frontpage.conference {
  #main {
    .navigation-links ul li a {
      background-color: var(--eventBoxColor);
    }
    .grid-container:not(.row-all-colored),
    .grid-container {
      .vrtx-frontpage-box.img-special-left,
      .vrtx-frontpage-box.img-special-right {
        background: var(--eventBoxColor);
      }
    }
  }
  .vrtx-feed.with-images ul li {
    img {
      object-fit: contain;
    }
  }
  .vrtx-frontpage-box .button {
    color: var(--textColor);
    background-color: var(--eventBoxColor);
    &:not(.feedback-yes):not(.feedback-no):after {
      background: var(--img-arrow-big) no-repeat 0 0;
      width: 27px;
      background-size: contain;
    }
  }

  ul.collaboration-part-of {
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    li:before {
      content: "";
    }
    li.collaboration-part-of-image a {
      height: auto;
      img {
        max-height: 100%;
        height: auto !important;
      }
    }
  }
}
