#vrtx-structured-article {
  .vrtx-introduction,
  h1 {
    max-width: 100%;
  }

  .vrtx-introduction,
  h1 {
    margin-left: 0;
    margin-right: 0;
  }

  #vrtx-content {
    .vrtx-introduction + .vrtx-introduction-image:not(.small-intro-image),
    .vrtx-introduction-image + .vrtx-introduction {
      margin-top: 0;
    }

    .vrtx-introduction-image {
      max-width: 100% !important;
      margin-bottom: 30px;

      img {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    a { //:not(.button):not(.vrtx-link-check):not(.vrtx-facts-container a) {
      color: var(--linkColor);
      text-decoration-color: var(--linkColor);
      &:focus,
      &:hover {
        color: var(--textColor);
        text-decoration-color: var(--textColor);
      }
    }

    a.button {
      color: var(--btnSecondaryColor);
    }

    #vrtx-main-content .only-links li a,
    .vrtx-contact-component a,
    a.read-more {
      color: var(--textColor);
      text-decoration-color: var(--textColor);
      &:hover,
      &:focus {
        color: var(--linkColor);
        text-decoration-color: var(--linkColor);
      }
    }

    blockquote {
      max-width: 870px;
    }
    // image with extra class:
    img.xl {
      width: 870px;
      max-width: 870px;
      margin-left: -180px;
    }

    .vrtx-authors {
      max-width: 100% !important;
      margin-top: 0;
      margin-bottom: 0;
      border-top: none;
      padding-top: 0;
    }

    .published-date {
      display: inline-block;
      margin-left: 5px !important;
      margin-bottom: 40px;
    }
  }
}
// Responsive only
@media @responsiveBreakpoint {
  #vrtx-structured-article {
    #main {
      #right-main,
      #total-main,
      img.xl {
        max-width: 100%;
        min-width: auto;
      }

      img.xl {
        margin-left: 0;
      }
    }
  }
}
