.vrtx-list-articles,
.vrtx-subfolder-menu {
  .menu-title {
    font-size: 2.8rem;
    line-height: 3.6rem;
    letter-spacing: .2px;
    margin-top: 60px;
    margin-bottom: 10px;
  }
}
