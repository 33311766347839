@import 'listings/person.less';
@import 'listings/blog.less';
@import 'listings/regular.less';
@import 'listings/gallery.less';
@import 'listings/images.less';

@thumbnailImgWidth: 350px;
@thumbnailImgGap: 40px;

#vrtx-collections {
  display: none;
}

// General listing of resources

#main {
  #vrtx-daily-events,
  .vrtx-resources {
    &.without-images {
      .vrtx-daily-events-listing,
      .vrtx-resource {
        a.vrtx-title {
          .textUnderline;
          &:hover,
          &:focus {
            text-decoration-color: transparent;
          }
        }
      }
    }

    .vrtx-daily-event .vrtx-resource {
      clear: none;
      margin-bottom: 20px;
      padding-bottom: 0;
      border-bottom: none;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .vrtx-daily-events-listing,
    .vrtx-resource {
      //border-bottom: 4px solid @grayBorder;
      margin-bottom: 80px;
      width: 100%;

      &:after {
        .clearfix();
      }
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      div.vrtx-title,
      a.vrtx-title {
        margin: 0;
        padding: 0;
      }
      div.vrtx-title {
        margin-bottom: 5px;
      }
      a.vrtx-title {
        font-size: 2.8rem;
        line-height: 3.6rem;
        letter-spacing: 0.38px;

        .textUnderlineSwitch;
      }
      a.vrtx-image {
        float: left;
        margin: 6px @thumbnailImgGap 0 0;
        padding: 0;
        display: block;
        width: @thumbnailImgWidth;

        img {
          display: block; // removed bottom spacing (previously fixed by line-height)
          max-width: 100%;
        }

        ~ *:not(.vrtx-read-more) {
          display: block;
          margin-left: ~"calc(@{thumbnailImgWidth} + @{thumbnailImgGap})";
        }
      }
      .vrtx-time-and-place-container {
        abbr {
          text-decoration: none;
        }
      }
      .lastModified,
      div.vrtx-time-and-place-container,
      .vrtx-number-of-comments-add-event-container,
      .published-date {
        display: block;
        font-size: 1.6rem;
        line-height: 2.1rem;
        letter-spacing: 0.17px;
        color: #333;
        margin-bottom: 5px;
      }
      .vrtx-number-of-comments-add-event-container {
        border: none;
        padding: 0;
        margin: 0;
        height: auto;
        display: inline-block;

        a {
          font-size: inherit;
          vertical-align: inherit;
          padding: 0;
        }
      }
      .description,
      .introduction {
        margin-top: 10px;

        p:first-child { margin-top: 0;    }
        p:last-child  { margin-bottom: 0; }

        > .vrtx-read-more {
          display: block;
          height: 0;
          font-size: 0;
        }
        > p + .vrtx-read-more {
          margin-top: -40px;
        }
      }
      .vrtx-read-more {
        display: none;
      }
    }
    &.vrtx-two-columns {
      .vrtx-resource.vrtx-default-article-left:nth-last-child(2) {
        border-bottom: none;
        padding-bottom: 0;
      }
      .vrtx-resource a.vrtx-image {
        width: auto;
        float: none;
        margin: 0 0 20px 0;

        ~ * {
          margin-left: 0;
        }
      }
      &.vrtx-resource-prioritize-first {
        > .vrtx-resource:first-child a.vrtx-image {
          width: auto;
          margin-right: 0;

          ~ * {
            margin-left: 0;
          }
        }
      }
    }
  }

  .vrtx-paging-feed-wrapper,
  div.vrtx-feed-link {
    font-size: 2.3rem;
    line-height: 3.1rem;
    letter-spacing: .4px;
  }

  .vrtx-paging-feed-wrapper {
    margin: 90px 0 0 0;
    padding: 0;
    clear: left;

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    .vrtx-subscribe-wrapper { // dropdown
      left: 0;
      right: auto;
      top: auto;
      font-size: 1.8rem;
      a {
        .textUnderlineSwitch;
      }
    }

    .vrtx-paging-wrapper {
      display: block;
      margin: 0 0 40px 0;
      line-height: 1.5;

      > a {
        display: inline-block;
        color: #555;
        margin-right: 15px;
        padding: 0;

        .textUnderlineSwitch;

        &.vrtx-previous {
          margin-right: 40px;
          margin-left: 0;
        }
        &.vrtx-next {
          margin-right: 0;
        }
        &:nth-last-child(2) {
          margin-right: 40px;
        }
        &.vrtx-previous,
        &.vrtx-next,
        &.vrtx-marked {
          color: #000;
        }
      }
    }
  }

  div.vrtx-feed-link {
    height: auto;
    font-size: inherit;
    line-height: inherit;
    background: none;

    a {
      display: inline-block;
      padding: 9px 0 9px 45px;
      color: @textColor;
      background: url('@{imagePath}listings/rss-feed.png') no-repeat center left;

      .textUnderlineSwitch;
    }
  }
  #vrtx-events-nav {
    margin-top: 40px;

    a {
      &:before {
        display: block;
        content: " ";
      }
    }
  }
}

// Folder listing

#vrtx-folder-menu ul {
  margin-top: 0;
}

// Introduction

#main {
  #vrtx-main-content {
    &:not(.vrtx-empty-additional-content):not(.vrtx-hide-additional-content-true):not(:last-child) {
      .vrtx-events-calendar-introduction ~ #vrtx-daily-events,
      .vrtx-introduction ~ .vrtx-resources {
        padding-top: 40px;
        // border-top: 4px solid @grayBorder;

        >h2:first-child {
          margin-top: 0;
          padding-top: 0;
          border-top: none;
        }
      }
      .vrtx-introduction[style*="display: none"] ~ .vrtx-resources {
        padding-top: 0;
        border-top: none;

        >h2:first-child {
          margin-top: 0;
          padding-top: 0;
          border-top: none;
        }
      }
    }
  }
}

// Where event listing has "Kommende" / "Tidligere" h2 before

#main {
  #vrtx-daily-events,
  .eventListing\.ongoingEvents,
  .eventListing\.upcomingEventsSearchComponent,
  .eventListing\.previousEventsSearchComponent,
  .vrtx-events-further-upcoming {
    h2 {
      font-size: 4rem;
      line-height: 4.9rem;
      letter-spacing: .2px;
      margin-bottom: 20px;
    }
    .vrtx-date-icon,
    .vrtx-daily-events-date {
      font-size: 3.1rem;
      line-height: 3.8rem;
      letter-spacing: .2px;
      margin-bottom: 15px;
      display: block;
    }
    .vrtx-date-icon-today + .vrtx-date-icon-month,
    .vrtx-date-icon-tomorrow + .vrtx-date-icon-month,
    .vrtx-daily-events-date-today + .vrtx-daily-events-date-month,
    .vrtx-daily-events-date-tomorrow + .vrtx-daily-events-date-month {
      display: none;
    }
    .vrtx-daily-events-listing {
      margin-bottom: 90px;
    }
    .vrtx-daily-event .vrtx-resource,
    .vrtx-resource {
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
      a.vrtx-title {
        font-size: 2.6rem;
        line-height: 3.1rem;
        letter-spacing: 0.39px;
      }
    }
  }
}

// Responsive

@media @responsiveBreakpoint {

  #main {
    #vrtx-daily-events,
    .vrtx-resources {
      .vrtx-daily-events-listing,
      .vrtx-resource {
        margin-bottom: 40px;

        a.vrtx-title {
          font-size: 3rem;
          line-height: 4rem;
          letter-spacing: 0.4px;
        }
        a.vrtx-image {
          float: none;
          margin: 0 0 20px 0;
          width: auto;

          ~ *:not(.vrtx-read-more) {
            margin-left: 0;
          }
        }
        .lastModified,
        div.vrtx-time-and-place-container,
        .vrtx-number-of-comments-add-event-container,
        .published-date {
          .baseFontSize;
        }
      }
    }

    .vrtx-paging-feed-wrapper {
      margin: 60px 0 0 0;

      .vrtx-paging-wrapper {
        max-width: none;

        + div.vrtx-feed-link {
        //  margin-top: 20px;
        }
      }
    }
  }
  #main {
    #vrtx-daily-events,
    .eventListing\.ongoingEvents,
    .eventListing\.upcomingEventsSearchComponent,
    .eventListing\.previousEventsSearchComponent,
    .vrtx-events-further-upcoming {
      h2 {
        font-size: 3rem;
        line-height: 3.9rem;
        letter-spacing: .2px;
        margin-bottom: 20px;
      }
      .vrtx-date-icon,
      .vrtx-daily-events-date {
        font-size: 2.8rem;
        line-height: 3.4rem;
        letter-spacing: .2px;
        margin-bottom: 15px;
        display: block;
      }
      .vrtx-date-icon-today + .vrtx-date-icon-month,
      .vrtx-date-icon-tomorrow + .vrtx-date-icon-month,
      .vrtx-daily-events-date-today + .vrtx-daily-events-date-month,
      .vrtx-daily-events-date-tomorrow + .vrtx-daily-events-date-month {
        display: none;
      }
      .vrtx-daily-events-listing {
        margin-bottom: 40px;
      }
      .vrtx-daily-event .vrtx-resource,
      .vrtx-resource {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
        a.vrtx-title {
          font-size: 2.6rem;
          line-height: 3.1rem;
          letter-spacing: 0.39px;
        }
      }
    }
  }
}
