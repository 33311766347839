#vrtx-program-option-frontpage-change-language-link,
#vrtx-program-frontpage-change-language-link,
#vrtx-organizational-unit-change-language-link,
#vrtx-internal-organizational-unit-corresponding-folder-link,
#vrtx-change-language-link,
#vrtx-person-change-language-link,
#vrtx-course-navigation,
#left-main .language-switch-link {
  float: none;
  margin: 80px 0 0 0;
  .textUnderline(@textColorUnderline);
  font-size: 1.4rem;
  line-height: 2rem;
  width: 105px;

  .offscreen-screenreader {
    display: inline;
    position: static;
    width: auto;
    height: auto;
  }
}
#left-main .language-switch-link,
#vrtx-person-change-language-link,
#vrtx-program-option-frontpage-change-language-link,
#vrtx-program-frontpage-change-language-link,
#vrtx-organizational-unit-change-language-link,
#vrtx-internal-organizational-unit-corresponding-folder-link,
#vrtx-change-language-link,
#vrtx-course-navigation {
  display: none;
}
body:not(.sidebar-menu-wrapper-visible) #left-main {
  .language-switch-link {
    display: block;
  }
}

#MathJax_Message {
  display: none;
}

@media @desktopSmallBreakpoint {

  body:not(.sidebar-menu-wrapper-visible) {
    #vrtx-person-change-language-link,
    #vrtx-program-option-frontpage-change-language-link,
    #vrtx-program-frontpage-change-language-link,
    #vrtx-organizational-unit-change-language-link,
    #vrtx-internal-organizational-unit-corresponding-folder-link,
    #vrtx-change-language-link,
    #vrtx-course-navigation {
      display: block;
      width: auto;
      margin: 0 0 10px 0;
      clear: left;
    }
  }
  body:not(.sidebar-menu-wrapper-visible) {
    &#vrtx-frontpage #vrtx-change-language-link {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  body:not(.sidebar-menu-wrapper-visible) #left-main {
    .language-switch-link {
      display: none;
    }
  }
}

@media @desktopSmallOnlyBreakpoint {

  body:not(.sidebar-menu-wrapper-visible) {
    &#vrtx-frontpage #vrtx-change-language-link {
      margin-left: 25px;
    }
  }
}