// event feed

body,
#vrtx-frontpage #main,
#main {
  /* fix img in article + event feed  + blog feed */
  .vrtx-event-component-picture,
  .vrtx-feed.with-images .items .vrtx-image,
  .vrtx-resources.vrtx-resources-frontpage article .vrtx-image {
    img {
      min-width: 100%;
    }
  }

  /* feeds articles and events */
  .grid-container {
    .two-column-feed,
    .third-box-left,
    .third-box-middle,
    .third-box-right,
    .three-column-feed {
      .vrtx-feed.with-images,
      .vrtx-event-component.with-images .vrtx-event-component-item {
        .vrtx-list-item-content:only-child {
          background: var(--feedBoxColor);
          padding: 30px 30px 60px 30px;
          .vrtx-time-and-place-container a,
          .item-description a {
            .transition(all, 1.5s, ease-in);
            &:hover,
            &:focus {
              color: var(--textColor);
              .textUnderlineHover(var(--textColorUnderline));
            }
          }
        }
      }
      .vrtx-feed,
      .vrtx-event-component .vrtx-event-component-item {
        .vrtx-event-component-title,
        .item-title {
          font-size: 2.3rem;
          text-decoration: none;
          text-decoration-color: transparent;
          &:hover,
          &:focus {
            color: var(--textColor);
            .textUnderlineHover(var(--textColorUnderline));
          }
        }
      }
      &.research,
      li[data-categories="type:structured-article tag:forskning"] {
        .vrtx-feed.with-images {
          .vrtx-list-item-content:only-child {
            background: var(--infoBoxColorDark);
            *,
            .published-date {
              color: white;
            }
            a {
              text-decoration-color: white;
              &:hover,
              &:focus {
                color: white;
                .textUnderlineHover(white);
              }
            }
          }
        }
      }
      .vrtx-event-component.with-images .vrtx-event-component-item {
        .vrtx-list-item-content:only-child {
          background: var(--eventBoxColor);
        }
      }
      .vrtx-event-component .vrtx-event-component-item,
      .vrtx-feed ul li {
        .vrtx-image img {
          max-height: none;
        }
        &:before {
          display: none;
        }
      }
    }
  }
}
/* feeds  */

/* feeds news */
#vrtx-frontpage #main {
  .three-column-feed {
    .vrtx-feed {
      ul li {
        .item-title {
          letter-spacing: 0;
          font-size: 2.3rem;
        }
        .published-date {
          margin-top: 0;
          display: block;
        }
      }
      a.all-messages {
        display: none;
      }
    }
  }
}
// Responsive only
@media @responsiveBreakpoint {
  #main #right-main,
  #main #total-main {
    #vrtx-daily-events:not(.with-images) .vrtx-daily-events-listing,
    .vrtx-event-component:not(.with-images) .vrtx-daily-events-listing,
    #vrtx-daily-events:not(.with-images) .vrtx-event-component-item,
    .vrtx-event-component:not(.with-images) .vrtx-event-component-item {
      padding-bottom: 0;
    }
    /* feeds */
    .two-column-feed,
    .three-column-feed {
      .vrtx-feed.with-images ul.items,
      .vrtx-feed ul.items,
      .vrtx-event-component {
        // new:
        display: flex;
        flex-wrap: wrap;
        .vrtx-event-component-item,
        li {
          //font-size: 2.3rem;
          width: 50%;
          float: left;
          &:nth-of-type(odd) {
            padding-right: 10px;
          }
          &:nth-of-type(even) {
            padding-left: 10px;
          }
          .item-title {
            font-size: 2.3rem;
          }
        }
      }
    }
  }
}

// Between responsive and mobile
@media only screen and (max-width: 550px) {
  #main #right-main,
  #main #total-main {
    /* feeds */
    .two-column-feed,
    .three-column-feed {
      .vrtx-feed.with-images ul.items,
      .vrtx-feed ul.items,
      .vrtx-event-component {
        .vrtx-event-component-item,
        li {
          width: 100%;
          &:nth-of-type(odd) {
            padding-right: 0;
          }
          &:nth-of-type(even) {
            padding-left: 0;
          }
        }
      }
    }
  }
}
