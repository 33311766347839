h1 {
  max-width: @h1IntroWidth;
}

.vrtx-introduction,
#vrtx-frontpage #vrtx-frontpage-introduction,
#vrtx-frontpage .vrtx-introduction,
.display-as-introduction p,
.vrtx-organizational-unit-introduction {
  font-family: @secondaryFontStack;
  font-size: 3.3rem;
  line-height: 4rem;
  letter-spacing: 0.3px;
  max-width: @h1IntroWidth;
}

// Introduction image text
.vrtx-imagetext {
  max-width: @h1IntroWidth;
}

.vrtx-introduction,
#vrtx-frontpage #vrtx-frontpage-introduction,
#vrtx-frontpage .vrtx-introduction,
.vrtx-organizational-unit-introduction,
.vrtx-introduction-image {
  margin-bottom: 75px;
}

.vrtx-organizational-unit-introduction,
.vrtx-introduction {
  p:first-child { margin-top: 0;    }
  p:last-child  { margin-bottom: 0; }
}

.vrtx-introduction + .vrtx-introduction-image:not(.small-intro-image),
.vrtx-introduction-image + .vrtx-introduction {
  margin-top: -30px;
}

.vrtx-byline {
  font-size: 2.8rem;
  line-height: 3.6rem;
  letter-spacing: 0.4px;
  margin-top: 30px;
  margin-bottom: 60px;

  abbr {
    text-decoration: none;
  }
}

.vrtx-introduction + .vrtx-byline {
  margin-top: -50px;
}

.vrtx-authors,
.vrtx-date-info {
  clear: both;
  margin-top: 60px;
  border-top: 1px solid #000;
  padding-top: 40px;
  margin-bottom: 90px;
  color: #434344;
}

.vrtx-authors {
  display: block;
}

.vrtx-authors + .vrtx-date-info {
  margin-top: -80px;
  padding-top: 0;
  border-top: none;
}

.vrtx-tags {
  display: block;
  margin: 40px 0;
}

// When unpublished resource
p + .vrtx-social-components {
  margin-top: 60px;
}

// Responsive

@media @responsiveBreakpoint {

  .vrtx-introduction,
  #vrtx-frontpage #vrtx-frontpage-introduction,
  #vrtx-frontpage .vrtx-introduction,
  .vrtx-organizational-unit-introduction {
    font-size: 2.8rem;
    line-height: 3.7rem;
    letter-spacing: 0.26px;
  }

  .vrtx-introduction,
  #vrtx-frontpage #vrtx-frontpage-introduction,
  #vrtx-frontpage .vrtx-introduction,
  .vrtx-organizational-unit-introduction,
  .vrtx-introduction-image {
    margin-bottom: 40px;
  }

  .vrtx-introduction + .vrtx-introduction-image,
  .vrtx-introduction-image + .vrtx-introduction {
    margin-top: -10px;
  }

  .vrtx-introduction+.vrtx-byline {
    margin-top: -30px;
  }

  h1+.vrtx-introduction-image {
    margin-top: 0;
  }
  .vrtx-introduction-image {
    max-width: 100% !important;  // override js intro scaling based on width

    &:not(.small-intro-image) {
      margin-left: 0;
    }
  }
}
