.vrtx-feedback {
  padding: 50px 90px 50px 90px;
  background: #f0f1f4;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;

  .vrtx-feedback-title {
    font-size: 2.8rem;
    line-height: 36px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .vrtx-feedback-thanks {
    margin: 40px 0 0 0;
  }

  ul {
    overflow: hidden;
    margin: 30px 0 0 0;

    li {
      margin-left: 0;
      width: 50%;
      float: left;
      &:first-child {
        padding-right: 20px;
      }
      &:last-child {
        padding-left: 20px;
      }
      &:before {
        display: none;
      }
    }
    .button {
      background: transparent;
      max-width: 100%;
      font-size: 1.9rem;
      line-height: 2.9rem;
      border: none;
      border-bottom: 1px solid #3e31d6;

      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
  }
}

@media @responsiveBreakpoint {

  .vrtx-feedback {
    text-align: left;
    padding: 40px 15px;

    ul li:nth-child(n) {
      width: auto;
      float: none;
      padding: 0;
    }
  }
}
