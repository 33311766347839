/* custom-elements.less */
// mixins

abbr {
  text-decoration: none;
}

.green-link {
  color: var(--linkColor);
  text-decoration-color: var(--linkColor);
}

.dark-link {
  color: var(--textColor);
  text-decoration-color: var(--textColor);
}

.white-link {
  color: white;
  text-decoration-color: white;
}

.arrow-big-green-bg-image {
  background-image: var(--img-arrow-big-green);
}

.arrow-big-bg {
  background: var(--img-arrow-big) no-repeat 0 0;
}
// introduction

#vrtx-frontpage #vrtx-frontpage-introduction,
#vrtx-frontpage #vrtx-frontpage-introduction .vrtx-introduction,
.vrtx-introduction,
.vrtx-introduction-image,
.vrtx-organizational-unit-introduction {
  margin-bottom: 40px;
}

.vrtx-introduction + .vrtx-byline {
  margin-top: 0;
}
// Language link

#vrtx-change-language-link {
  float: right;
}
// Big search- on search hits pages

.vrtx-additional-search-info {
  margin: 30px 0;
  padding: 25px 30px;
  display: block;
  border: 3px solid var(--orange);
  box-sizing: border-box;

  a {
    .green-link();

    &:focus,
    &:hover {
      .dark-link();
    }
  }
}

// Breadcrumbs (new)
#breadcrumbs a:before {
  background-position: center left;
  top: 2px;
}

//html:not(.special-page-config-header-minimal)

#vrtx-breadcrumb-wrapper #vrtx-breadcrumb {
  padding-top: 30px;
}
// FACTS CONTAINER

.not-for-ansatte #main .vrtx-facts-container {
  background: var(--infoBoxDefaultColor);
  margin: 40px 0;

  *,
  a {
    color: var(--textColor);
    /* override link color in articles */
    text-decoration-color: var(--textColor);
  }

  .toggle-more {
    padding: 95px 40px 50px;
    background: linear-gradient(
      rgba(156, 211, 155, 0.24),
      rgba(156, 211, 155, 0.9),
      var(--infoBoxDefaultColor),
      var(--infoBoxDefaultColor),
      var(--infoBoxDefaultColor),
      var(--infoBoxDefaultColor)
    );

    &::before {
      //content: "";
      display: none;
    }

    a {
      width: auto;
      border: 1px solid;
      border-radius: 25px;
      padding: 5px 20px;
      bottom: 40px;
      left: 60px;
      text-decoration: none;
      .dark-link();

      &::before {
        content: "+";
        padding-right: 10px;
        font-size: 2.44rem;
        font-family: var(--mainFontStack);
        font-weight: 300;
        display: inline-block;
      }

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }

    &:focus a,
    &:hover a {
      text-decoration: underline;
    }
  }

  &.toggle-container {
    &.expanded {
      .toggle-more::before {
        content: "";
      }

      .toggle-more {
        padding: 40px 60px 60px;

        a {
          display: block;
          width: fit-content;
          width: -moz-fit-content;
          left: 0;
          bottom: 0;
          .dark-link();
          padding: 5px 20px;

          &::before {
            content: "-";
          }
        }
      }
    }
  }

  &.info-fullwidth {
    .toggle-more a {
      padding-left: 20px;
      padding-right: 20px;
      left: 60px;
    }
  }

  &.dark {
    background: var(--infoBoxColorDark);

    *,
    a {
      .white-link();
    }

    .toggle-more {
      background: linear-gradient(
        rgba(0, 64, 2, 0.25),
        rgba(0, 64, 2, 0.89),
        var(--infoBoxColorDark),
        var(--infoBoxColorDark),
        var(--infoBoxColorDark)
      );
    }

    &.toggle-container {
      &.expanded {
        .toggle-more {
          a {
            .white-link();
          }
        }
      }
    }
  }
}

.togglable:before { // +
  font-size: 2.6rem;
  top: -0.8ex;
}
.togglable[aria-expanded="true"]:before { // -
  font-size: 2.6rem;
  top: -0.9ex;
}

.inn-facts-table {
  display: flex;
  flex-wrap: wrap;
  div  {
    flex: 1;
    text-align: center;
    span {
      padding: 16px 20px;
      display: block;
      &:first-of-type  {
        font-weight: bold;
        font-size: 4rem;
      }
    }
  }
}
/* social components */
#main {
  .vrtx-social-components {
    a {
      text-indent: -9999px;
      margin-left: 15px;
      &::before {
        background: var(--icon-mail) no-repeat 50%
          50% var(--publishedDateGrey);
        border-radius: 50%;
        background-size: 55% auto;
      }
    }

    a.facebook,
    li.vrtx-share-at-Facebook {
      a::before {
        background: var(--icon-fb) no-repeat;
      }
      a:hover::before {
        opacity: 0.6;
      }  
    }
    
    a.instagram::before {
      background: var(--icon-instagram) no-repeat;
    }

    li.vrtx-share-at-Twitter a::before {
      background: var(--icon-twitter) no-repeat;
    }
  }

  .vrtx-social-components {
    span {
      display: block;
      margin-right: 0;
      font-size: 1.8rem;
      font-family: var(--mediumFontStack);
      font-weight: 600;
    }

    .vrtx-share-at-component {
      float: right;

      ul li:last-child a {
        margin-right: 0;
      }
    }
  }
}

#vrtx-structured-article,
#vrtx-structured-event,
#vrtx-web-page {
  .vrtx-social-components {
    width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
  .vrtx-date-info {
    margin-bottom: 0;
    padding-top: 20px;
    .published-date,
    .published-date-label,
    .vrtx-authors, .separator-date {
      display: none;
    }
  }
}

blockquote {
  margin: 50px 0;
  font-size: 2.8rem;
  font-family: var(--tertiaryFontStack);
  font-weight: 600;
  p {
    &:first-of-type::before {
      content: var(--img-quote-open);
      padding-right: 15px;
      top: 2px;
    }
    &:last-of-type::after {
      content: var(--img-quote-close);
      padding-left: 15px;
      margin-top: 12px;
      position: relative;
    }
    &:last-child {
      font-weight: 400;
    }
  }
}

/* images and figcaption */
.vrtx-imagetext,
figcaption {
  margin: 10px 0 0;
}
// tiles &  submenu as tiles:
// More styling for Custom sub menu – #center-menu-same-level-folders is in "custom-menu.less"

#center-menu-same-level-folders,
#main {
  #vrtx-semester-links,
  .navigation-links ul,
  .vrtx-subfolder-menu ul,
  ul.navigation-links,
  ul.vrtx-breadcrumb-menu {
    li ul {
      display: unset;
      li {
        color: white;
        padding: 20px 25px !important;
        width: 100%;
      }
    }
    li:has(li) a {
        height: fit-content;
    }
    li a {
      line-height: 1.3;
      .dark-link();
      font-size: 2.1rem;
      display: block;
      text-decoration: none;
      position: relative;
      padding: 20px 60px 20px 25px;
      background-color: var(--tilesColor);
      word-break: break-word;

      &:focus,
      &:hover {
        background-color: var(--tilesColorHover);
      }

      &:after {
        background: var(--img-arrow-big) no-repeat 0 0;
        background-size: auto 100%;
        top: 28px;
        content: " ";
        display: inline-block;
        width: 26px;
        height: 16px;
        position: absolute;
        right: 20px;
      }
    }
  }

  .navigation-links li {
    padding: 2px;
  }
  .navigation-links:not(.navigation-links-three-columns) li,
  .navigation-links:not(.navigation-links-three-columns) ul li {
    &:nth-child(2n + 1) {
      padding-right: 15px;
      padding-left: 2px;
    }

    &:nth-child(2n + 2) {
      padding-left: 15px;
      padding-right: 2px;
    }
  }
  .navigation-links.green1 ul {
    li a {
      background-color: var(--green1);
      .white-link();

      &:focus,
      &:hover {
        background-color: var(--green1Hover);
      }
    }
  }
  .navigation-links.green5 ul {
    li a, ul li {
      background-color: var(--green5);
      .white-link();
      &:focus,
      &:hover {
        background-color: var(--green5Hover);
      }
    }
  }
  .navigation-links.color-green ul,
  .navigation-links.green6 ul {
    li a, ul li {
      background-color: var(--green6);
      .white-link();
      &:focus,
      &:hover {
        background-color: var(--green6Hover);
      }
    }
  }
}
/* if dark green navigation links */
#main .navigation-links.color-green ul li a:after,
#main .navigation-links.green5 ul li a:after,
#main .navigation-links.green6 ul li a:after {
  background: var(--img-arrow-big-white) no-repeat;
  background-size: auto 100%;
}
.read-more-small:after,
.read-more:after,
.read-more:before,
.vrtx-more a:after {
  background: var(--img-arrow-big) no-repeat 0 0;
}

#main .grid-container .vrtx-frontpage-box {
  &.img-special-left,
  &.img-special-right {
    h2 a {
      &::after,
      &:before {
        background-image: var(--img-arrow-big);
      }
      &.read-more {
        &:focus,
        &:hover {
          &::after,
          &:before {
            background-image: var(--img-arrow-big);
          }
        }
      }
    }

    &.dark-grey-box,
    &.green6 {
      h2 a {
        &::after,
        &:before {
          background-image: var(--img-arrow-big-white);
        }

        &.read-more {
          &:focus,
          &:hover {
            &::after,
            &:before {
              background-image: var(--img-arrow-big-white);
            }
          }
        }
      }
    }
  }
}
/* vrtx more */
.vrtx-frontpage-box .vrtx-more {
  margin-top: 40px;
}

.read-more,
.read-more-small,
.vrtx-more a {
  text-decoration: none;
  border-bottom: 0.3em solid transparent;
  box-shadow: 0px 0.03em 0px 0px var(--textColor);
  &::after,
  &::before {
    background: var(--img-arrow-big) no-repeat 0 0;
    background-size: auto 0.6em;
    top: 0.35em
  }
  &:focus,
  &:hover {
    box-shadow: 0px 0.03em 0px 0px var(--linkColor);
    text-decoration: none;
    &:after,
    &:before {
      background-image: var(--img-arrow-big-green);
      background-size: auto 0.6em;
    }
  }
}

.vrtx-frontpage-box.img-special-left,
.vrtx-frontpage-box.img-special-right {
  .read-more {
    &:focus,
    &:hover {
      &:after,
      &:before {
        background-image: var(--img-arrow-big);
        background-size: auto 0.6em;
      }
    }
  }
}
// Linklist
/* may use this in other doctypes */
//vrtx-hinn-exchange-destination
body .main #vrtx-content #vrtx-main-content {
  #vrtx-hinn-studieprogram-content,
  * {
    .vrtx-hinn-linklist {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      margin: 20px 0;
      li {
        margin: 7.5px 0;
      }
    }
    .half-width .vrtx-hinn-linklist li {
      width: 100%;
    }
  }
  
  #vrtx-hinn-exchange-content {
    .vrtx-hinn-infobox {
      .vrtx-introduction-image {
        background-color: var(--tilesColor);
      }
    }
    .vrtx-hinn-linklist {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 40px;
      row-gap: 10px;
      margin: 15px 0 0;
      li {
        margin: 0;
      }
    }
  }
}

// crisis banner

#crisis-banner, #vrtx-structured-article #vrtx-content #crisis-banner-demo {
  background-color: var(--color-crisis);
  padding: 30px 15px;
  position: relative;
  z-index: 10;
  * {
      color: white;
      text-decoration-color: white;
  }
  
  .crisis-banner-inner {
    width: fit-content;
    max-width: 100vw;
    margin: 0 auto;
    padding-left: 50px;
    position: relative;
    text-align: center;
    &::before {
      content: "";
      background: var(--img-crisis) no-repeat center;
      width: 1.7em;
      height: 2em;
      top: -4px;
      left: 0px;
      position: absolute;
      background-size: contain;
      padding-left: 5px;
      margin-right: 5px;
    }
  }
  &.info {
    background: var(--btnPrimaryBg);
    padding: 15px;
    *, a {
      .dark-link();
    }
    .crisis-banner-inner {
      &::before {
        content: "";
        background: var(--img-alert) no-repeat center;
        width: 1.5em;
        height: 1.5em;
        top: 0px;
        left: 0px;
      }
    }
  }
}

// Search buttons : change search icon

#error .searchform button[type="submit"],
form.vrtx-search-form button[type="submit"],
form.vrtx-big-search button[type="submit"],
.search-form button[type="submit"],
form#enheter button[type="submit"],
form#emner button[type="submit"],
form#personer button[type="submit"],
#error .searchform input[type="submit"],
form.vrtx-search-form input[type="submit"],
form.vrtx-big-search input[type="submit"],
.search-form input[type="submit"],
form#enheter input[type="submit"],
form#emner input[type="submit"],
form#personer input[type="submit"] {
  background-image: var(--icon-magnifyer);
  background-repeat: no-repeat;
  background-position: 0 0;
  opacity: 0.6;
  top: 1.2em;
  right: 1em;
  width: 1.2em;
  height: 1.2em;
}

.search-form button[type="submit"] {
  top: 16px;
  right: 12px;
  position: relative;
}

// Search results
body .ac_results ul {
  margin: 0;
  max-height: 399px !important;
  li {
    margin: 0;
  }
}


#vrtx-program-listing .vrtx-programs-inactive,
#vrtx-program-frontpage .vrtx-program-options,
#vrtx-program-links,
#vrtx-program-option .vrtx-program-title-box,
#vrtx-course-toc,
#vrtx-course-semesters ul,
#vrtx-semester .vrtx-teacher-list-participants,
#vrtx-semester-resource-links,
#vrtx-hinn-programme-presentation #vrtx-hinn-studieprogram-content .vrtx-hinn-linklist,
#vrtx-hinn-exchange-destination #vrtx-hinn-exchange-content .vrtx-hinn-linklist,
.main ul.only-links {
  li {
    margin-left: 0;
    margin-bottom: 20px;
    &:before {
      display: none;
    }
    a:not(.item-title):not(.button):not(.button-large):not(.vrtx-button):not(.vrtx-field-contact) {
      border-bottom: 0.3em solid transparent;
      color: var(--textColor);
      text-decoration: none;
      padding-left: 1.9em;
      display: inline-block;
      &:before {
        content: "";
        background-image: var(--img-arrow-big);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: auto 0.6em;
        height: 1em;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0.42em;
        .transition(left, 0.2s, ease-in-out);
      }
      &:hover,
      &:focus {
        //box-shadow: 0px 0.03em 0px 0px var(--linkColor);
        text-decoration: underline;
        color: var(--linkColor);
        &:before {
          background-image: var(--img-arrow-big-green);
          background-size: auto 0.6em;
          left: 0.2em;
          width: 100%;
        }
      }
    }
  }
}


// hide arrow in slideshow:
.main
  ul.only-links.vrtx-image-listing-include-thumbs
  li
  a:not(.button):not(.button-large):before {
  display: none;
}

#vrtx-hinn-programme-presentation {
  .only-links {
    margin-bottom: 20px;
  }
}

input#person-search[type="text"],
input#person-search[type="search"] {
  padding: 0.9em 3em 0.8em 1em;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 2px solid var(--textColor);
  &:hover,
  &:focus {
    border: 1px solid var(--textColor);
    border-bottom-width: 2px;
  }
  &:focus-visible {
    outline: 3px solid -webkit-focus-ring-color;
    border-bottom: 2px solid var(--textColor);
  }
  &.click-focus {
    outline: none;
  }
}

// buttons
.vrtx-button:not(form),
.button,
input[type="button"],
input[type="submit"],
input[type="reset"],
.ui-dialog.ui-widget button,
button {
  background: var(--btnPrimaryColor);
  border: none;
  padding: 0.8em 0.8em 0.75em 0.85em;
}

.leganto-url .button:not(.feedback-yes):not(.feedback-no),
.leganto-url .vrtx-button-larger:not(form),
.vrtx-frontpage-box .button-large,
.vrtx-frontpage-box .button:not(.feedback-yes):not(.feedback-no),
.vrtx-frontpage-box .vrtx-button-larger:not(form),
.vrtx-frontpage-box .vrtx-button:not(form),
.vrtx-frontpage-box input[type="button"],
.leganto-url input[type="button"],
.vrtx-frontpage-box input[type="submit"],
.leganto-url input[type="submit"],
.vrtx-frontpage-box input[type="reset"],
.leganto-url input[type="reset"],
.vrtx-frontpage-box .ui-dialog.ui-widget button,
.leganto-url .ui-dialog.ui-widget button,
.vrtx-frontpage-box button,
.leganto-url button,
a.button:not(.feedback-yes):not(.feedback-no) {
  &:after {
    background-image: var(--img-arrow-big-white);
    content: "";
    background-position-x: 0;
    background-position-y: 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-size: 100% auto;
    height: 1.1em;
    width: 1.1em;
    position: absolute;
    top: 1.2em;
    right: 0.9em;
  }

  &.external {
    &:after {
      transform: rotate(320deg);
    }
  }

  &.green1 {
    background-color: var(--green1);
    &:focus,
    &:hover {
      background-color: var(--green1Hover);
    }
  }

  &.green5 {
    background-color: var(--green5);
    &:focus,
    &:hover {
      background-color: var(--green5Hover);
    }
  }

  &.green6 {
    background-color: var(--green6);
    &:focus,
    &:hover {
      background-color: var(--green6Hover);
    }
  }

  &.orange {
    background-color: var(--orange);
    &:focus,
    &:hover {
      background-color: var(--orangeHover);
    }
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }
}

//Contact component
.vrtx-contact-component {
  position: relative;
  margin: 15px 0 30px 0;
  padding-right: 20px;
  min-height: 133px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
  a:not(.name a):not(.contact a) {
    position: absolute;
    left: 0;
    top: 0;

    img {
      width: 100px;
    }
  }
  a:not(.name a)+.name,
  a:not(.name a)+.name+.title,
  a:not(.name a)+.name+.title+.contact, .addresses {
    margin-left: 100px;
    padding-left: 20px;
  }
  .name {
    margin-bottom: 8px;
  }
  .title,
  .contact {
    font-size: 1.6rem;
  }
  .contact {
    * {
      float: left;
      margin-right: 5px;
      font-size: 1.6rem;
    }
    dt {
      display: none;
    }
    dd {
      margin: 0 0 25px 0;
      &:not(:last-of-type):after {
        content: "|";
        display: inline-block;
        margin: 0 5px;
      }
      a {
        position: relative;
      }
    }
  }
  .addresses {
    margin-top: 25px;
    clear: both;
  }
}
// Remove uio-style from button:

html body {
  .button,
  .button-large,
  .button-large-blue,
  .vrtx-button-larger:not(form),
  .vrtx-button:not(form),
  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  .ui-dialog.ui-widget button,
  button {
    background-color: var(--btnPrimaryColor);
    &.no-styling {
      font-size: normal;
      line-height: 1.5;
      font-family: var(--mainFontStack);
      letter-spacing: 0;
      max-width: none;
      width: auto;
      padding: 0;
      margin: 0;
      background: transparent;
      background-color: transparent;
      color: var(--textColor);
      border: none;
      text-align: left;
      cursor: pointer;
      text-decoration: none;
      outline: none;
      display: inline-block;
      -webkit-appearance: none;
      appearance: none;
      -webkit-border-radius: 0;
      border-radius: 0;
      position: relative;
    }
    &:hover, &:focus {
      background-color: var(--btnPrimaryColorHover);
    }
  }
}
// Buttons with dark arrow

#main .button-large,
#main .button.orange,
#vrtx-structured-event .button,
.button-large,
.button-large-blue,
.vrtx-button-larger:not(form),
.vrtx-frontpage-box .button-large,
.vrtx-frontpage-box .button.orange {
  color: var(--textColor);
  background-color: var(--btnPrimaryBg);
  &:after {
    background-image: var(--img-arrow-big);
  }

  &:focus,
  &:hover {
    background-color: var(--btnPrimaryBgHover);
    border-color: var(--btnPrimaryBgHover);
  }

  &.external {
    &:after {
      transform: rotate(320deg);
    }
  }
}
#main .button-large,
#main .button.orange,
.vrtx-frontpage-box .button.orange {
  background: transparent;
  border: 2px solid var(--btnPrimaryBg);
  &:focus,
  &:hover {
    background-color: var(--btnPrimaryBgHover);
    border-color: var(--btnPrimaryBgHover);
  }
}
// buttons for external-links:
//buttons in events and structured articles

#vrtx-hinn-programme-presentation,
#vrtx-structured-article,
#vrtx-structured-event,
#vrtx-web-page {
  //#vrtx-content {
    .button, // :not([type="submit"]):not(.marketing-yes-d):not(.marketing-no-d),
    button:not([type="submit"]) {
      margin: 20px 0;
    }
  //}
}

#vrtx-web-page {
  .vrtx-date-info-with-content-manager {
    background-color: var(--alternateRowColor);
    padding: 30px;
    margin-top: 40px;
    .content-manager-section {
      display: block;
      font-size: 1.6rem;
      line-height: 1.5;
      font-family: var(--smallTxtFontStack);
    }
    .last-modified-date {
      color: var(--textColor);
    }
  }
}

.media-portrait-right, .media-portrait-left {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 60px;
  max-width: 1000px;
  align-items: center;
  justify-items: start;
  img {
    max-width: 315px;
  }
  blockquote {
    margin: 0;
    padding: 0;
  }
}
.media-portrait-right {
  grid-template-columns: auto max-content;
  .vrtx-media-player {
    order: 1;
  }
}
@media @responsiveBreakpoint {
  .media-portrait-left,
  .media-portrait-right {
    grid-template-columns: auto;
    gap: 0;
    align-items: center;
    justify-items: left;
    margin-bottom: 30px;
    img {
      max-width: 100%;
    }
  }
  .media-portrait-right .vrtx-media-player {
    order: 0;
  }
}

@media print {
  .accordion-wrapper .accordion-content {
    display: block !important;
  }

  .ui-accordion-header .ui-accordion-header-icon.ui-icon {
    display: none;
    opacity: 0;
  }

  #main .ui-accordion.ui-widget .ui-accordion-header:first-child {
    border-top: none;
  }
}
// english version of this page link

// Responsive only
// @desktopBreakpoint - min-width: 1071px
// @desktopSmallBreakpoint - max-width: 1070px
// @responsiveBreakpoint - max-width: 877px
@media only screen and (max-width: 1070px) {
  // Breadcrumbs (new)
  #vrtx-breadcrumb-wrapper #vrtx-breadcrumb {
    padding-top: 0;
  }
}
// tablet and mobile
@media only screen and (max-width: 877px) {
  //@media @responsivebreakpoint {
  #vrtx-daily-events:not(.with-images) .vrtx-daily-events-listing,
  #vrtx-daily-events:not(.with-images) .vrtx-event-component-item,
  .vrtx-event-component:not(.with-images) .vrtx-daily-events-listing,
  .vrtx-event-component:not(.with-images) .vrtx-event-component-item,
  .vrtx-feed:not(.with-images) li:nth-child(n) {
    border-bottom: none;
  }

  .read-more,
  .read-more-small,
  .vrtx-more a {
    border-bottom: 0.1em solid transparent;
  }

  #center-menu-same-level-folders,
  #main {
    #vrtx-semester-links,
    .navigation-links ul,
    .vrtx-subfolder-menu ul,
    ul.navigation-links,
    ul.vrtx-breadcrumb-menu {
      li a {
        font-size: 2rem;
        span {
          font-size: 1.6rem;
        }
      }
    }
  }

  body #main #center-menu-same-level-folders {
    ul.vrtx-breadcrumb-menu {
      padding-left: 15px;
      padding-right: 15px;
      li a {
        span {
          font-size: 2rem;
        }
      }
    }
  }

  #main .vrtx-social-components {
    .vrtx-share-at-component,
    li {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  // Linklist
  /* may use this in other doctypes */
  body .main #vrtx-content #vrtx-main-content {
    #vrtx-hinn-studieprogram-content,
    * {
      .vrtx-hinn-linklist {
        li {
          width: 100%;
          float: none;
        }
      }
    }
    #vrtx-hinn-exchange-content {
      padding-bottom: 40px;
      .vrtx-hinn-linklist {
        grid-template-columns: 1fr;
      }
    }
  }
  // FACTS CONTAINER
  .not-for-ansatte #main .vrtx-facts-container {
    position: relative;
    // Midlertidig fix:
    max-height: none;
  }

  #vrtx-structured-article,
  #vrtx-structured-event {
    .vrtx-social-components {
      width: 100%;
    }
  }
}


@media only screen and (max-width: 877px) {
  .vrtx-contact-component {
    .contact {
      * {
        float: none;
      }
      dd {
        margin-bottom: 2px;
        &:not(:last-of-type):after {
          content: "";
        }
      }
    }
  }
}