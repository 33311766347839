//center-grid
#main {
  max-width: 1090px;
  min-width: 1090px;
  padding-left: 20px;
  padding-right: 20px;
  #total-main,
  #right-main {
    padding-left: 0px;
    max-width: 1050px;
    min-width: 1050px;
    margin: 0 auto;
  }
}

/* new test 11.05 */
#vrtx-frontpage #main,
#vrtx-frontpage #main #right-main,
#vrtx-hinn-programme-presentation #main,
#vrtx-hinn-programme-presentation #total-main,
#vrtx-hinn-programme-presentation #right-main,
#vrtx-hinn-programme-presentation #vrtx-hinn-studieprogram-content,
#vrtx-hinn-exchange-destination #main,
#vrtx-hinn-exchange-destination #total-main,
#vrtx-hinn-exchange-destination #right-main,
#vrtx-hinn-exchange-destination #vrtx-hinn-exchange-content {
  max-width: 100%;
  min-width: 100%;
  padding: 0;
}

.info-link-wrapper .info-link,
.uio-app-line-top,
#head-wrapper #head,
.grid-container.uio-info-message.alert .row,
#footer-wrapper #footers,
#footer-wrapper div.footer-social-links,
#marketing-container.grid-container .row {
  max-width: 1050px;
  min-width: 1050px;
  margin: 0 auto;
  padding: 0 15px;
}
h1,
#vrtx-frontpage #main h1,
#vrtx-frontpage.total-main #main h1,
#vrtx-frontpage #main #breadcrumbs,
#breadcrumbs,
#vrtx-frontpage.total-main #main #breadcrumbs,
#vrtx-frontpage #main .full-width-picture-bg.feature-full-width-grid-container,
.vrtx-person-search-hits {
  max-width: 1050px;
  min-width: 1050px;
  margin-left: auto;
  margin-right: auto;
}

.vrtx-introduction,
.vrtx-hinn-facts-horizontal,
#center-menu-same-level-folders ul.vrtx-breadcrumb-menu,
#center-menu-same-level-folders,
#vrtx-frontpage #main .row,
#vrtx-frontpage.total-main #main .row,
#vrtx-frontpage.total-main #main #vrtx-frontpage-introduction,
body.blog #main #vrtx-content,
body.blog #main #vrtx-content ~ .grid-container .row,
#vrtx-hinn-programme-presentation .vrtx-field-marketing h2,
#vrtx-hinn-programme-presentation .vrtx-field-marketing .vrtx-feed,
#vrtx-hinn-programme-presentation .vrtx-field-contact,
#vrtx-hinn-programme-presentation .vrtx-hinn-layout-horisontal,
#vrtx-hinn-exchange-destination .vrtx-field-stories h2,
#vrtx-hinn-exchange-destination .vrtx-field-stories .vrtx-feed,
#vrtx-hinn-exchange-destination .vrtx-field-contact,
#vrtx-hinn-exchange-destination .vrtx-hinn-layout-horisontal,
#vrtx-hinn-exchange-destination .vrtx-field-programmes,
#filters-form {
  max-width: 1050px;
  min-width: 1050px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#vrtx-frontpage.total-main #main h1,
#vrtx-frontpage.total-main #main .row,
#vrtx-frontpage.total-main #main #breadcrumbs,
#vrtx-frontpage.total-main #main #vrtx-frontpage-introduction,
body.blog #main #vrtx-content,
body.blog #main #vrtx-content ~ .grid-container .row,
body.blog #main #vrtx-content .blog-recent-posts .row {
  padding-left: 0;
  padding-right: 0;
}

h1,
.vrtx-introduction,
.vrtx-introduction-image,
#vrtx-frontpage.total-main #main h1 {
  margin-left: auto;
  margin-right: auto;
}

body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
  #vrtx-main-content
  > blockquote.blockquote-small,
body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
  #vrtx-main-user
  > blockquote.blockquote-small,
body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
  #vrtx-main-content
  > *:not(h1):not(#responsive-submenu):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title),
body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
  #vrtx-main-user
  > *:not(h1):not(#responsive-submenu):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
  margin-right: auto;
  position: relative;
  margin-left: auto;
}

@media only screen and (min-width: 1180px) {
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    #vrtx-main-content
    > blockquote.blockquote-small,
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    #vrtx-main-user
    > blockquote.blockquote-small,
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    #vrtx-main-content
    > *:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title),
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    #vrtx-main-user
    > *:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
    margin-left: auto;
  }

  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor)
    #vrtx-main-user
    > *:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
    margin-left: auto;
    margin-right: auto;
  }
}

html:not(.gallery-old) .main {
  .thumbs-wrapper {
    max-width: 1340px;
    min-width: 1140px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 10px auto 40px auto;
  }
  .thumbs-prev-row {
    width: 60px;
  }
  .thumbs-next-row {
    width: 60px;
  }
}

/* override UIO*/

#vrtx-unitsearch #vrtx-calendar-listing #vrtx-main-content,
#vrtx-searchview #vrtx-calendar-listing #vrtx-main-content,
#vrtx-personsearch #vrtx-calendar-listing #vrtx-main-content,
#vrtx-personal-data #vrtx-calendar-listing #vrtx-main-content,
#vrtx-helseforsk #vrtx-calendar-listing #vrtx-main-content,
#vrtx-event-listing #vrtx-calendar-listing #vrtx-main-content,
#vrtx-article-listing #vrtx-calendar-listing #vrtx-main-content,
#vrtx-structured-project #vrtx-calendar-listing #vrtx-main-content,
#vrtx-organizational-unit #vrtx-calendar-listing #vrtx-main-content,
#vrtx-person #vrtx-calendar-listing #vrtx-main-content,
#vrtx-unitsearch #vrtx-content #vrtx-main-content,
#vrtx-searchview #vrtx-content #vrtx-main-content,
#vrtx-personsearch #vrtx-content #vrtx-main-content,
#vrtx-personal-data #vrtx-content #vrtx-main-content,
#vrtx-helseforsk #vrtx-content #vrtx-main-content,
#vrtx-event-listing #vrtx-content #vrtx-main-content,
#vrtx-article-listing #vrtx-content #vrtx-main-content,
#vrtx-structured-project #vrtx-content #vrtx-main-content,
#vrtx-organizational-unit #vrtx-content #vrtx-main-content,
#vrtx-person #vrtx-content #vrtx-main-content {
  width: 100%;
  padding-right: 0;
  float: none;
}
// Responsive only
// @desktopBreakpoint - min-width: 1071px;
//@desktopSmallBreakpoint:  ~"max-width:  1080px";
// @responsiveBreakpoint - max-width: 877px;
// @desktopSmallBreakpoint - max-width: 1070px;

@media @desktopSmallBreakpoint {
  #head-wrapper #head,
  #vrtx-frontpage.total-main #main .row,
  #main,
  #main #total-main,
  #main #right-main,
  div.vrtx-title,
  .vrtx-hinn-facts-horizontal,
  h1,
  .vrtx-introduction,
  #center-menu-same-level-folders,
  #center-menu-same-level-folders ul.vrtx-breadcrumb-menu,
  #vrtx-frontpage #main h1,
  #vrtx-frontpage #main .row,
  #vrtx-frontpage.total-main #main h1,
  #vrtx-frontpage #main #breadcrumbs,
  #breadcrumbs,
  #vrtx-frontpage.total-main #main #breadcrumbs,
  #vrtx-frontpage.total-main #main .row,
  #vrtx-frontpage.total-main #main #vrtx-frontpage-introduction,
  .blog #main #vrtx-content,
  .blog #main #vrtx-content ~ .grid-container .row,
  .info-link-wrapper .info-link,
  .uio-app-line-top,
  #head-wrapper #head,
  .grid-container.uio-info-message.alert .row,
  #footer-wrapper #footers,
  #footer-wrapper div.footer-social-links,
  #vrtx-frontpage
    #main
    .full-width-picture-bg.feature-full-width-grid-container,
  .vrtx-introduction,
  #vrtx-frontpage #main .row,
  #vrtx-frontpage.total-main #main .row,
  body.blog #main #vrtx-content,
  body.blog #main #vrtx-content ~ .grid-container .row,
  #vrtx-hinn-programme-presentation .vrtx-field-marketing h2,
  #vrtx-hinn-programme-presentation .vrtx-field-marketing .vrtx-feed,
  #vrtx-hinn-programme-presentation .vrtx-field-contact,
  #vrtx-hinn-programme-presentation .vrtx-hinn-layout-horisontal,
  #vrtx-hinn-exchange-destination .vrtx-field-stories h2,
  #vrtx-hinn-exchange-destination .vrtx-field-stories .vrtx-feed,
  #vrtx-hinn-exchange-destination .vrtx-field-contact,
  #vrtx-hinn-exchange-destination .vrtx-hinn-layout-horisontal,
  #vrtx-hinn-exchange-destination .vrtx-field-programmes,
  #filters-form,
  #marketing-container.grid-container .row,
  .vrtx-person-search-hits {
    max-width: 100%;
    min-width: auto;
  }

  #main,
  #vrtx-frontpage #main .vrtx-back,
  #vrtx-frontpage #main h1,
  #vrtx-hinn-programme-presentation #breadcrumbs,
  #vrtx-hinn-programme-presentation h1,
  #vrtx-hinn-programme-presentation .vrtx-introduction,
  #vrtx-hinn-programme-presentation.vrtx-hinn-studieprogram
    #main
    .vrtx-field-marketing,
  #vrtx-hinn-programme-presentation .vrtx-hinn-layout-horisontal,
  #vrtx-hinn-exchange-destination .vrtx-field-programmes,
  #vrtx-hinn-exchange-destination #breadcrumbs,
  #vrtx-hinn-exchange-destination h1,
  #vrtx-hinn-exchange-destination .vrtx-introduction,
  #vrtx-hinn-exchange-destination #main .vrtx-field-marketing,
  #vrtx-hinn-exchange-destination .vrtx-hinn-layout-horisontal,
  #vrtx-hinn-facts-horizontal,
  #vrtx-frontpage #main #vrtx-frontpage-introduction,
  #vrtx-frontpage.total-main #main h1,
  #vrtx-frontpage.total-main #main #breadcrumbs,
  #vrtx-frontpage.total-main #main #vrtx-frontpage-introduction,
  body.blog #main #vrtx-content,
  body.blog #main #vrtx-content ~ .grid-container .row,
  body.blog #main #vrtx-content .blog-recent-posts .row,
  #vrtx-frontpage.total-main #main #breadcrumbs,
  .vrtx-frontpage-box.navigation-links,
  #marketing-customize-container,
  html.special-page-config #vrtx-frontpage-introduction .vrtx-introduction,
  html.special-page-config-header-minimal
    #vrtx-frontpage-introduction
    .vrtx-introduction,
  #vrtx-frontpage #main #breadcrumbs {
    padding-left: 15px;
    padding-right: 15px;
  }

  #vrtx-frontpage.total-main #main .row {
    padding-left: 0px;
  }
  #vrtx-frontpage.total-main #main .grid-container {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}
@media @responsiveBreakpoint {
  #vrtx-frontpage #main {
    .vrtx-frontpage-box,
    .grid-container,
    .grid-container:last-child
      .vrtx-frontpage-box:last-child:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
      margin-bottom: 40px;
    }
    .vrtx-frontpage-box {
      &.grey-box:not(.responsive-hide) + .grey-box,
      &.grey-clip-box:not(.responsive-hide) + .grey-clip-box,
      &.grey-box-light:not(.responsive-hide) + .grey-box-light,
      &.red:not(.responsive-hide) + .red {
        margin-top: -40px;
      }
    }
  }
}
