/* header.less */
body {
  #head-wrapper {
    padding: 15px 0;
    min-height: auto;
    #head {
      display: block;
      padding: 0;
      #header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.expanded-search {
          #header-tools {
            button.toggle-search:not(.header-search-below) {
              display: none;
            }
            #header-search-form.search-form {
              display: block;
            }
          }
        }
        a.logo {
          background: var(--logo-inn) no-repeat;
          background-size: contain;
          width: 300px;
          height: 45px;
          margin-left: -5px;
          img {
            display: none;
          }
        }
        #header-tools {
          display: grid;
          grid-template-columns: auto auto auto;
          row-gap: 20px;
          column-gap: 10px;
          align-items: center;
          #header-language {
            @media only screen and (max-width: 650px) {
             column-gap: 0;
             margin-top: 0;
            }
            position: relative;
            display: flex;
            column-gap: 5px;
            margin-left: auto;
            margin-top: 6px;
            span, a {
              padding: 0px 6px 1px 6px;
              text-transform: uppercase;
              font-size: 1.5rem;
              color: var(--green5);
            }
            span {
              border: 2px solid var(--green5);
            }
            a {
              margin-top: 1px;
              text-decoration: none;
              &:hover, &:focus {
                .textUnderlineHover(var(--green5));
              }
              &:focus, &:focus-visible {
                outline: -webkit-focus-ring-color auto 1px;
              }
            }
    
            #header-language-tooltip {
              align-self: start;
              position: absolute;
              z-index: 1;
              right: 0;
              margin-top: 40px;
              background-color: var(--color-dark);
              padding: 30px;
              width: 450px;
              max-width: 80vw;
              * {
                color: white;
              }
              a:hover, a:focus {
                color: white;
                .textUnderlineHover(white);
              }
              &:not(.visible) {
                display: none;
              }
              #header-language-tooltip-close {
                position: absolute;
                right: 0;
                top: 0;
                background: var(img-close) no-repeat center;
                background-size: cover;
                margin: 5px;
                width: 30px;
                height: 30px;
                &:hover, &:focus {
                  border: 1px solid white;
                }
              }
            }
          }
          button.toggle-search {
            grid-column-start: 2;
            height: 3.75em;
            height: 56px;
            width: fit-content;
            display: flex;
            align-items: center;
            background: var(--icon-search-green) no-repeat 98% 60%;
            background-size: 26px;
            padding: 16px 30px 15px 20px;
            font-size: 1.5rem;
            color: var(--green5);
            line-height: 1.5;
            .transition(0.2s, opacity, ease-in-out);
            cursor: pointer;
            margin: 0;
            &:hover, &:focus {
              .textUnderlineHover(var(--green5));
            }
            &:focus, &:focus-visible {
              outline: -webkit-focus-ring-color auto 1px;
            }
          }
          #header-search-form.search-form {
            display: none;
            grid-column-start: 2;
            grid-row-start: 1;
            max-width: none;
            min-width: 0;
            width: auto;
            margin: 0;
            form {
              position: relative;
              border-bottom: 2px solid;
              width: 295px;
              input {
                font-size: 1.5rem;
                color: var(--publishedDateGrey);
                padding: 13px 50px 10px 10px; // right space for button
                width: 100%;
                background: transparent;
                border: none;
                &::placeholder {
                  color: var(--textColor);
                  opacity: 1; /* Firefox */
                }
              }
              input[type="text"] {
                &:focus {
                  outline: -webkit-focus-ring-color auto 1px;
                }
                &:focus-visible {
                  outline: none;
                }
              }
              button[type="submit"] {
                position: absolute;
              }
            }
          }
          .sidebar-menu-toggle {
            grid-column-start: 3;
            width: 6em;
            height: 3.75em;
            height: 56px;
            font-size: 1.5rem;
            color: var(--green5);
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: transparent;
            padding: 0;
            &:before {
              display: none;
            }
            &:after {
              content: "";
              background: var(--icon-menu-green) no-repeat center left;
              background-size: 100% auto;
              height: 18px;
              width: 30px;
              display: inline-block;
            }
            &:hover,
            &:focus {
              // color: var(--textColor);
              .textUnderlineHover(var(--green5));
              span {
                text-decoration-color: transparent;
              }
            }
            &:focus {
              outline: -webkit-focus-ring-color auto 1px;
            }
            span {
              margin-right: 10px;
              letter-spacing: 0;
            }
          }

          .language-switch-link {
            display: none;
          }

          #left-menu-same-level-folders {
            width: auto;
            position: absolute;
            left: 0;
            top: 100px;
            padding: 0 0 0 40px;
            display: none;
          }
        }
      }
    }
  }
}
body.en #head-wrapper #head #header a.logo {
  background-image: var(--logo-inn-en);
}

// Wider search-button in english
html body.en {
  #head-wrapper #head #header #header-tools button.toggle-search {
    width: 100px;
  }
}

// special rules for open menu
html body.sidebar-menu-wrapper-visible {
  #head-wrapper {
    #head {
      #header {
        #header-tools {
          flex-wrap: nowrap;
          .sidebar-menu-toggle:after {
            content: "";
            background: var(--icon-menu-green-close) no-repeat center left;
            background-size: 100%;
            height: 22px;
            width: 30px;
          }
        }
      }
    }
  }
}
// ^ @desktopBreakpoint - min-width: 1071px ^

// @desktopSmallBreakpoint - max-width: 1070px
@media @desktopSmallBreakpoint {
  html body {
    #head-wrapper #head #header {
      display: grid;
      grid-template-columns: auto auto;
      padding: 0 15px;
      #header-tools {
        .sidebar-menu-toggle span {
          top: 0;
        }
      }

      a#sidebar-toggle-link {
        span {
          top: 0;
        }
        &::before {
          background-size: 100%;
        }
      }
    }
    &.sidebar-menu-wrapper-visible  {
      #header-tools a#sidebar-toggle-link::before {
        background-size: 70%;
      }
    }
  }
  body:not(.sidebar-menu-wrapper-visible) #vrtx-change-language-link {
    display: none;
  }
}

// @responsiveBreakpoint - max-width: 877px
@media only screen and (max-width: 650px) {
  html body #head-wrapper {
    padding: 0 22px;
    #head {
      padding: 0;
      #header {
        height: 86px;
        a.logo {
          @media (max-width: 500px) {
            width: 85px;
            background-size: cover;
          }
          img {
            min-width: 85px;;
            &.logo-desktop,
            &.logo-responsive-light,
            &.logo-responsive {
              display: none;
            }
          }
        }
      }

      #header.expanded-search {
        button.toggle-search {
          min-width: 0;
          border: none;
          button[type="submit"] {
            background-size: 40%;
            background-position: center center;
          }
        }
      }

      #header #header-tools {
        column-gap: 0;
        row-gap: 0;
        #header-search-form.search-form {
          grid-column-start: 1;
          form {
            max-width: 100%;
            width: 100%;
            border-bottom: 1px solid var(--color-dark);
            background: var(--color-light);
            #search-string-responsive {
              border-bottom: none;
              width: 100%;
            }
          }
        }

        button.toggle-search.header-search-expand {
          background-size: 25px;
          background-position: center;
        }
        button.toggle-search {
          font-size: 0;
          background: var(--icon-search-green) no-repeat center center;
        }
        #header-search-form.search-form {
          position: absolute;
          width: 100%;
          left: 0;
          top: 100px;
          padding: 0 10px;
        }

        a#sidebar-toggle-link {
          span {
            display: none;
          }
        }
        .sidebar-menu-toggle {
          width: 30px;
        }
      }
    }
  }
}
