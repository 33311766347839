// Override UiO css-variables

.sidebar-search.search-form,
body:not(.vrtx-editor-frontpage) .vrtx-frontpage-box.feature-full-width {
  opacity: 0;
}

// body:not(.vrtx-editor-frontpage) .vrtx-frontpage-box-picture.header-inn {
//   opacity: 0;
//   &.large-links {
//     opacity: 0;
//   }
//   div.mobile-image {
//     opacity: 0;
//   }
// }
// body:not(.vrtx-editor-frontpage) .grid-container.full-width-picture-bg {
//   .vrtx-frontpage-box-picture.header-inn {
//     opacity: 1;
//   }
// }

body:not(.vrtx-editor-frontpage) .feature-full-width-grid-container.feature {
  .feature-full-width {
    opacity: 1;
  }
}

#header-tools {
  .sidebar-search.search-form {
    opacity: 1;
  }
  #left-main {
    opacity: 1;
    #sidebar-toggle-link {
      opacity: 1;
      &::before {
        opacity: 1;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 1070px) {
  #header-tools #left-main {
    display: block;
  }
}

@media only screen and (max-width: 877px) {
  // Override UiO css-variables
  #left-main {
    display: none;

    #sidebar-toggle-link {
      opacity: 0;
      display: none;
      &:before  {
        display: none;
      }
    }
  }

  #header-tools #left-main {
    display: block;
    #sidebar-toggle-link {
      display: inline-block;
      opacity: 1;
      &:before {
        display: inline-block;
      }
    }
  }
}
