/* main-menu.less */
#main #left-main {
  display: none;
}
body.sidebar-menu-wrapper-visible {
  #head-wrapper,
  .sidebar-menu-wrapper {
    background: var(--tilesColor);
  }
  #sidebar-menu .sidebar-search.search-form {
    display: none;
  }
  #main #left-main {
    display: none;
  }
}
body.not-for-ansatte {
  .sidebar-menu-wrapper {
    .sidebar-menu-inner-wrapper {
      padding: 55px 0;
    }
    ul.vrtx-tab-menu {
      padding-left: 100px;
      li:before {
        content: "";
      }
      a {
        &:hover,
        &:focus {
          color: var(--textColor);
          .textUnderlineHover(var(--textColor));
        }
      }
      a:focus-visible {
        outline: none;
      }
    }
  }
}

.sidebar-menu-wrapper {
  .sidebar-global-menu {
    max-width: 1090px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    .sidebar-services-language-menu {
      display: block;
      position: relative;
      left: 0px;
      top: 0px;
      border-top: 0;
      li {
        &:before {
          content: "";
        }
        a {
          font-family: var(--mainFontStack);
          line-height: 1.5;
          display: block;
          font-size: 1.6rem;
          margin-bottom: 15px;
          &:hover,
          &:focus {
            color: var(--textColor);
            .textUnderlineHover(var(--textColor));
          }
          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }
}

@media @desktopSmallBreakpoint {
  body.not-for-ansatte {
    .sidebar-menu-wrapper {
      .sidebar-menu-inner-wrapper {
        padding: 63px 25px 0 25px;
        .sidebar-global-menu {
          display: flex;
          flex-direction: column;
          .vrtx-tab-menu {
            padding-left: 0;
          }
          .sidebar-services-language-menu {
            display: none;
          }
        }
        .sidebar-services-language-menu {
          margin-top: 0;
          display: flex;
          flex-direction: column;
          li {
            margin-bottom: 0;
            &:before {
              content: "";
            }
            &.language {
              display: block;
            }
          }
        }
      }
    }
  }

  body.sidebar-menu-wrapper-visible {
    #main #left-main {
      display: none;
    }
  }
}

// @media @responsiveBreakpoint {
// }
