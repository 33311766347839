#vrtx-frontpage #footer-wrapper, #footer-wrapper {
  background: linear-gradient(to right, var(--colorFooterBg) 0%, var(--colorFooterBg) 50%, var(--tilesColor) 50%, var(--tilesColor) 100%);
  @media only screen and (max-width: 650px) {
    padding: 0;
  }
  #footers {
    background: var(--img-footer-bg) no-repeat;
    width: 100%;
    max-width: 100%;
    border-top: 1px solid var(--colorFooterBorder);
    margin: 0;
    padding: 0;
    * {
      color: var(--color-dark);
    }
    
   .menu-label {
      text-transform: none;
      font-family: var(--mediumFontStack);
      font-weight: 600;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: 0px;
    }
    ul.only-links {
      margin-top: 5px;
      margin-bottom: 20px;
      li {
        margin-bottom: 0;
        margin-left: 0;
        width: fit-content;
        &::before {
          content: "";
          position: relative;
          margin: 0;
          
        }
        a {
          text-decoration: none;
          &:before {
            content: "";
            background: var(--img-arrow-big-green) no-repeat 0 0;
            background-size: auto 0.6em;
            height: 1em;
            width: 1em;
            position: absolute;
            left: 0px;
            top: 0.42em;
            -webkit-transition: 0.2s left ease-in-out;
            -moz-transition: 0.2s left ease-in-out;
            -o-transition: 0.2s left ease-in-out;
            transition: 0.2s left ease-in-out;
          }
          &:hover,
          &:focus {
            text-decoration: underline;
            color: var(--linkColor);
            &:before {
              left: 0.2em;
              width: 100%;
            }
          }
        }
      }
    }
    .col-3-5 {
      width: 100%;
    }
    .footer-content, .col-3-5 {
      .study-locations-wrapper {
        width: 100%;
        .study-locations {
          display: grid;
          grid-template-columns: auto auto auto;
          column-gap: 30px;
          max-width: 1050px;
          width: 100%;
          margin: 0 auto;
          .column {
            padding-left: 15px;
            padding-top: 80px;
            &:has(.map) {
              padding-top: 0;
              padding-left: 0;
              display: flex;
            }
            .map {
              display: flex;
              overflow: visible;
              img {
                width: 100%;
                height: 100%;
                max-width: 307px;         
                object-fit: contain;
                object-position: right top; 
                overflow: visible;
                padding-bottom: 20px;
              }
            }
            .study-place-links {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-top: 80px;
              @media only screen and (max-width: 877px) {
                padding-top: 40px;
              }
            }
            .map {
              @media only screen and (max-width: 650px) {
                max-width: 50%;
                overflow: hidden;
                img {
                  object-fit: cover;
                  overflow: hidden;
                }
              }
            }
          }
          
          @media only screen and (max-width: 877px) {
            grid-template-columns: 1fr;
            column-gap: 0;
            .column {
              padding: 40px 0 20px;
              &.contact, &.other-links {
                width: 100%;
                background-color: white;
                padding-left: 15px;
              }
              &.contact {
                padding-top: 40px;
              }
              &.other-links {
                padding-bottom: 30px;
                padding-top: 0px;
              }
            }
          }
        }
      }
      .footer-social-links-wrapper {
        background-color: white;
        max-width: 100%;
        padding: 40px 60px 40px 40px;
        @media only screen and (max-width: 877px) {
          background: var(--tilesColor);
          padding: 40px 0 20px;
        }
        .footer-social-links {
          max-width: 1050px;
          margin-left: auto;
          margin-right: auto;
          ul.only-links {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 75%;
            column-gap: 20px;
            @media only screen and (max-width: 877px) {
              grid-template-columns: 1fr;
            }
          }
        }
      }
    }
    .responsible-login {
      position: relative;
      max-width: 1050px;
      width: 100%;
      margin: 0 auto;
      bottom: 20px;
      right: 0;
      background-color: white;
      color: var(--color-dark);
      @media only screen and (max-width: 877px) {
        margin: 0 20px;
        bottom: 0;
        right: 20px;
      }
      .content {
        position: absolute;
        right: 0;
        top: -142px;
        background-color: white;
        padding-top: 10px;
        @media only screen and (max-width: 877px) {
          position: relative;
          top: 0;
          right: unset;
          padding: 40px 20px 40px;
        }
        p {
          margin: 0 0 20px;
        }
      }
      .vrtx-login-manage-component.vrtx-dropdown-component.vrtx-dropdown-component-toggled {
        display: block;
        background-size: 22px auto;
        float: none;
        margin-top: 0;
      }
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background-image: url(/vrtx/dist/resources/uio2/css/images/footer/dropdown-black.png);
      &:focus, &:hover {
        background-image: url(/vrtx/dist/resources/uio2/css/images/footer/dropdown-black-hover.png);
      }
    }
  }
}