.lightbox {
  background: #f0f1f4;
  border: 1px solid #000;
  .backgroundClip();
  max-width: 690px;

  .header {
    margin-bottom: 20px;

    .title {
      display: block;
      float: left;
      font-weight: normal;
      font-size: 2.8rem;
      line-height: 3.6rem;
      letter-spacing: 0.4px;
      margin: 0;
    }
    &:after {
      .clearfix();
    }
  }

  .lightbox-content {
    padding: 70px 60px;

    &:after {
      .clearfix();
    }
  }

  textarea { /* Feedback */
    margin: 0;
    width: 100%;
  }

  p {
    margin-bottom: 20px;
  }

  #submitButtons,
  .submitButtons {
    margin-top: 40px;

    .vrtx-focus-button,
    .button {
      display: inline-block;
      margin-right: 20px;

      &:after {
        display: none;
      }
    }
    .vrtx-focus-button input[type="submit"],
    .button {
      padding: 10px 40px;
      width: auto;
      display: inline-block;
    }

    .vrtx-focus-button input[type="submit"] {
      background-color: #3e31d6;
      color: #fff;

      &:hover,
      &:focus {
        background-color: #3e31d6;
        opacity: 0.5;
      }
    }
    .button {
      background-color: transparent;
      border-bottom: 1px solid #3e31d6;

      &:hover,
      &:focus {
        background-color: transparent;
        opacity: 0.5;
      }
    }
  }
}

/* jQuery UI dialogs styled as Lightbox Me */

.ui-dialog {
  background: #fff;
  padding: 25px 25px 20px 25px;
  border: 4px solid rgba(0, 0, 0, 0.5);
  z-index: 1002;

  .vrtx-button,
  .vrtx-button-larger {
    margin: 10px 7px 10px 0;
    width: auto;
  }
}

.ui-widget-overlay {
  background: #000;
  opacity: 0.4;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.ui-dialog-titlebar {
  display: none;
}

#vrtx-frontpage .vrtx-dialog-content h2,
.vrtx-dialog-content h2 {
  font-size: 3rem;
  line-height: 3.7rem;
  margin-bottom: 20px;
}

.vrtx-dialog-content {
  padding-bottom: 20px;

  >*:first-child {
    margin-top: 0;
  }
  .vrtx-button-larger + .vrtx-button,
  .vrtx-button + .vrtx-button,
  .button + .button {
    margin-left: 20px;
  }

  .button, .button-large, .button-large-blue, .ui-dialog.ui-widget button, .vrtx-button-larger:not(form), .vrtx-button:not(form), button, input[type=button], input[type=reset], input[type=submit] {
    font-size: 1.8rem;
    line-height: 2.7rem;
    padding: 10px 20px 8px;
  }
  .button, .ui-dialog.ui-widget button, .vrtx-button:not(form), button, input[type=button], input[type=reset], input[type=submit] {
    padding-top: 9px;
    padding-bottom: 7px;
  }
}

/* Survey */

.ui-dialog .vrtx-dialog-content .uio-app-name {
  margin-bottom: 20px;

  a.uio-faculty,
  a.uio-host {
    display: none;
  }
}

.ask-again-later-wrapper {
  margin: 10px -25px 15px -25px;
  padding: 10px 25px;
  background-color: #F4F6FF;

  .vrtx-button {
    margin-left: 10px;
  }
}

.survey-minimized {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: @UiORed;
  color: #fff;
  padding: 8px 20px 11px 20px;
  z-index: 9;

  a {
    color: #fff;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  >*:first-child { margin-top: 0;    }
  >*:last-child  { margin-bottom: 0; }
}

.for-ansatte {
  .survey-minimized {
    background-color: #000;
  }
}

// Responsive

@media @responsiveBreakpoint {

  body.dialog-responsive {
    overflow: hidden;
  }

  .ui-dialog {
    width: 100% !important;
    top: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    position: fixed !important;
    border: none;
    padding: 15px;
    overflow: hidden;
    overflow-y: auto;

    .vrtx-dialog-content {
      .vtrx-button-larger + .vrtx-button {
        clear: left;
        margin-top: 10px;
      }
      .vrtx-button-larger,
      .vrtx-button,
      .button {
        clear: both;
        float: left;
        margin-left: 0;
        margin-right: 0;
      }

      .ask-again-later-wrapper {
        margin: 10px -15px 20px;
        padding: 10px 15px;
        overflow: hidden;

        .vrtx-button {
          display: block;
          float: none;
          width: 150px;
        }
      }
    }
  }

  .survey-minimized {
    width: 100%;
    right: 0;
    bottom: 0;
  }
}
