@tileBackground: #eceff8;
@tileBackgroundAlternate: #fff;

#main {
  #vrtx-semester-links,
  .navigation-links ul,
  ul.navigation-links {
    overflow: hidden;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li {

      margin-left: 0;
      margin-bottom: 30px;
      a {
        line-height: 1.3;
        color: @textColor;
        background-color: @tileBackground;
        font-size: 23px;
        font-size: 2.3rem;
        display: block;
        padding: 25px 60px 25px 20px;
        height: 100%;
        text-decoration: none;
        position: relative;

        &:after {
          background: url("@{imagePath}arrows/arrow-big.svg")
            no-repeat;
          background-size: auto 100%;
          display: inline-block;
          .textUnderline(@textColorUnderline);
          content: " ";
          width: 26px;
          height: 16px;
          position: absolute;
          top: 31px;
          right: 20px;
        }
        &:hover,
        &:focus {
          background: #dbe0f1;
        }
      }
      &::before {
        content: " ";
        display: none;
      }
    }
  }
  .navigation-links:not(.navigation-links-three-columns) ul,
  ul.navigation-links:not(.navigation-links-three-columns) {
    li {
      flex: 0 0 50%;
      &:nth-child(2n+1) {
        padding-right: 10px;
      }
      &:nth-child(2n+2) {
        padding-left: 10px;
      }
    }
  }

  #vrtx-semester-links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    ul {
      flex: 0 0 50%;
      position: relative;

      &.left {
        padding-right: 10px;
      }
      &.right {
        padding-left: 10px;
      }

      &.left,
      &.right {
        flex: 0 0 50%;
      }
      &.thirds-left,
      &.thirds-middle,
      &.thirds-right {
        flex: 0 0 33.3333%;
      }
      &.fourths-left,
      &.fourths-middle,
      &.fourths-right {
        flex: 0 0 25%;
      }

      li {
        margin-bottom: 20px;
      }
    }
  }

/*
  .navigation-links {
    max-width: 100% !important;
    ul {
      display: grid;
      grid-template-columns: 50% 50%;
      li {
        background-color: @tileBackground;
        margin: 50px 20px 0 0;
        width: 95%;
      }
    }
  } */

  .grey-box {
    #vrtx-semester-links,
    .navigation-links {
      a {
        background-color: @tileBackgroundAlternate;
      }
    }
  }
}

#main {
  .navigation-links ul,
  ul.navigation-links {
    li {
      span {
        clear: left;
        display: block;
        font-style: normal;
        margin-top: 10px;
        .baseFontSize;
      }
    }
  }
}

@media @responsiveBreakpoint {

  #main .navigation-links ul,
  #main ul.navigation-links {
    display: grid;
    grid-template-columns: 100%;
    li {
      margin-bottom: 10px;
      a {
        height: auto !important;
      }
    }
  }
  #main #vrtx-semester-links {
    flex-direction: column;
    ul {
      li {
        margin-bottom: 10px;
        a {
          height: auto !important;
        }
      }
      &.left,
      &.right,
      &.thirds-left,
      &.thirds-middle,
      &.thirds-right,
      &.fourths-left,
      &.fourths-middle,
      &.fourths-right {
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}
