#footer-wrapper {
  background: #000;
  clear: both;
  position: relative;

  *,
  a {
    color: #fff;
    font-size: 1.7rem;
  }
  p {
    margin-top: 9px;
  }
  #footers {
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 60px;

    &:after {
      .clearfix
    }

    // New footer design
    .footer-content-wrapper {
      display: grid;
      grid-template-columns: minmax(300px,470px) auto minmax(140px, auto);
      grid-gap: 60px;

      h2.menu-label,
      h2 {
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: normal;
        margin-top: 0;
        margin-bottom: 5px;
      }

      .footer-title {
        a {
          display: inline-block;
          text-decoration: none;
          font-size: 3.3rem;
          line-height: 4rem;
          margin-right: 20px;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        .footer-uio-logo {
          height: 96px;
          width: auto;
        }
      }

      .footer-content {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 40px 60px;
      }

      p:last-child {
        margin-bottom: 0;
      }

      .col-3-5,
      .col-2-5,
      .col-1-3 {
        float: none;
        width: auto;
        margin-top: 0;
        .content {
          padding: 0;
        }
      }
    }
    // ^ NEW

    .col-3-5,
    .col-2-5,
    .col-1-3 {
      float: left;
    }

    .col-3-5 {
      width: 70%; // needed some more place for admin-link when 1050 desktop 3 * (100% / 4);
    }
    .col-1-3 {
      width: ~"calc(100% / 3)";
    }
    .col-2-5 {
      width: 30%; // needed some more place for admin-link when 1050 desktop (100% / 4);
      margin-top: -10px;
    }
    .col-1-3,
    .col-2-5 {
      .content {
        padding: 0 40px 0 0;
      }
    }
    .menu-label {
      //padding-top: 105px;
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: normal;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .vrtx-dropdown-component .vrtx-dropdown-wrapper ul li a {
      color: @textColor;
      .textUnderlineSwitch;
    }
    .vrtx-login-manage-component.vrtx-dropdown-component.vrtx-dropdown-component-toggled {
      //padding-left: 30px;
      //background: url("@{imagePath}arrows/arrow-small-white.svg") 5px 10px no-repeat;
      display: inline-block;
      background-size: 22px auto;
      float: left;
      margin-top: -20px;
    }

    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      display: inline-block;
      width: 21px;
      height: 22px;
      background: url('@{imagePath}footer/dropdown.png') no-repeat center right;
      background-size: 22px auto;
      margin: 0 0 -6px 6px;

      &:focus,
      &:hover {
        background-image: url('@{imagePath}footer/dropdown-hover.png');
      }
    }
  }
  &.faculty-institute-footer {
    #footers {
      background-image: none;
      padding-top: 50px;
    }
  }
  #footer-title {
    display: block;
    margin-top: 0;
    margin-bottom: 60px;

    a {
      display: inline-block;
      text-decoration: none;
      font-size: 3.3rem;
      line-height: 4rem;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

#footer-wrapper-back-to-uio {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #000;
  a {
    width: 320px;
    display: block;
    height: 60px;
    background: url('@{imagePath}footer/uio-logo-no.svg') 0 0 no-repeat;
    background-size: auto 100%;
  }
}
body.en #footer-wrapper-back-to-uio a {
  background-image: url('@{imagePath}footer/uio-logo-en.svg');
}

@media @desktopSmallOnlyBreakpoint {
  #footer-wrapper #footers .footer-content-wrapper {
    grid-template-columns: auto minmax(140px,auto);
    >:first-child { // Take full width (https://stackoverflow.com/questions/50447477/how-do-i-make-the-first-grid-item-span-100)
      grid-column: 1 / -1;
    }
  }
}

@media @responsiveBreakpoint {
  #footer-wrapper #footers .footer-content-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 40px 0;
  }
  #footer-wrapper #footers .footer-content-wrapper .footer-content {
    grid-template-columns: 1fr;
    grid-gap: 20px 0;

    h2.menu-label,
    h2 {
      margin-top: 0;
    }
  }

  #footer-wrapper #footers {
    padding: 50px 15px 90px 15px;
    min-width: 0;
    background-size: auto 86px;
    background-position: 15px 40px;

    .col-3-5,
    .col-2-5,
    .col-1-3 {
      float: none;
      width: 100%;
      margin: 0;

      .content {
        padding: 0;
      }
      .menu-label {
        margin-top: 40px;
        display: block;
      }
    }
    .vrtx-dropdown-component .vrtx-dropdown-wrapper {
      right: auto;
    }
    .vrtx-login-manage-component.vrtx-dropdown-component.vrtx-dropdown-component-toggled {
      margin-top: -20px;
    }
  }
  #footer-wrapper-back-to-uio {
    padding-top: 0;
  }
}
