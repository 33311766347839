body.blog {
  h1,
  .vrtx-introduction {
    min-width: 100%;
    width: 100%;
  }
  &#vrtx-searchview,
  &#vrtx-structured-article #vrtx-content,
  &#vrtx-blog-listing,
  #main .grid-container.full-width-picture-bg {
    .blog-title,
    .vrtx-box-content {
      border-bottom: none;
      a:not(.button):not(.vrtx-link-check):not(.vrtx-facts-container a) {
        display: block;
        text-align: center;
        font-family: var(--mediumFontStack);
        font-size: 5.5rem;
        font-style: normal;
        line-height: 62px;
        letter-spacing: 0em;
        margin-bottom: 60px;
        text-decoration-color: var(--textColor);
        color: var(--textColor);
        &:hover,
        &:focus {
          text-decoration-color: var(--linkColor);
          color: var(--linkColor);
        }
      }
    }
  }

  // Blog-grid:
  #main #vrtx-content,
  #main .grid-container.full-width-picture-bg,
  #main .grid-container.full-width-picture-bg .row,
  #total-main
    .grid-container.blog-about
    .vrtx-frontpage-box.vrtx-frontpage-box-picture
    .vrtx-box-content,
  &#vrtx-blog-listing #vrtx-content,
  #main #vrtx-content .blog-recent-posts .row {
    max-width: 930px;
    min-width: 930px;
    display: block;
  }

  #main {
    margin-bottom: 0;
    .author-image img {
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
    }
    .grid-container {
      margin-left: 0;
      &.full-width-picture-bg {
        top: 0;
        height: 120px;
        margin: 0 auto;
        .row {
          margin: 0 auto;
          padding-left: 20px;
        }
        .vrtx-frontpage-box .vrtx-frontpage-box-picture {
          height: 100px;
        }
        .vrtx-box-content {
          bottom: 0;
          width: 100%;
        }
      }
    }
    .vrtx-resources
      .vrtx-resource
      .vrtx-title
      ~ *:not(.author-published-byline) {
      font-family: "Graphik";
      font-size: 2rem;
      line-height: 1.5;
    }
    #total-main .grid-container.blog-about {
      padding: 0;
      background-color: var(--tilesColor);
      .row {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      .vrtx-frontpage-box.vrtx-frontpage-box-picture  {
        display: block;
        height: 705px;
        .vrtx-frontpage-box-picture {
          width: 100%;
          flex: none;
          margin-top: 0;
          height: 420px;
          margin-top: 285px;
          img {
            min-height: 100%;
            min-width: 100%;
          }
        }
        .vrtx-box-content {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          padding: 70px 0;

          * {
            color: var(--textColor);
            text-decoration-color: var(--textColor);
          }
        }
      }
    }
    .blog-recent-posts .row {
    }
  }
  &#vrtx-blog-listing {
    /* hode tag cloud */
    #vrtx-content {
      #vrtx-main-content {
        padding-right: 0;
      }
      #vrtx-additional-content {
        display: none;
      }
      .vrtx-resources .vrtx-resource a.vrtx-image {
        max-height: 475px;
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }
    }
  }
  &#vrtx-searchview {
    .vrtx-big-search,
    span.hits {
      display: none;
    }
    .footer span.url {
      display: none;
    }
  }
  &#vrtx-structured-article {
    h1 {
      margin-bottom: 100px;
    }
    #vrtx-content {
      .vrtx-social-components {
        overflow: visible;
        min-height: 150px;
        .vrtx-share-at-component {
          height: 58px;
        }
      }
    }
    .grid-container {
      &.blog-recent-posts {
        background: var(--alternateRowColor);
        padding: 70px 0;
        margin-bottom: 0;
      }
      &.blog-about {
        margin-top: 0;
      }
    }
  }

  /* author info tooltip */
  .tooltip.tooltip-author {
    width: 100%;
    margin-left: 0;
    left: 0 !important;
    p {
      white-space: pre-line;
      &.tooltip-meta {
        font-size: 16px;
        line-height: 1.5;
      }
    }
    a.tooltip-author-search {
      display: block;
      padding-top: 20px;
    }
  }
}

// Responsive only
@media @responsiveBreakpoint {
  body.blog {
    // fix blog-grid:
    #main #vrtx-content,
    #main .grid-container.full-width-picture-bg,
    #main .grid-container.full-width-picture-bg .row,
    #total-main
      .grid-container.blog-about
      .vrtx-frontpage-box.vrtx-frontpage-box-picture
      .vrtx-box-content,
    &#vrtx-blog-listing #vrtx-content {
      max-width: 100%;
      min-width: 0;
      .vrtx-resources .vrtx-resource {
        margin-bottom: 60px;
      }
    }
    #main {
      #total-main,
      #right-main {
        .grid-container.full-width-picture-bg .row {
          .vrtx-frontpage-box.full-width-picture {
            .vrtx-box-content {
              border-bottom: none;
              padding-left: 0;
              a {
                font-size: 40px;
                text-align: left;
                margin-bottom: 0;
              }
            }
          }
        }
      }

      #total-main .grid-container.blog-about {
        .vrtx-frontpage-box.vrtx-frontpage-box-picture  {
          .vrtx-frontpage-box-picture {
          }
          .vrtx-box-content {
            position: relative;
            min-width: 0;
            max-width: 100%;
          }
        }
      }
    }

    #main {
      padding-bottom: 0;
      #total-main {
        .grid-container.blog-about
          .vrtx-frontpage-box.vrtx-frontpage-box-picture {
          .vrtx-frontpage-box-picture {
            height: auto;
            display: none;
          }
          .vrtx-box-content {
            padding: 70px 20px;
          }
        }
      }
    }
  }
}
