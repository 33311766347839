// Override UiO css-variables
:root {
  --textColor: #262626;
  --textColorUnderline: rgba(0, 0, 0, 0.6); // weaker underline
  --linkColor: #105422;//#056731; 
  --linkColorUnderline: #105422;//rgba(5, 103, 49, 0.6);
  --borderColor: #262626;
  --tilesColor: #E9F5E7;//#e2ddd1;
  --tilesColorHover: darken(#E9F5E6, 5%); //#c8c3b9;
  --publishedDateGrey: #575757;
  --publicationBorder: #575757;
  --btnPrimaryBg: #eb7300;//#f49e43;
  --btnPrimaryBgHover: #f9c690;
  --btnPrimaryColor: #262626;
  --btnPrimaryColorHover: #757575;
  --btnSecondaryBg: #262626; // Rydde bort denne
  --btnSecondaryBgHover: #757575; // Rydde bort denne
  --btnSecondaryColor: #ffffff;
  --btnSecondaryColorHover: #ffffff;
  --checkedBgHover: #757575;
  --paginationTextColor:#757575;
  --infoBoxColorDark: #105422;// #004002;
  --infoBoxColorDarkHover: #003F01;// darken(#004002, 5%);
  --filter-box-border: #105422;
  --infoBoxDefaultColor: #cfdfc9;//#a5d39b;
  --image-wide-bg: #cfdfc9;
  --accordionBorderColor: #9b9b9b;
  --checkboxDisabled: #9b9b9b;
  --filterChoiceBorderBottom: #9b9b9b;
  --darkrowColor: #cfdfc9;//#a5d39b; 
  --alternateRowColor: #F3F3EA;// #f6f4f1;
  --feedBoxColor: #cfdfc9;// #a5d39b;
  --eventBoxColor: #cfdfc9;//#d1e28c;
  --eventBoxColorHover: #e5efbe;
  --programListingFilterHover: #ebf3ee;
  --codeBg: #eceff8;
  --listSuccessColor: #008000;
  --colorFooterBg: #cfdfc9;
  --colorFooterBorder: #709E7F;
  --color-crisis: #b90000;
  --color-info: #eb7300; //#f49e43; // New orange 2024: #eb7300
  // shell (used in typography)
  --shell-green: #2ec483;
  --shell-green-dark: #151515;
  --shell-text-color: #eee;

  // basic-colors:
  --green1: #cfdfc9;//#d1e28c; 
  --green1Hover: darken(#cfdfc9, 5%);//#c0d07f; 
  --green2: #cfdfc9;// #a5d39b; 
  --green2Hover: darken(#cfdfc9, 5%);//#93bd8a;
  --green5: #105422;// #056731;
  --green5Hover: #003F01;//darken(#056731, 5%);
  --green6: #105422;//#004002; 
  --green6Hover: #003F01;//darken(#004002, 5%);
  --orange: #eb7300; //#f49e43; 
  --orangeHover: lighten( #eb7300, 5%);//rgba(244, 158, 67, 0.8);
  --color-dark: #262626;
  --color-light: #ffffff;
  --color-outline: #0f4bf3;
  // fonts
  // --mainFontStack: "Graphik", Helvetica, Arial, sans-serif;
  // --secondaryFontStack: "Gza", Georgia, serif; // introduction, quote etc.
  // --tertiaryFontStack: "Gzasemibold", Georgia, serif; // special-quote
  // --mediumFontStack: "Graphikmedium", Helvetica, Arial, sans-serif;
  // --smallTxtFontStack: "Graphikcompact", Helvetica, Arial, sans-serif;
  // --smallTxtMediumFontStack: "Graphikcompact-medium", Helvetica, Arial, sans-serif;
  --mainFontStack: 'Open Sans', sans-serif;
  --secondaryFontStack: 'Open Sans', sans-serif; // introduction, quote etc.
  --tertiaryFontStack: 'Open Sans', sans-serif; // special-quote
  --mediumFontStack: 'Open Sans', sans-serif;
  --smallTxtFontStack: 'Open Sans', sans-serif;
  --smallTxtMediumFontStack: 'Open Sans', sans-serif;
  // logo
  --logo-inn: url("/vrtx/dist/resources/images/logos/inn-logo-horiz-green.svg");
  --logo-inn-white: url("/vrtx/dist/resources/images/logos/inn-logo-horiz-white.svg");
  --logo-inn-black: url("/vrtx/dist/resources/images/logos/inn-logo-horiz-black.svg");
  --logo-inn-vert-green: url("/vrtx/dist/resources/images/logos/inn-logo-vert-green.svg");
  --logo-inn-vert-white: url("/vrtx/dist/resources/images/logos/inn-logo-vert-white.svg");
  --logo-inn-vert-black: url("/vrtx/dist/resources/images/logos/inn-logo-vert-black.svg");
  // logo english
  --logo-inn-en: url("/vrtx/dist/resources/images/logos/inn-logo-en-horiz-green.svg");
  --logo-inn-en-white: url("/vrtx/dist/resources/images/logos/inn-logo-en-horiz-white.svg");
  --logo-inn-en-black: url("/vrtx/dist/resources/images/logos/inn-logo-en-horiz-black.svg");
  --logo-inn-en-vert-green: url("/vrtx/dist/resources/images/logos/inn-logo-en-vert-green.svg");
  --logo-inn-en-vert-white: url("/vrtx/dist/resources/images/logos/inn-logo-en-vert-white.svg");
  --logo-inn-en-vert-black: url("/vrtx/dist/resources/images/logos/inn-logo-en-vert-black.svg");
  // decor elements
  --img-footer-map: url("/vrtx/dist/resources/images/map-inn-white-dots.svg");
  --img-footer-bg: url("/vrtx/dist/resources/images/footer-bg.svg");
  --img-footer-bg-mobil: url("/vrtx/dist/resources/images/footer-bg-mobil.svg");
  // arrows
  --img-arrow-big: url("/vrtx/dist/resources/images/arrows/arrow-big.svg");
  --img-arrow-big2: url(/vrtx/dist/resources/images/arrow-big.svg);
  --img-arrow-big-white: url("/vrtx/dist/resources/images/arrows/arrow-big-white.svg");
  --img-arrow-b-white: url(/vrtx/dist/resources/images/arrows/arrow_b_white.svg); // This is a bit smaller than big white (neccessary?)
  --img-arrow-big-green: url("/vrtx/dist/resources/images/arrows/arrow-big-green.svg");
  --img-arrow-down: url(/vrtx/dist/resources/images/inn-arrow-down.svg);
  --img-arrow-down-white: url(/vrtx/dist/resources/images/inn-arrow-down-white.svg);
  // quote
  --img-quote-open: url( "/vrtx/dist/resources/images/quote-open-light.svg");
  --img-quote-close: url( "/vrtx/dist/resources/images/quote-close-light.svg");
  // icons
  --icon-menu: url(/vrtx/dist/resources/images/global-menu-button/sidebar-open.svg);
  --icon-menu-green: url(/vrtx/dist/resources/images/global-menu-button/icon-menu-green.svg);
  --icon-menu-white: url(/vrtx/dist/resources/images/inn-menu-icon-white.svg);
  --icon-menu2: url(/vrtx/dist/resources/images/global-menu-button/inn-menu-icon.svg);
  --icon-menu-close: url(/vrtx/dist/resources/images/global-menu-button/sidebar-close.svg);
  --icon-menu-green-close: url(/vrtx/dist/resources/images/global-menu-button/icon-menu-green-close.svg);
  --icon-menu-close-white: url("/vrtx/dist/resources/images/global-menu-button/sidebar-close-white.svg");
  --icon-close2: url(/vrtx/dist/resources/images/close-button.svg);
  --icon-close-white2: url(/vrtx/dist/resources/images/close-button-white.svg);
  --icon-close: url(../images/close.svg);
  --icon-search-green: url(/vrtx/dist/resources/images/global-menu-button/icon-search-green.svg);
  --icon-magnifyer: url(/vrtx/dist/resources/uio2/css/images/sidebar/magnifying-glass.svg);
  --icon-magnifyer2: url(/vrtx/dist/resources/images/magnifying-inn.svg);
  --icon-magnifyer-white: url("/vrtx/dist/resources/images/magnifying-inn-white.svg");
  --icon-dropdown: url(/vrtx/dist/resources/uio2/css/images/footer/dropdown.png);
  --icon-unchecked: url('/vrtx/dist/resources/images/form-elements/inn-unchecked.svg');
  --icon-checked: url('/vrtx/dist/resources/images/form-elements/inn-checked.svg');
  --icon-checked2: url(/vrtx/dist/resources/images/checked.svg);
  --icon-mixed: url('/vrtx/dist/resources/images/form-elements/inn-mixed.svg');
  --icon-external-link: url(../images/external-link.svg);
  --icon-mail: url("/vrtx/dist/resources/images/email.svg");
  --icon-fb: url("/vrtx/dist/resources/images/facebook.svg");
  --icon-instagram: url("/vrtx/dist/resources/images/instagram.svg");
  --icon-twitter: url("/vrtx/dist/resources/images/twitter-x.svg");
  // crisis info
  --img-crisis: url("../images/warning-triangle.svg");
  --img-alert: url("../images/alert.svg");
}
