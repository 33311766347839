//fix error with breadcrumb menu
#vrtx-person {
  .vrtx-breadcrumb-menu {
    display: none;
  }

  .vrtx-field-about,
  .vrtx-groups.vrtx-frontpage-box,
  .vrtx-projects.vrtx-frontpage-box,
  #vrtx-publications-wrapper,
  #vrtx-hinn-addresses {
    width: 690px;
    margin: 0 auto;
  }

}
#breadcrumbs .vrtx-breadcrumb-before-active {
  margin-bottom: 0;
}
#vrtx-hinn-person-change-language-link {
  width: 100%;
  display: block;
  text-align: right;
  margin-bottom: 55px;
  margin-top: -40px;
  a {
    font-size: 1.4rem;
    line-height: 1.5;
    .textUnderline(var(--textColor));
  }
  .offscreen-screenreader {
    display: inline;
    position: static;
    width: auto;
    height: auto;
  }
}

.vrtx-hinn-person-card {
  background: var(--tilesColor);
  padding: 50px 40px;
  display: flex;

  .photo {
    padding-right: 40px;

    img {
      width: 177px;
    }

    .pressPhoto {
      margin: 5px 0 15px;
    }
  }

  .info {
    flex: 1 1 auto;
    padding-left: 20px;

    h1 {
      margin-top: 0;
    }
    dl {
      margin-bottom: 20px;
      dt {
        margin-right: 15px;
        float: left;
      }
    }
    .vrtx-hinn-links {
      margin-top: 0;
      li {
        margin: 10px 40px 10px 0;
        &:last-of-type {
          margin-right: 0;
        }
        &:before {
          display: none;
        }
        float: left;
      }
    }
  }
}

#vrtx-person {
  .vrtx-frontpage-box > h2 {
    margin-top: 50px;
  }
  .vrtx-hinn-addresses-container {
    display: flex;
    div {
      width: 50%;
      &.vrtx-hinn-visiting-address {
        padding-right: 20px;
      }
    }
    a {
      margin: 10px 0;
      display: block;
    }
  }
  .vrtx-tabs#vrtx-publication-tabs > ul,
  .ui-tabs.ui-widget.ui-widget-content .ui-tabs-nav {
    li a {
      border: 1px solid var(--publishedDateGrey);
      background-color: var(--btnSecondaryColor);
      color: var(--textColor);
      padding: 11px 17px;
    }
    li[aria-selected='true'] a {
      background-color: var(--tilesColor);
      font-family: var(--mediumFontStack);
      font-weight: normal;
      border: none;
      padding: 18px 22px;
      margin-top: -6px;
      &:after {
        display: none;
      }
    }
  }
  .vrtx-tabs > div:not(.more-options-dropdown),
  .ui-tabs.ui-widget.ui-widget-content .ui-tabs-panel {
    padding: 30px 0 0 0;
  }

  #vrtx-publication-tabs {
    > div:not(.more-options-dropdown) li,
    .ui-tabs-panel li {
      background: var(--alternateRowColor);
      margin: 15px 0;
      padding: 25px 30px;
      border-bottom: 0;
      padding-left: 30px;
      text-indent: 0;
      .vrtx-parent-contributors,
      .vrtx-title.title-articlesAndBookChapters {
        font-family: var(--mediumFontStack);
        display: block;
      }
      .vrtx-contributors {
        font-family: var(--mediumFontStack);
      }
    }
    .vrtx-publication-summary a.vrtx-publication-summary {
      display: inline-block;
      margin-top: 10px;
      padding: 6px 11px;
      border: 1px solid var(--publicationBorder);
      text-decoration: none;
    }
  }

  button#toggleMorePublications {
    border: 1px solid var(--publicationBorder);
    background: white;
    color: black;
    width: auto;
    padding: 11px 17px;
    font-size: 1.8rem;
  }
}

// Responsive only
@media @responsiveBreakpoint {
  #vrtx-hinn-person-change-language-link {
    margin-bottom: 65px;
    margin-top: -55px;
  }
  #vrtx-hinn-person-change-language-link {
    a {
      max-width: 107px;
      top: 102px;
    }
  }

  #vrtx-person {
    .vrtx-field-about,
    .vrtx-groups.vrtx-frontpage-box,
    .vrtx-projects.vrtx-frontpage-box,
    #vrtx-publications-wrapper,
    #vrtx-hinn-addresses {
      width: 100%;
      margin: 0 auto;
    }
  }

  .vrtx-hinn-person-card {
    padding: 40px 25px;
    display: block;
    .photo {
      padding: 0;
      margin-bottom: 20px;
    }
    .info {
      flex: none;
      padding: 0;
      .vrtx-hinn-links {
        li {
          float: none;
        }
      }
      dl dt {
        margin-right: 10px;
      }
    }
  }

  #vrtx-person {
    .vrtx-hinn-addresses-container {
      display: block;
      div {
        width: 100%;
        &:first-of-type {
          padding-right: 0;
          margin-bottom: 20px;
        }
        &:last-of-type {
          padding-left: 0;
        }
      }
    }
  }
}
