#vrtx-organizational-unit-employees {
  ul,
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
  > ul {
    > li {
      margin-bottom: 30px;
      > span,
      > a {
        float: left;
        display: block;
        height: 168px;
        width: 126px;
        margin-right: 30px;
        margin-top: 6px;
      }
      .vrtx-organizational-unit-employees-info {
        padding-left: 156px;
      }
    }
    &:after {
      .clearfix();
    }
  }
  li {
    margin-left: 0;
    &:before {
      display: none;
    }
  }

  .vrtx-organizational-unit-employees-info {
    margin: 0;

    li {
      margin-bottom: 0;
      a {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
    }
  }

  .vrtx-more {
    font-size: 2.8rem;
    line-height: 3.6rem;
    letter-spacing: 0.4px;
  }

  &:after {
    .clearfix();
  }
}

.vrtx-unit-contactinfo,
#vrtx-organizational-unit .vcard {
  > * {
    margin-bottom: 30px;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .vrtx-address-line,
  .vrtx-value,
  .vrtx-label {
    display: block;
  }
  .vrtx-label {
    font-weight: bold;
  }
}

#vrtx-unit {
  .vrtx-unit-contactinfo {
    >* {
      float: left;
      width: ~"calc(100% / 3)";
    }
  }
}

// Org. start page at: https://www.uio.no/om/organisasjon/index.html

.map-icon a {
  font-size: 2.1rem;
  line-height: 2.9rem;
  background: url("/vrtx/decorating/resources/dist/images/magnifying-glass.png") no-repeat center left;
  margin-left: 20px;
  padding-left: 24px;
}

.uio-units {
  h3 {
    clear: left;
  }
}

.vrtx-organizational-unit-help-subtext {
  color: #555555;
  font-size: 16px;
  display: block;
}

// Responsive

@media @responsiveBreakpoint {

  #vrtx-unit {
    .vrtx-unit-contactinfo {
      >* {
        float: none;
        width: auto;
      }
    }
  }
}
