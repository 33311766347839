@import "../typography.less";

.not-for-ansatte #main .grid-container:not(.full-width-picture-bg) {
  &.row-all-colored:last-child {
    margin-bottom: 0;
  }
}

#vrtx-frontpage {
  #head-wrapper #head #header {
    @media only screen and (max-width: 650px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  #main {
    #vrtx-frontpage-introduction {
      max-width: 1050px;
      min-width: 1050px;
      margin-left: auto;
      margin-right: auto;
      .vrtx-introduction {
        margin: 0;
        max-width: 100%;
      }
    }
    
    .grid-container.image-wide {
      background-color: var(--image-wide-bg);
      .row {
        max-width: 1920px;
        .vrtx-frontpage-box-picture {
          img {
            width: 100%;
            max-width: 1920px;
            height: 60vh;
            max-height: 700px;
            min-height: 450px;
            margin: 0 auto;
            object-fit: cover;
          }
        }
        @media only screen and (max-width: 1070px) {
          .vrtx-frontpage-box:not(.img-special-left):not(.img-special-right):not(.full-width-picture):not(.navigation-links):not(.special-logo-video):not(.special-logo-image) {
            padding: 0;
            img {
              height: 40vh;
              min-height: 300px;
            }       
          }  
        }
      }
      .pos-top img {
        object-position: top;
      }
      .pos-bottom img {
        object-position: bottom;
      }
      .pos-left img {
        object-position: left;
      }
      .pos-right img {
        object-position: right;
      }
      .pos-top.pos-left img {
        object-position: top left;
      }
      .pos-top.pos-right img {
        object-position: top right;
      }
      .pos-bottom.pos-left img {
        object-position: bottom left;
      }
      .pos-bottom.pos-right img {
        object-position: bottom right;
      }
    }
    .row-all-colored.grid-container:has(.large-links) {
      background-color: var(--tilesColor);
      margin-top: -80px;
      .vrtx-frontpage-box.large-links {
        padding-top: 40px;
        .vrtx-box-content {
          background-color: var(--green6);
          padding-top: 7em;
          box-shadow: -75px 0 var(--green6Hover);
          position: absolute;
          top: -206px;
          left: 75px;
          ul {
            background-color: var(--green6);
            margin-left: -75px;
            padding: 30px 70px 5px 0;
            li {
              padding-bottom: 18px;
              margin-bottom: 0px;
              padding-left: 1.5em;
              &::before {
                content: "";
              }
              a {
                font-size: 3rem;
                color: white;
                padding-left: 1.6em;
                text-decoration: underline;
                box-shadow: none;
                text-decoration-thickness: 1px;
                text-underline-offset: 5px;
                &:before {
                  .transition(left, 0.2s, ease-in-out);
                  background-image: var(--img-arrow-big-white);
                  background-size: 1.8ex 0.6em;
                  top: 0.2em;
                }
              }
            }
          }
        }
        @media only screen and (max-width: 1070px) {
          .vrtx-box-content {
            left: 90px;
          }
        }
      
        @media only screen and (max-width: 877px) {
          padding-top: 90px;
          margin-bottom: 60px;
          .vrtx-box-content {
            padding-top: 4em;
            top: -52px;
            left: 68px;
            box-shadow: -53px 0 var(--green6Hover);
            ul {
              margin-left: -53px;
              margin-bottom: -20px;
              padding: 10px 40px 10px 0;
              li {
                padding-bottom: 0px;
                padding-left: 1em;
                a {
                  font-size: 2.3rem;
                  text-underline-offset: 3px;
                  &:before {
                    top: 0.3em;
                  }
                }
              }
            }
          }
        }
        &.vrtx-edit-box .vrtx-box-content {
          position: unset;
        }
      }
    }
    
    
    .row-all-colored.grid-color-green {
      background: var(--tilesColor);
    }
    .row-all-colored.grid-color-nature {
      background: var(--alternateRowColor)
    }
    .row-all-colored.grid-color-white {
      background: white;
    }


    /* large picture on top */
    .full-width-picture-bg {
      //margin-top: -6.98em;
      margin-bottom: 4.45em;
      top: 0;
      height: 600px;
      background-position: center right !important;
      .row {
        padding-top: 6.65em;
      }
      .vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
        opacity: 0;
        display: none;
        visibility: hidden;
        img {
          opacity: 0;
          display: none;
          visibility: hidden;
          width: 0;
          height: 0;
        }
      }
      div.mobile-image {
        display: none;
      }
      .vrtx-frontpage-box.large-links {
        opacity: 1;
      }
    }
    &.dark-header .grid-container.full-width-picture-bg {
      margin-top: -136px;
    }

    #vrtx-content .grid-container.sub-header-container {
      .row {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding-left: 0 !important;
        .vrtx-frontpage-box.sub-header-box-big {
          max-width: 100%;
        }
      }
    }
  }
  &:has(#crisis-banner) {
    .full-width-picture-bg {
      margin-top: -4.98em;
    }
  }
}

#vrtx-frontpage #main {
  .grid-container.full-width-picture-bg {
    top: 0;
    margin-bottom: 80px;
    margin-left: 0;
  }
  #vrtx-main-content .row-all-colored:first-child {
    position: relative;
    top: 0;
    margin-bottom: 80px;
  }
  .row-all-colored {
    &.grid-color-articles,
    &.grid-color-events {
      a {
        &:hover,
        &:focus {
          color: var(--textColor);
          text-decoration-color: var(--textColorUnderline);
          &:not(h2 a) {
            text-decoration: none;
          }
        }
      }
      .vrtx-more a,
      .read-more-small {
        &:focus,
        &:hover  {
          box-shadow: 0px 0.03em 0px 0px var(--textColor);
          &::before,
          &:after {
            background-image: var(--img-arrow-big);
            background-size: auto 0.7em;
          }
        }
      }
    }
    &.grid-color-articles {
      background: var(--darkrowColor);
      .vrtx-frontpage-box {
        background: var(--darkrowColor);
      }
    }
    &.grid-color-events {
      background: var(--eventBoxColor);
      .events-box {
        background: var(--eventBoxColor);
      }
    }
    &.grid-color-articles,
    &.grid-color-events {
      .vrtx-frontpage-box .vrtx-feed {
        .published-date {
          color: var(--textColor);
        }
      }
    }
  }

  /* frontpage boxes */
  .grid-container {
      &:has(.img-special-left), &:has(.img-special-right) {
        background: transparent;
        .vrtx-frontpage-box {
          &.img-special-left,
          &.img-special-right {
            background: var(--tilesColor);
            h2,
            h3 {
              font-family: var(--mainFontStack);
            }
            .vrtx-box-content {
              padding: 50px 60px;
              a.read-more, a {
                box-shadow: 0px 0.03em 0px 0px var(--textColor);
                text-decoration-color: var(--textColor);
                text-decoration: none;
                line-height: 1.65;
              }
              * {
                color: var(--textColor);
              }
            }
            &.title-only {
              h2, h3, h4, a {
                margin-bottom: 0;
              }
            }
            &.dark-grey-box {
              background: var(--textColor);
              box-shadow: 0px 0.03em 0px 0px white;

              .vrtx-box-content {
                a.read-more {
                  box-shadow: 0px 0.03em 0px 0px white;
                }

                * {
                  color: white;
                }
              }
            }
            &.green6 {
              background: var(--green6);
              .vrtx-box-content {
                a.read-more {
                  box-shadow: 0px 0.03em 0px 0px white;
                }
                h2 a:not(.read-more) {
                  .textUnderline(white);
                }
                * {
                  color: white;
                }
              }
            }
            &.half-width-picture {
              grid-template-columns: 1fr 1fr;
            }
            &.special-link-bottom {
              margin-bottom: 80px;
              .vrtx-more {
                position: absolute;
                bottom: -80px;
                left: 0;
                a {
                  color: var(--textColor);
                  text-decoration: none;
                  box-shadow: 0px 0.03em 0px 0px var(--textColor);
                  text-decoration-color: var(--textColor);
                  &:hover,
                  &:focus {
                    color: var(--linkColor);
                    box-shadow: 0px 0.03em 0px 0px var(--linkColor);
                  }
                }
              }
            }
          }
          &.transparent {
            background-color: transparent;
            &.img-special-left .vrtx-box-content {
            padding-right: 0;
          }
          &.img-special-right .vrtx-box-content {
            padding-left: 0;
          }
        }
      }
    }
  }

  /* Large feature teaser in frontpage box */
  .full-width-picture-bg.feature-full-width-grid-container {
    height: auto;
  }
  .vrtx-frontpage-box.feature-full-width {
    margin-left: auto;
    padding: 190px 60px 130px 60px;
    text-align: center;
    h2 {
      margin-bottom: 60px;
      line-height: 1.4;
      a {
        text-decoration: none;
        background: white;
        display: inline;
        padding: 8px 12px;
        &:hover,
        &:focus {
          color: var(--textColor);
          .textUnderlineHover(var(--textColor));
        }
      }
    }
    .vrtx-box-content {
      padding: 0 130px;
      * {
        background: white;
        display: inline;
        padding: 8px 10px;
        line-height: 1.7;
      }
      a {
        text-decoration: none;
        &:hover,
        &:focus {
          color: var(--textColor);
          .textUnderlineHover(var(--textColor));
        }
      }
    }
  }

  /* narrow boxes for readable text */
  .vrtx-frontpage-box.plain-text-box {
    max-width: 750px;
    margin: 0 auto;
  }

  /* half width video boxes */
  .half-box-left,
  .half-box-right {
    &.video-box {
      .vrtx-box-content {
        iframe {
          height: 260px;
        }
      }
    }
  }

  /* prioriterte artikler */
  .promoted-article {
    .vrtx-frontpage-box-picture {
      margin: 0 0 20px 0;
    }
    &.color-box {
      .vrtx-box-content {
        background: var(--infoBoxDefaultColor);
        padding: 30px;
      }
      &.research .vrtx-box-content {
        background: var(--infoBoxColorDark);
        * {
          color: white;
        }
        a {
          text-decoration-color: white;
        }
      }
    }
  }
}

// Responsive only
// @desktopBreakpoint - min-width: 1071px
// @desktopSmallBreakpoint - max-width: 1070px
// @responsiveBreakpoint - max-width: 877px

@media only screen and (min-width: 1441px) {
  #vrtx-frontpage {
    #main {
      /* test full width */
      .grid-container.full-width-picture-bg {
        height: auto;
        height: 700px;
        background-position: center top !important;
      }
    }
  }
}

@media only screen and (max-width: 1070px) {
  #vrtx-frontpage #main {
    .full-width-picture-bg {
      margin-top: -151px;
    }
    .vrtx-frontpage-box.header-inn {
      .vrtx-box-content {
        .only-links {
          li a:not(.button):not(.button-large) {
            font-size: 3rem;
            line-height: 4.2rem;
            padding-left: 44px;
          }
        }
      }
    }
  }
  #vrtx-frontpage #main {
    #vrtx-frontpage-introduction,
    .vrtx-introduction {
      max-width: 100%;
      min-width: auto;
    }

    .grid-container.full-width-picture-bg {
      .vrtx-frontpage-box.large-links {
        .vrtx-box-content .only-links {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }

    .row > h2 {
      margin-left: 15px;
      margin-right: 15px;
    }

    /* frontpage boxes */
    .grid-container:not(.row-all-colored):not(.full-width-picture-bg),
    .grid-container {
      .vrtx-frontpage-box.img-special-left,
      .vrtx-frontpage-box.img-special-right {
        margin-left: 15px;
        margin-right: 15px;
        width: auto;
        &.title-only {
          h2,
          h3,
          h4,
          a {
            margin-bottom: 0;
          }
        }
      }
    }
    .vrtx-frontpage-box:not(.img-special-left):not(.img-special-right):not(.full-width-picture):not(.navigation-links):not(.special-logo-video):not(.special-logo-image) {
      padding-left: 15px;
      padding-right: 15px;
    }
    /* Large feature teaser in frontpage box */
    .full-width-picture-bg.feature-full-width-grid-container {
      .vrtx-frontpage-box.feature-full-width.full-width-picture {
        padding: 90px 20px 60px 20px;
        .vrtx-box-content {
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 977px) {
  #vrtx-frontpage #main {
    .full-width-picture-bg {
      height: auto;
      .row {
        padding-top: 0px;
      }
    }
    .vrtx-frontpage-box.header-inn {
      height: 610px !important;
      padding-top: 0;
      .vrtx-frontpage-box-picture {
        margin: 0;
        img {
          display: none;
        }
      }
      .vrtx-box-content {
        padding: 0;
        margin: 0;
        height: 100%;
        .only-links {
          padding: 0 30px;
          position: absolute;
          top: 160px;
          z-index: 1;
          li {
            margin-bottom: 30px;
            a:not(.button):not(.button-large) {
              font-size: 3rem;
              line-height: 4.2rem;
              padding-left: 44px;
            }
          }
        }
        div.mobile-image {
          opacity: 1;
          display: block;
          overflow: hidden;
          height: 100%;
          position: relative;
          img {
            display: block;
            min-width: 100%;
            width: auto;
            height: 100%;
            max-width: none;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
}

// @responsiveBreakpoint - max-width: 877px
//@responsivebreakpoint {
@media only screen and (max-width: 877px) {
  #vrtx-frontpage #main {
    .vrtx-frontpage-box.header-inn {
      .vrtx-box-content {
        .only-links {
          li a:not(.button):not(.button-large) {
            text-shadow: 0 0 5px var(--color-light);
          }
        }
      }
    }
  }
  #vrtx-frontpage #main {
    .full-width-picture-bg {
      margin-top: -106px;
      margin-bottom: 40px;
    }

    .grid-container:not(.row-all-colored):not(.full-width-picture-bg),
    .grid-container {
      .vrtx-frontpage-box.img-special-left,
      .vrtx-frontpage-box.img-special-right {
        display: inline-block;
        h2 {
          font-size: 3.1rem;
          line-height: 4rem;
        }
        .vrtx-box-content {
          padding: 10px 30px;
        }
        &.title-only {
          padding: 20px;
        }
        &.half-width-picture {
          .vrtx-frontpage-box-picture {
            width: 100%;
            a {
              position: relative;
            }
          }
        }
      }
    }

    /* Large feature teaser in frontpage box */

    .full-width-picture-bg.feature-full-width-grid-container {
      .vrtx-frontpage-box.feature-full-width.full-width-picture {
        min-height: 300px;
        position: relative;
        overflow: hidden;
        padding: 40px 20px;
        padding: 60px 0;
        h2 {
          display: inline-block;
          font-size: 3rem;
          line-height: 1.5;
          z-index: 2;
          padding: 0;
          max-width: 90%;
          margin-bottom: 20px;
        }
        .vrtx-frontpage-box-picture {
          img {
            min-height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
            z-index: -1;
          }
        }
        .vrtx-box-content {
          padding: 0;
          max-width: 90%;
          margin: 0 auto;
          *,
          p {
            font-size: 1.5rem;
          }
        }
      }
    }

    .vrtx-frontpage-box .vrtx-box-content .inn-facts-table {
      padding: 20px 0 0 0;
      div {
        flex: 50%;
        margin-bottom: 20px;
        max-width: 50%;
        span {
          padding: 16px 20px;
          display: block;
          word-wrap: break-word;
          word-break: normal;
          &:first-of-type  {
            font-size: 3.3rem;
            padding-bottom: 0;
          }
          &:last-of-type  {
            font-size: 1.6rem;
          }
        }
      }
    }
    .grid-container .row .vrtx-frontpage-box {
      &.img-special-left,
      &.img-special-right {
        &.special-link-bottom {
          margin-bottom: 70px;
          .vrtx-more {
            padding: 30px 0 0 0;
            background: white;
            margin: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  #vrtx-frontpage #main {
    .vrtx-frontpage-box .vrtx-box-content .inn-facts-table {
      div {
        span {
          padding-left: 0;
          padding-right: 0;
        }
        &:nth-of-type(odd)  {
          padding-left: 0;
          padding-right: 5px;
        }
        &:nth-of-type(even)  {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
    // &.dark-header .vrtx-frontpage-box.header-inn .vrtx-box-content .only-links li a.read-more:not(.button):not(.button-large) {
    //   color: var(--btnSecondaryColor);
    //   box-shadow: 0px 0.03em 0px 0px var(--btnSecondaryColor);
    //   &:before,
    //   &:after {
    //     background-image: var(--img-arrow-big-white);
    //     background-size: auto 0.7em;
    //   }
    // }
  }
}

@media only screen and (max-width: 354px) {
  #vrtx-frontpage #main .grid-container .row .vrtx-frontpage-box {
    &.img-special-left,
    &.img-special-right {
      &.special-link-bottom {
        margin-bottom: 100px;
        .vrtx-more {
          padding: 30px 0 0 0;
          background: white;
          margin: 0;
          bottom: -90px;
        }
      }
    }
  }
}
