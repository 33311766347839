/*
  local.less for www.inn.no/inn-test.uio.no
*/

// TODO: cleanup local inn.no lib/uio2 files (remove what is not needed)

@import "lib/uio2/css/base/base.less";
//@import "../../../../../../lib/uio2/css/typography.less";

@import 'lib/uio2/css/grid/content-grid.less';
@import 'lib/uio2/css/grid/grid-container.less';
@import 'lib/uio2/css/grid/header.less';
@import 'grid/sidebar-menu.less'; // Moved here as the UiO changed alot, march 2023
@import 'lib/uio2/css/grid/footer.less';
@import 'lib/uio2/css/grid/jump-links.less';
@import 'lib/uio2/css/grid/breadcrumbs.less';
@import 'lib/uio2/css/grid/left-menu.less';
@import 'lib/uio2/css/grid/cookie-bar.less';
@import 'lib/uio2/css/grid/language-switch.less';

@import "lib/uio2/css/components.less";
@import "lib/uio2/css/documents.less";
@import "lib/uio2/css/listings.less";
@import "lib/uio2/css/search.less";
@import "lib/uio2/css/frontpage-edit.less";
@import "lib/uio2/css/embedded.less";
@import "lib/uio2/css/print.less";

@import "typography.less"; // override less-file from uio

// Local
@import "variables.less"; // override UiO css-variables and own local
@import "load-pretty.less"; 
@import "listings/feeds.less"; 
@import "grid/center-grid.less"; 
@import "documents/study-program.less"; 
@import "documents/research.less"; //research-group and research-project
@import "documents/frontpage.less"; 
@import "custom-elements.less"; 
@import "documents/special.less"; 
@import "grid/header.less";
@import "grid/footer.less";
@import "grid/custom-menu.less"; 
@import "documents/article.less"; 
@import "documents/blog.less"; 
@import "grid/main-menu.less";
@import "documents/event.less";
@import "documents/conference.less";
@import "documents/info.less";
@import "listings/listing.less";
@import "documents/person-presentation.less";
@import "documents/error.less";
//@import "documents/cookies.less"; // deprecated?

@responsiveConsentBreakpoint: ~"only screen and (max-width: 877px)";
@tabletConsentBreakpoint: ~"only screen and (max-width: 1070px)";
@import '../../../../../groups/all/src/resources/css/consent.less';

// Change consent buttons to black variant
.consent-settings .consent-settings-main-buttons,
.consent .consent-main-buttons {
  .button-large {
    background: var(--btnSecondaryBg);
    color: var(--btnSecondaryColor);

    &:focus,
    &:hover {
      background: var(--btnSecondaryBgHover);
      color: var(--btnSecondaryColorHover);
    }
  }
}

@import "print.less";
