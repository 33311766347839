// listing article pages:
#vrtx-article-listing,
body:not(#vrtx-blog-listing) {
  #main .vrtx-resources {
    .vrtx-resource {
      background: var(--alternateRowColor);
      padding: 30px 30px 40px 30px;
      position: relative;
      margin-bottom: 20px;
      .vrtx-title a, a {
        text-decoration: none;
        &:hover, &:focus {
          color: var(--textColor);
          text-decoration: underline;
          text-decoration-color: var(--textColor);
          text-underline-offset: 0.2em;
          text-decoration-thickness: 0.06em;
         }
      }
      a {
        text-decoration: underline;
        .textUnderline(var(--textColor));
        &:hover, &:focus {
          color: var(--textColor);
          text-decoration: none;
         }
      }
      .vrtx-image {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        overflow: hidden;
        margin: 0;
        img {
          height: auto;
          min-height: 100%;
          min-width: 100%;
          object-fit: cover;
          position: absolute;
        }
      }
      a.vrtx-image ~ *:not(.vrtx-read-more) {
        display: block;
        margin-left: 360px;
      }

      &:last-child {
        padding-bottom: 40px;
      }
      .introduction {
        .vrtx-read-more {
          margin-top: 0;
        }
      }
    }
  }
}

#main .eventListing\.upcomingEventsSearchComponent h2 {
  font-size: 3.1rem;
  letter-spacing: 0;
}

#vrtx-hinn-programme-listing,
#vrtx-filter-event-listing,
#vrtx-hinn-exchange-listing,
#vrtx-hinn-person-listing {
  #filters-form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding-top: 25px;
    fieldset#vrtx-filter-query, fieldset#vrtx-filter-tittel {
     grid-column: 1 / 5;
    }
    .filter-container {
      margin: 0;
      position: relative;
      .filter-name, .search-field-label {
        font-size: 1.6rem;
        display: block;
        position: relative;
        margin-bottom: 5px;
      }

      .filter-select {
        box-shadow: 0 1px var(--tilesColor) inset, 0 -1px var(--textColor) inset,
          -1px -1px var(--tilesColor) inset, 1px 0 var(--tilesColor) inset;
        padding: 0 50px 0 15px;
        background: transparent;
        color: var(--textColor);
        display: block;
        height: 3em;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        font-size: 1.7rem;
        line-height: 3;
        cursor: pointer;
        word-break: break-all;
        span {
          display: block;
          -webkit-mask-image: linear-gradient(90deg, black 60%, transparent);
          mask-image: linear-gradient(90deg, black 60%, transparent);
        }
        &::after {
          display: block;
          content: "";
          position: absolute;
          width: 3em;
          height: 100%;
          top: 0;
          right: 0;
          background-color: var(--green1);
          background-image: var(--img-arrow-down);
          background-size: 27%;
          background-repeat: no-repeat;
          background-position: center;
          box-shadow: 0 1px transparent inset, 0 -1px var(--textColor) inset, -1px 0 transparent inset, 1px 0 transparent inset;
          z-index: -1;
        }
        &:hover,
        &:focus {
          outline: none;
        }
        &.selected {
          background-color: var(--tilesColor);
          z-index: -2;
          &::after {
            transform: rotate(180deg);
            border: none;
            box-shadow: 0 1px var(--textColor) inset, 0 -1px transparent inset, -1px 0 transparent inset, 1px 0 transparent inset;
          }
        }
        &:not(.selected) {
          &:focus {
            .addfocus;
          }
          &:hover,
          &:focus {
            background: var(--tilesColor);
            z-index: 0;
          }
        }
      }

      ul.values {
        display: none;
        border: 1px solid var(--eventBoxColor);
        margin: 0;
        border-top: 0;
        padding: 0 15px;
        position: absolute;
        width: 100%;
        z-index: 1;
        background: white;
        font-size: 1.6rem;
        line-height: 1.4;
        max-height: 400px;
        overflow: auto;
        z-index: 2;
        li {
          display: flex;
          align-items: center;
          &::before {
            display: none;
          }
          border-bottom: 1px solid var(--filterChoiceBorderBottom);
          margin: 0;
          &:last-of-type {
            border-bottom: none;
          }
          input {
            margin: 0;
            float: left;
            width: 1.1em;
            height: 1.1em;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border-radius: 4px;
            outline: none;
            background-color: transparent;
            border: 1px solid;
            color: var(--textColor);
            cursor: pointer;
            &:checked {
              background: var(--icon-checked2) no-repeat center center var(--textColor);
              background-size: 80%;
              border: none;
            }
            &:hover,
            &:focus {
              background-color: var(--checkedBgHover);
              border: none;
            }
          }
          label {
            display: inline-block;
            width: 85%;
            padding: 15px 0 15px 6px;
            margin: 0;
          }
          &.single-indent {
            padding-left: 20px;
          }
          &.double-indent {
            padding-left: 40px;
          }
        }
      }
      &#vrtx-filter-kan-sokes {
        align-self: center;
        top: 15px;
        #vrtx-filter-kan-sokes-value {
          height: 19px;
          width: 19px;
          position: absolute;
          left: 23px;
          top: 5px;
          margin: 0;
        }
        label {
          margin-left: 53px;
        }
      }
    }

    &::after {
      content: "";
      width: 100%;
      display: block;
      border-top: 1px solid var(--filterChoiceBorderBottom);
      margin-top: 20px;
      grid-column-start: 1;
      grid-column-end: 5;
    }

    div.accordion-wrapper {
      width: 100%;
      margin: 0;
      cursor: pointer;
      grid-column-start: 1;
      grid-column-end: 5;
      .ui-accordion-header.ui-accordion-header-active {
        border-top: 1px solid transparent;
        border-bottom: 1px solid;
      }
    }
    .filters-expandable {
      display: none;
      max-width: 25%;
      flex: 1 0 21%;
      margin: 14px 0 0 0;
      align-self: center;
      max-width: 100%;
    }
  }
}

#vrtx-hinn-programme-listing,
#vrtx-filter-event-listing,
#vrtx-hinn-exchange-listing,
#vrtx-hinn-person-listing {
  #main {
    #right-main,
    #total-main {
      #vrtx-content #vrtx-main-content {
        position: relative;
        span.active-filters {
          float: left;
          margin: 30px 15px 15px 0 !important;
          color: var(--textColor);
          a.filter-remove {
            font-size: 1.6rem;
          }
        }
        // ul.active-filters,
        // .clear-filters {
        //   float: left;
        // }

        .clear-filters {
          padding: 9px 20px 9px 20px;
          border: 1px solid; 
          height: fit-content;
          line-height: 1.5;
          margin: 20px 20px 15px 0;
          z-index: 1;
          &:hover,
          &:focus {
            background: var(--green1Hover);
          }
        }

        ul.active-filters a,
        a.clear-filters,
        #filters-form button[type="submit"] {
          border-radius: 25px;
          text-decoration: none;
          box-sizing: border-box;
          cursor: pointer;
          color: var(--textColor);
        }
        .no-results {
          max-width: -webkit-fill-available !important;
        }
        #filters-form button[type="submit"] {
          padding: 10px 28px;
          background: var(--btnPrimaryColor);
          width: fit-content;
          text-align: center;
          color: white;
          &:hover, &:focus {
            background: var(--btnPrimaryColorHover);
          }
          &:focus {
            .addfocus;
          }
        }

        #filters-form {
          .filters-submit {
            font-size: 1.8rem;
            grid-column: 1 / 5;
            margin-top: 10px;
          }
          #vrtx-filter-tittel,
          #vrtx-filter-query {
            flex: 100%;
            label {
              position: absolute;
              left: -10000px;
              // top: auto;
              // width: 1px;
              // height: 1px;
              // overflow: hidden;
            }
            input#vrtx-filter-tittel-value,
            input#vrtx-filter-query-value {
              font-size: 1.9rem;
              padding-right: 63px;
              height: 3em;
              padding: 12px 21px 12px 21px;
              max-width: 100%;
              width: 100%;
              border: 1px solid rgba(0, 0, 0, 0.25);
              border-bottom: 2px solid var(--textColor);
              padding-right: 15px;
              &:hover {
                border-color: var(--textColor);
              }
              &:focus {
                .addfocus;
              }
              &.click-focus {
                outline: none;
              } 
            }
            button.filters-search {
              border-radius: 0;
              background: var(--icon-magnifyer) no-repeat center center var(--green1);
              overflow: hidden;
              text-indent: -9999px;
              background-size: 1.2em;
              width: 2.6em;
              height: 100%;
              position: absolute;
              right: 0px;
              text-align: center;
              padding: 10px 28px;
              color: white;
              &:hover, &:focus {
                background-color: var(--green1Hover);
              }
              &:focus {
                border-radius: 0;
                border: 1px solid white;
                box-shadow: 0 0 0 2px var(--color-outline);
                .addfocus;
                outline-style: solid;
              }
            }
          }
        }

        ul.active-filters {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          width: auto;
          max-width: none;
          max-width: 1050px !important;
          padding-right: 15px;
          li {
            display: inline-block;
            width: auto;
            margin: 0;
            a {
              background: var(--eventBoxColor);
              position: relative;
              padding: 10px 35px 10px 20px;
              margin: 0;
              display: flex;
              align-items: center;
              &:hover,
              &:focus {
                color: var(--textColor);
                background: var(--green1Hover);
              }
              &::after {
                content: "";
                width: 1.25em;
                height: 100%;
                position: absolute;
                right: 15px;
                background: var(--icon-close2) no-repeat center right;
                background-size: 48%;
                pointer-events: none;
              }
            }
            &::before {
              display: none;
            }
          }
        }
        .results {
          clear: left;
          padding-top: 2px;
        }
      }
    }
  }
}

// Results
#vrtx-filter-event-listing,
#vrtx-hinn-programme-listing,
#vrtx-hinn-exchange-listing,
#vrtx-hinn-person-listing {
  .vrtx-event,
  .vrtx-programme-entry,
  .vrtx-exchange-destination-entry {
    background: var(--tilesColor);
    position: relative;
    margin: 0 auto 10px auto;
    float: none;
    width: 100%;
    max-width: 690px;
    a {
      text-decoration: none;
      &:hover, &:focus {
        color: var(--textColor);
        .textUnderlineHover(var(--textColor));
      }
    }
    .title {
      font-size: 2rem;

      &::after {
        content: "";
        display: block;
        white-space: pre; /* or pre-wrap */
        height: 0;
      }
    }

    .categories .category,
    span:not(.title):not(.event-dates):not(.event-dates span) {
      color: var(--textColor);
      &::after {
        content: "|";
        display: inline-block;
        margin: 0 5px 0 8px;
      }
      &:last-of-type::after {
        display: none;
      }
    }
  }
  // Paging
  .vrtx-paging-wrapper {
    max-width: 690px;
    width: 100%;
    margin: 60px auto 0 auto;
    .vrtx-page-number {
      margin-right: 10px;
    }
  }
}

// Event listing only
#vrtx-filter-event-listing {
  #main {
    #right-main,
    #total-main {
      #vrtx-content #vrtx-main-content #filters-form {
        .filter-container {
          ul.values {
            border: 1px solid var(--eventBoxColor);
          }
          .filter-select {
            height: 3em;
            padding: 0 50px 0 15px;
            box-shadow: 0 1px var(--eventBoxColor) inset, 0 -1px var(--textColor) inset, 
            -1px 0 var(--eventBoxColor) inset, 1px 0 var(--eventBoxColor) inset;
            &:hover,
            &:focus {
              background: var(--tilesColor);
              z-index: 0;
            }
          }
        }
      }
    }
  }
  // Event Results
  .vrtx-event {
    padding: 25px 30px 25px 130px;
    .event-dates {
      background: var(--eventBoxColor);
      padding: 25px 15px;
      height: 100%;
      display: inline-block;
      width: 110px;
      position: absolute;
      text-align: center;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .date {
        min-height: 50px;
        &:first-child {
          padding-right: 5px;
        }
        &:last-child {
          padding-left: 5px;
          position: relative;
          &::before {
            content: "-";
            position: absolute;
            left: -3px;
          }
          &:first-child {
            &::before {
              content: "";
              display: none;
            }
          }
        }
        #text {
          position: absolute;
          top: 0;
        }
        span.day {
          font-size: 2rem;
        }
        span.month {
          font-size: 1.5rem;
          display: block;
          margin-top: -7px;
        }
      }
    }
    .time {
      display: none;
    }
  }
}
// Results programme listing + exchange-destination-entry
.vrtx-programme-entry,
.vrtx-exchange-destination-entry {
  padding: 25px 30px;
}

// person listing
#vrtx-hinn-unit-employee-listing,
#vrtx-hinn-person-listing {
  .vrtx-person-search-hits {
    clear: both;
    a.vrtx-image {
      display: none;
    }
    td {
      span {
        float: left;
      }
      a span {
        text-decoration: underline;
      }
    }
  }
}

#vrtx-hinn-unit-employee-listing {
  table.vrtx-person-listing td.vrtx-person-listing-name {
    padding-left: 0;
    height: auto;
  }
  .vrtx-person-search-hits td span {
     float: none;
  }
}

// person-listing only
#vrtx-hinn-unit-employee-listing,
#vrtx-hinn-person-listing {
  #main {
    #right-main,
    #total-main {
      #vrtx-content #vrtx-main-content {
        ul.active-filters li a {
          background: var(--tilesColor);
          &:hover,
          &:focus {
            background: var(--alternateRowColor);
          }
        }
        #filters-form {
          #vrtx-filter-query button.filters-search {
            background: var(--icon-magnifyer2) no-repeat center center var(--tilesColor);
            background-size: 1.2em;
            width: 2.6em;
            border-bottom: 2px solid var(--textColor);
          }
          #vrtx-filter-enhet {
            grid-column: 1 / 3;
          }
          #vrtx-filter-campus {
            grid-column: 3 / 5;
          }
          .filter-container {
            ul.values {
              border: 1px solid var(--tilesColor);
            }
            .filter-select {
              &::before,
              &.selected,
              &:hover,
              &:focus, 
              &:not(.selected):hover,
              &:not(.selected):focus {
                background-color: var(--tilesColor);
              }
            }
          }
        }
        .vrtx-person-search-hits {
          span.vrtx-person-listing {
            font-family: var(--smallTxtMediumFontStack);
            font-weight: 600;
            font-size: 2rem;
          }
          .vrtx-person-listing {
            display: block;
            max-width: 690px;
            margin: 10px auto 20px auto;
          }
          ul.vrtx-person-listing li[class^="vrtx-person-"] {
            background: var(--tilesColor);
            padding: 24px 10px 22px 40px;
            margin-left: 0;
            &:before {
              display: none;
            }
            [class^="vrtx-person-listing-"] {
              font-size: 1.8rem;
              font-family: var(--smallTxtFontStack);
            }
            .vrtx-person-listing-name {
              font-family: var(--mediumFontStack);
              font-weight: 600;
              width: 100%;
              display: block;
              font-size: 2rem;
            }
            .vrtx-person-listing-position {
              display: block;
            }
            
            .vrtx-person-listing-position-description {
              display: inline;
              &:before {
                content: " | ";
              }
            }
            .vrtx-person-listing-phone,
            .vrtx-person-listing-email,
            .vrtx-person-listing-mobilephone {
              font-size: 1.6rem;
              padding-right: 1em;
            }
          }
        }
      }
    }
  }
}

// Pagination
#main .vrtx-paging-feed-wrapper {
  font-size: 2.4rem;
  .vrtx-paging-wrapper {
    max-width: 100%;
    a {
      color: var(--paginationTextColor);
      .textUnderline(var(--paginationTextColor));
      &:hover,
      &:focus {
        color: var(--textColor);
        .textUnderlineHover(var(--textColor));
      }
      &.vrtx-next,
      &.vrtx-previous,
      &.vrtx-marked {
        .textUnderline(var(--textColor));
      }
    }
  }
}

@media @desktopSmallBreakpoint {
  #vrtx-hinn-programme-listing,
  #vrtx-filter-event-listing,
  #vrtx-hinn-exchange-listing,
  #vrtx-hinn-person-listing {
    #main #right-main,
    #main #total-main {
      #vrtx-content #vrtx-main-content  {
        .results,
        #filters-form {
          width: 100%;
          min-width: 100%;
          margin-right: auto;
          margin-left: auto !important; // overrde UiO code
        }
        a.clear-filters {
          margin-left: auto !important; // overrde UiO code
        }
      }
    }
  }
}

@media @responsiveBreakpoint {
  #vrtx-article-listing #main #total-main .vrtx-resources,
  #vrtx-article-listing #main #right-main .vrtx-resources,
  body:not(#vrtx-blog-listing) #main {
    .vrtx-resource a.vrtx-image ~ *:not(.vrtx-read-more) {
      display: block;
      margin-left: 50%;
      padding-left: 30px;
    }

    .vrtx-resource.vrtx-default-article,
    .vrtx-resource.vrtx-featured-article {
      a.vrtx-image {
        width: 50%;
        img {
          height: auto;
          min-height: 100%;
          min-width: 100%;
          max-width: 100%;
          position: absolute;
          object-fit: cover;
        }
      }
    }
  }
  #vrtx-hinn-programme-listing,
  #vrtx-filter-event-listing,
  #vrtx-hinn-exchange-listing,
  #vrtx-hinn-person-listing {
    #main {
      #right-main,
      #total-main {
        #vrtx-content #vrtx-main-content  {
          .results,
          #filters-form {
            display: block;
            .filter-container {
              width: 100%;
              margin: 0 0 20px 0;
              ul.values {
                max-height: none;
                position: relative;
              }
              button.filter-select {
                max-width: 100%;
              }
              &#vrtx-filter-kan-sokes {
                #vrtx-filter-kan-sokes-value {
                  left: 0px;
                }
                label {
                  margin-left: 34px;
                }
              }
            }
            .filters-expandable {
              max-width: none;
            }
            div.accordion-wrapper {
              margin-top: 40px;
            }
          }
          .vrtx-paging-wrapper {
            margin-top: 20px;
          }
        }
      }
    }

    // Results
    .vrtx-event,
    .vrtx-programme-entry,
    .vrtx-exchange-destination-entry {
      .title {
        font-size: 1.9rem;
        line-height: 25px;
      }
    }
    .vrtx-programme-entry,
    .vrtx-exchange-destination-entry {
      padding: 20px;
    }
  }

  // person listing
  #vrtx-hinn-unit-employee-listing,
  #vrtx-person-listing {
    #main .vrtx-person-search-hits {
      td a.vrtx-image {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  #vrtx-article-listing #main #total-main .vrtx-resources,
  #vrtx-article-listing #main #right-main .vrtx-resources,
  body:not(#vrtx-blog-listing) #main .vrtx-resources {
    .vrtx-resource a.vrtx-image ~ *:not(.vrtx-read-more) {
      padding-left: 30px;
      margin-left: 0;
      &.published-date {
        padding-top: 20px;
      }
    }
    .vrtx-resource.vrtx-default-article,
    .vrtx-resource.vrtx-featured-article {
      padding: 0 0 40px 0;
      a.vrtx-image {
        width: 100%;
        position: relative;
        img  {
          display: block;
          position: relative;
        }
      }
      div.published-date {
        padding-top: 30px;
      }
      .published-date,
      div.vrtx-title,
      .description.introduction {
        padding: 0 30px;
        margin: 0;
      }
    }
  }
}

.addfocus {
  outline: -webkit-focus-ring-color auto 1px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 1px;
}
