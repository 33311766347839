/* custom-menu.less */
body.not-for-ansatte {
  // temporary *** Because event-with-filter lacks functionality to be hidden from menu Calendar is hidden with css
  .sidebar-global-menu li.events {
    display: none;
  }
  // temporary ^***

  #left-main {
    .vrtx-breadcrumb-menu {
      display: none;
    }
    #sidebar-toggle-link {
      position: relative;
      display: block;
      width: 89px;
      width: auto;
      height: 56px;
      float: right;
      margin-left: 30px;
      overflow: hidden;
      span {
        line-height: 56px;
        padding-right: 10px;
        letter-spacing: 0;
      }
      &::before {
        height: 100%;
        height: 56px;
        background: var(--icon-menu2) no-repeat center center;
        background-size: 33px;
        width: 33px;
        margin: 0;
        float: right;
      }
    }
  }

  &.sidebar-menu-wrapper-visible {
    #left-main {
      position: unset;
      #sidebar-toggle-link::before {
        background-image: var(--icon-menu-close);
        background-size: 60%;
        background-position: 4.5px center;
      }
    }
  }
  #center-menu-same-level-folders {
   // margin-bottom: 60px;
    .menu-title {
      display: none;
    }
    ul.vrtx-breadcrumb-menu,
    .vrtx-subfolder-menu ul {
      width: 100%;
      overflow: hidden;
      margin-left: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
      padding-left: 0;
      > li {
        width: 33.33333333%;
        margin: 0 0 30px 0;
        padding-left: 10px;
        padding-right: 10px;
        clear: none;
        float: left;
        a {
          word-break: break-word;
        }
        &:nth-child(3n + 1) {
          padding-right: 20px;
          padding-left: 0;
          clear: left;
        }
        &:nth-child(3n + 3) {
          padding-left: 20px;
          padding-right: 0;
          clear: none;
        }
        &:before  {
          display: none;
        }

        &.vrtx-parent {
          width: 100%;
          padding: 0;
          ul {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 30px;
            column-gap: 30px;
            width: 100%;
            li {
              margin-left: 0;
              margin-bottom: 0;
              &:before  {
                display: none;
              }
            }
          }
        }
        &.vrtx-parent {
          margin: 0;
        }
      }
      ul.large-box li a,
      &.large-box li a {
        min-height: 108px;
        height: 100%;
      }
    }

    /* hide same level folders */
    .vrtx-breadcrumb-menu li.vrtx-ancestor {
      display: none;
    }
    .vrtx-breadcrumb-menu {
      li.vrtx-parent {
        a {
          display: none;
        }
        ul li a  {
          display: block;
        }
        &::before {
          content: "";
        }
      }
    }
  }
}

// Hide responsive submenu
#responsive-submenu {
  display: none;
}

// Responsive only
// @desktopBreakpoint - min-width: 1071px
// @desktopSmallBreakpoint - max-width: 1070px
// @responsiveBreakpoint - max-width: 877px
// Responsive only
// @desktopSmallBreakpoint - max-width: 1070px

@media @desktopSmallBreakpoint {
  body.not-for-ansatte #header-tools #left-main {
    margin-top: 0 !important;
  }
  body.not-for-ansatte #main {
    #center-menu-same-level-folders {
      min-width: 100%;
      margin-left: 0 !important;
    }
  }
}

@media @responsiveBreakpoint {
  body.not-for-ansatte:not(.vrtx-hinn-studieprogram) #main {
    #center-menu-same-level-folders {
      ul.vrtx-breadcrumb-menu,
      .vrtx-subfolder-menu ul {
        li {
          float: none;
          width: 100%;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 10px;
          a {
            min-height: auto;
          }
        }
      }
    }
  }
}
