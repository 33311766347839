// COPIED FROM uio2-design as global menu changes, march 2023

// Sidebar and sidebar fullscreen menu

// I suspect that the uioRed and everything connected to it can be removed. 
// I have no idea why it's here. (red: Lise 2024)
@uioRed: #b60000; // www.uio.no only sidebar opened menu background

#left-main {
  position: relative;
  width: 220px;
  z-index: 5;
  height: 100%;
  padding: 0 40px 25px 25px;

  .language {
    display: none;
  }
  a {
    color: @textColor;
  }
  .sidebar-services-language-menu {
    display: none;
    margin-top: 60px; // align horizontal with global menu in open menu
    border-top: none;

    li.language {
      display: inline-block;
    }
    li {
      margin-left: 0;
      &:before {
        display: none;
      }
      margin-bottom: 5px;
    }
    li a {
      font-size: 1.4rem;
      line-height: 2.3rem;

      .textUnderlineSwitch;
    }
  }
}

#main .sidebar-services-language-menu {
  li {
    margin-left: 0;
    &:before {
      display: none;
    }
  }
  li a {
    font-size: 1.5rem;

    .textUnderlineSwitch;
  }
}

body.www\.uio\.no:not(.header-context) #main .sidebar-services-language-menu {
  li a {
    color: white;
  }
}

.sidebar-menu-wrapper-visible {
  #left-main {
    position: fixed;
  }
  .info-link-wrapper {
    display: none;
  }
  .grid-container.uio-info-message.alert {
    .col-1-1 {
      > strong {
        display: none;
      }
    }
  }
}

.sidebar-menu-toggle {
  display: inline-block;
  color: @textColor;
  text-decoration: none;

  &:before {
    content: "";
    background: var(--icon-menu) no-repeat center left;
    background-size: auto 100%;
    height: 21px;
    width: 40px;
    display: inline-block;
    margin-right: 0;
    margin-bottom: -7px;
  }

  &:focus {
    outline: 3px solid var(--color-outline);
  }
  &.click-focus {
    outline: none;
  }

  &:focus,
  &:hover {
    span {
      text-decoration: none;
    }
  }

  span {
    display: inline-block;
    font-size: 1.5rem;
    letter-spacing: 0.56px;
    padding-left: 10px;
  }
}

body.sidebar-menu-wrapper-visible .sidebar-menu-toggle {
  &:before {
    background-image: var(--icon-menu-close);
    height: 25px;
  }
  span {
    padding-left: 0px;
  }
}

// Seal
.sidebar-seal {
  position: absolute;
  top: 517px;
  left: 25px;
  display: block;
  // image does not exist: Clean up?
  //background: url("/vrtx/dist/resources/images/global-menu-button/seal.svg") no-repeat 0 0;
  background-size: 100% auto;
  width: 138px;
  height: 138px;
  display: block;
}

// Menu
.sidebar-menu-wrapper {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  display: none;
  overflow: hidden;
  overflow-y: auto;
  padding: 140px 0 100px 0;

  > *:not(.uio) {
    max-width: @gridWidth;
    min-width: @gridMinWidth;
    padding-right: @contentGapRight;
    padding-left: @sidebarWidth;
    margin-left: ~"max(0.01px, calc(calc(calc(var(--width) - @{gridWidth}) / 2)))";
  }
  .sidebar-menu-inner-wrapper {
    position: relative;
    padding-bottom: 130px;
    .sidebar-menu-inner-wrapper-fade {
      display: block;
      pointer-events: none;
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    &.uio {
      &:after {
        display: none;
      }
      > * {
        max-width: @gridWidth;
        min-width: @gridMinWidth;
        padding-right: @contentGapRight;
        padding-left: @sidebarWidth;
        margin-left: ~"max(0.01px, calc(calc(calc(var(--width) - @{gridWidth}) / 2)))";
      }
    }
  }

  .sidebar-services-language-menu {
    display: none;
  }

  a {
    color: @textColor;
    display: block;

    .textUnderlineSwitch;
  }

  form.vrtx-search-form,
  form.vrtx-big-search,
  .search-form,
  form#enheter,
  form#emner,
  form#personer {
    input[type="text"],
    input[type="search"] {
      padding: 8px 38px 7px 0;
      letter-spacing: 0.33px;
      background: transparent;
      border: 1px solid @borderColor;
      border-width: 0 0 1px 0;

      &:focus,
      &:hover {
        border-width: 0 0 1px 0;
      }
      &:focus {
        outline: none;
      }
    }
    button[type="submit"],
    input[type="submit"] {
      opacity: 1;
      width: 22px;
      height: 22px;
      top: 13px;
      right: 0;

      &:focus {
        outline: 1px solid #888;
      }
    }
  }
}
.sidebar-menu-wrapper-visible {
  overflow: hidden;

  #head-admin-menu-wrapper,
  #left-menu-same-level-folders {
    display: none;
  }
  .sidebar-menu-wrapper,
  #left-main .sidebar-services-language-menu {
    display: block;
  }

  #head-wrapper {
    background: white;
    z-index: 6;
    position: relative;
  }
}

// Menu components

.sidebar-left-menu,
.sidebar-services-language-menu {
  a + ul {
    margin-top: 10px;
  }
  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }

    a {
      .baseFontSize;
    }
  }
}

.sidebar-search {
  margin-top: 0;
  margin-bottom: 50px;
  width: 100%;
  max-width: 100%;

  input[type="text"] {
    max-width: 100%;
  }

  input[type="text"],
  button {
    font-size: 2.1rem;
    font-family: @mainFontStack;
  }
}

.sidebar-global-menu {
  font-size: 2.1rem;
  line-height: 2.9rem;

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sidebar-services-language-menu {
  border-top: 1px solid @borderColor;
}

// UiO
.sidebar-menu-wrapper-visible
  .sidebar-menu-wrapper
  .uio.sidebar-menu-inner-wrapper {
  background: @uioRed;
  margin: 0;
  padding: 30px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  //width: ~"calc(100% + 225px)";

  input[type="text"] {
    .placeholderColor (white, white);
    border-color: white;
    border-width: 0 0 2px 0;

    &:focus,
    &:hover {
      border-width: 0 0 2px 0;
    }
  }

  form.vrtx-search-form,
  form.vrtx-big-search,
  .search-form,
  form#enheter,
  form#emner,
  form#personer {
    button[type="submit"],
    input[type="submit"] {
      background-image: var(--icon-magnifyer);
    }
  }

  .sidebar-services-language-menu {
    border-color: white;
  }

  *,
  a {
    color: white;
  }
}

body.www\.uio\.no:not(.header-context) {
  &.sidebar-menu-wrapper-visible {
    #head-wrapper {
      background: @uioRed;
      z-index: 6;
    }
    #head-wrapper,
    #left-main,
    .sidebar-menu-wrapper {
      *,
      a {
        color: white;
      }
      .sidebar-menu-inner-wrapper .sidebar-menu-inner-wrapper-fade {
        display: none;
      }
    }
    .sidebar-menu-toggle {
      &:hover,
      &:focus {
        span {

          .textUnderline(currentColor);
        }
      }
    }
    // Do we need these? 
    .uio-host {
      background-image: url("@{imagePath}header/uio-logo-white-no.svg");
    }
    &.en .uio-host {
      background-image: url("@{imagePath}header/uio-logo-white-en.svg");
    }
    .sidebar-menu-toggle {
      &:before {
        background-image: var(--icon-menu-close-white);
      }
    }
    form.vrtx-search-form,
    form.vrtx-big-search,
    .search-form,
    form#enheter,
    form#emner,
    form#personer {
      button[type="submit"],
      input[type="submit"] {
        background-image: var(--icon-magnifyer-white);
      }
    }
    .sidebar-menu-wrapper {
      background: @uioRed;

      input[type="text"] {
        .placeholderColor (white, white);
        border-color: white;
        border-width: 0 0 2px 0;

        &:focus,
        &:hover {
          border-width: 0 0 2px 0;
        }
      }
    }
    .sidebar-menu-inner-wrapper {
      padding-bottom: 0;
    }
    .sidebar-search {
      margin-top: 0;
    }
    .sidebar-left-menu,
    .sidebar-services-language-menu {
      border-color: white;
    }
    .sidebar-left-menu .vrtx-ancestor + .vrtx-ancestor {
      border-color: white;
    }
    .sidebar-left-menu .vrtx-child > a.vrtx-marked,
    .sidebar-left-menu .vrtx-parent > a.vrtx-marked {
      background: white;

      &,
      * {
        color: @textColor;
      }
    }
  }
}

@media @desktopSmallBreakpoint {
  #main #left-main {
    position: absolute;
    right: 15px;
    top: 30px;
    left: auto !important;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    z-index: 6;
    display: block;
  }

  .sidebar-search {
    margin-top: 0;
  }

  #left-main .uio-acronym,
  .sidebar-menu-wrapper {
    display: none;
  }
  .sidebar-menu-toggle {
    margin-top: 0;
    height: auto;
    display: inline-block;
    width: 38px;

    &:before {
      width: 38px;
      margin-bottom: -6px;
    }
  }

  .sidebar-services-language-menu {
    display: flex;
    flex-direction: row;

    li {
      flex: 1;
      display: block;
      margin-right: 40px;

      &.language {
        display: none;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .sidebar-menu-wrapper-visible {
    #main #left-main nav:not(#left-menu-same-level-folders) {
      display: flex;
      flex-direction: row;
    }
    #main #left-main {
      .sidebar-services-language-menu {
        order: 1;
        margin: 0;
        &,
        .language {
          display: inline-block;
        }
        li:not(.language) {
          display: none;
        }
        .language {
          margin-right: 30px;
          font-size: 1.4rem;
        }
      }
      .sidebar-menu-toggle {
        order: 2;
      }
    }
    .sidebar-menu-inner-wrapper {
      .sidebar-services-language-menu {
        display: flex;
        padding-top: 20px;
        margin-top: 40px;
      }
      .sidebar-menu-inner-wrapper-fade {
        margin-top: -20px;
      }
    }
  }

  // Menu

  body.the-frontpage:not(.sidebar-menu-wrapper-visible) .sidebar-menu-toggle {
    margin-top: 0;
  }

  body.www\.uio\.no:not(.header-context) {
    &,
    &.sidebar-menu-wrapper-visible {
      #left-main {
        top: 30px;
      }
      &.has-slogan .sidebar-menu-toggle,
      .sidebar-menu-toggle {
        margin-top: 0;
      }
    }
    &.sidebar-menu-wrapper-visible {
      #head-wrapper {
        margin-right: 0;
      }
      .uio-acronym {
        display: block;
        background-image: url("@{imagePath}header/uio-logo-acronym-white.svg");
      }
      .uio-host {
        display: none;
      }
      .sidebar-search {
        margin-top: 0;
      }
    }
  }

  .sidebar-menu-wrapper .sidebar-menu-inner-wrapper.uio > *,
  .sidebar-menu-wrapper > :not(.uio) {
    max-width: none;
    min-width: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-menu-wrapper {
    margin-top: 20px;
    padding: 15px 15px 150px 15px;
  }

  .sidebar-menu-wrapper-visible
    .sidebar-menu-wrapper
    .uio.sidebar-menu-inner-wrapper {
    padding: 20px 15px;
    margin: 0;
  }
}

@media @desktopSmallOnlyBreakpoint {
  #main #left-main {
    right: 20px;
  }
  .sidebar-menu-wrapper,
  .sidebar-menu-wrapper-visible
    .sidebar-menu-wrapper
    .uio.sidebar-menu-inner-wrapper {
    padding-left: 25px;
  }
  .sidebar-menu-toggle {
    width: auto;

    span {
      position: relative;
      //top: -3px;
    }
  }
}

@media @responsiveBreakpoint {
  body:not(.www\.uio\.no) {
    &.sidebar-menu-wrapper-visible {
      // Context
      .uio-app-subtitle .uio-host {
        font-size: 1.7rem;
      }
    }
  }

  body.sidebar-menu-wrapper-visible #main #left-main {
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 650px) {
  .sidebar-menu-toggle span {
    display: none;
  }
}
