// Frontpage inner content / grid (and some for other places boxes is used, like studies frontpages)

// Frontpage only specific classes
@import "../documents/frontpage/feature.less";
@import "../documents/frontpage/img-special.less";
@import "../documents/frontpage/sub-header.less";
@import "../documents/frontpage/full-width-picture.less";
@import "../documents/frontpage/filter.less";

// Read more link
.vrtx-more a,
.read-more-small,
.read-more {
  color: @textColor;
  position: relative;
  display: inline;
  padding-left: 1.5em;

  &:before {
    content: "";
    height: 1.1em;
    width: 1.2em;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: .25em;
    background: url("@{imagePath}arrows/arrow-big.svg") no-repeat 0 0;
    background-size: auto .7em;
    .transition(left, .2s, ease-in-out);
  }

  .textUnderlineSwitch;

  &:focus,
  &:hover {
    &:before {
      left: .2em;
    }
  }
}

// Frontpage typography

#vrtx-frontpage {
  .vrtx-frontpage-box {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }

  h1 {
    font-size: 5rem;
    line-height: 6rem;
    letter-spacing: 0.3px;
  }

  h2 {
    font-size: 4rem;
    line-height: 4.9rem;
    letter-spacing: 0.2px;
  }

  // Row title
  .row > h2 {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .row > h2 ~ .vrtx-frontpage-box > h3 {
    margin-top: 0;
  }

  .row > h2 ~ .vrtx-frontpage-box > h2,
  h3 {
    font-size: 3.1rem;
    line-height: 3.8rem;
    letter-spacing: 0.2px;
  }

  .vrtx-more a,
  .read-more,
  h4 {
    font-size: 2.6rem;
    line-height: 3.1rem;
    letter-spacing: 0.2px;
  }

  h4 {
    font-weight: normal;
  }

  h5 {
    font-size: 2.1rem;
    line-height: 2.7rem;
    letter-spacing: 0.3px;
  }

  .third-box-left,
  .third-box-middle,
  .third-box-right {
    h2 {
      font-size: 3.3rem;
      line-height: 4.1rem;
      letter-spacing: 0.3px;
    }
    h3 {
      font-size: 2.8rem;
      line-height: 3.6rem;
      letter-spacing: 0.4px;
    }
    .vrtx-more a,
    .read-more {
      font-size: 2.2rem;
      line-height: 3.1rem;
      letter-spacing: 0.4px;
    }
  }

  h5 {
    font-size: 2.2rem;
    line-height: 3.1rem;
    letter-spacing: 0.38px;
  }

  #vrtx-additional-content .grid-container {
    margin-top:  @verticalGap;
  }
}


#main .grid-container {
  background: white;
  padding: 0;
  margin-bottom: @verticalGap;

  .row {
    &:before,
    &:after {
      .clearfix();
    }
  }

  .vrtx-frontpage-box {
    margin-bottom: 0;
    z-index: 1;
    position: relative;
  }
}

.row-hidden,
.vrtx-frontpage-box-hidden {
  display: none;
}

#vrtx-frontpage #main {
  margin-bottom: 0;

  .row-all-no-margin-bottom { margin-bottom: 0; }
  .row-all-hidden-boxes     { display: none;    }

  .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes) + .row-all-colored:not(.row-all-hidden-boxes) {
    margin-top: -@verticalGap;
  }

  &,
  #total-main #vrtx-content,
  #total-main #vrtx-main-content {
    width: 100%;
  }
}

#vrtx-frontpage:not(.total-main) {
  .row-all-colored {
    padding-left: 40px;
    padding-right: 40px;
  }
}

#vrtx-program-frontpage,
#vrtx-program-option,
#vrtx-course-description,
#vrtx-semester,
#vrtx-frontpage {
  .vrtx-frontpage-box {
    h2, h3 {
      margin-bottom: 25px;
    }
    h2 + h3,
    h3 + h4 {
      margin-top: -15px;
    }
  }
  .vrtx-frontpage-box:not(.vrtx-frontpage-box-picture):not(.half-box-left):not(.half-box-right):not(.third-box-left):not(.third-box-middle):not(.third-box-right) {
     > h2 {
       margin-bottom: 40px;
     }
   }
}

#vrtx-frontpage {
  #responsive-submenu {
    margin-left: 15px;
    margin-right: 15px;
  }
  #vrtx-main-content .row-all-colored:first-child {
    position: relative;
    top: 50px;
    margin-bottom: 130px;
  }
}

// Colored row

#main .row-all-colored {
  background: @boxGreyBg;
  padding-top: @verticalGap;
  padding-bottom: @verticalGap;

  &:last-child {
    margin-bottom: 0;
  }

  // White background text boxes
  input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url] {
    background: #fff;
  }

  &.row-all-colored-red   { background: @UiORed;         }
  &.row-all-colored-light { background: @boxLightGreyBg; }

  &.grid-color-dark-grey  {
    background: @boxDarkGreyBg;

    > * {
      &,
      h2,
      h3,
      h4,
      h5,
      h6,
      a {
        color: #fff;
      }
    }
  }
  &.grid-color-pink-orange {
    background: @boxPinkOrange;
  }
  &.grid-color-blue {
    background: @boxBlue;
    --linkColor: #153AEF;
  }
}

// Box

.vrtx-frontpage-box {
  margin-bottom: @verticalGap;
  clear: left;

  &:after {
    .clearfix();
  }

  &:last-child,
  h1:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    .vrtx-more {
      padding-bottom: 10px;
    }
  }

  > h2 {
    a {
      .textUnderlineSwitch;
    }
  }

  &.vrtx-frontpage-box-picture {
    .vrtx-frontpage-box-picture {
      a {
        display: block;
      }
      //float: right;
      //line-height: 0;
      margin: 0 0 30px 0;
      max-width: 100%;

      img {
        display: block; // removed bottom spacing (previously fixed by line-height)
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.vrtx-more-false {
    > h2 a {
      .textUnderline(@textColorUnderline);
      &:hover,
      &:focus {
        text-decoration-color: transparent;
      }
    }
  }

  &.vrtx-hide,
  &.hide-box,
  &.box-hide {
    display: none;
  }

  .vrtx-more {
    margin-top: 60px;

    span {
      display: inline-block;
    }
  }

  .read-more-small {
    padding-left: 1em;

    &:before {
      background-image: url("@{imagePath}arrows/arrow-small.svg");
      height: .8em;
      width: .8em;
      top: 1ex;
    }
  }

  // Box title
  > h2 {
    margin-top: 0;
  }

  // Box content
  .vrtx-box-content {
    &:after {
      .clearfix();
    }

    /* Remove top margin for content after headings */
    h2 + *,
    h3 + *,
    h4 + *,
    h5 + *,
    h6 + * {
      &,
      + .right,
      + .thirds-middle,
      + .fourths-middle {
        &,
        + .thirds-right,
        + .fourths-middle {
          &,
          + .fourths-right {
            margin-top: 0;
          }
        }
      }
    }

    >*:first-child {
      margin-top: 0;

      >*:first-child {
        margin-top: 0;

        >*:first-child {
          margin-top: 0;
        }
      }

      >.vrtx-subfolder-menu:first-child ul {
        margin-top: 0;
      }

      >.vrtx-feed:first-child ul,
      + .right,
      + .thirds-middle,
      + .fourths-middle {
        margin-top: 0;
        >*:first-child {
          margin-top: 0;
        }

        + .thirds-right {
          margin-top: 0;
          >*:first-child {
            margin-top: 0;
          }
        }

        + .fourths-middle {
          margin-top: 0;
          >*:first-child {
            margin-top: 0;
          }
          + .fourths-right {
            margin-top: 0;
            >*:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }

    >.thirds-left:nth-last-child(3),
    >.thirds-middle:nth-last-child(2),
    >.fourths-left:nth-last-child(4),
    >.fourths-middle:nth-last-child(3),
    >.fourths-middle:nth-last-child(2),
    >.left:nth-last-child(2),
    >*:last-child {
      margin-bottom: 0;

      >.thirds-left:nth-last-child(3),
      >.thirds-middle:nth-last-child(2),
      >.fourths-left:nth-last-child(4),
      >.fourths-middle:nth-last-child(3),
      >.fourths-middle:nth-last-child(2),
      >.left:nth-last-child(2),
      >*:last-child {
        margin-bottom: 0;

        >*:last-child {
          margin-bottom: 0;
        }
      }
      >.vrtx-subfolder-menu:last-child ul {
        margin-bottom: 0;
      }
    }
  }
  &.two-column-feed .vrtx-box-content {
    .vrtx-feed ul li:nth-last-child(2),
    .vrtx-event-component .vrtx-event-component-item:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
  &.three-column-feed .vrtx-box-content {
    .vrtx-feed ul li:nth-last-child(3),
    .vrtx-feed ul li:nth-last-child(2),
    .vrtx-event-component .vrtx-event-component-item:nth-last-child(3),
    .vrtx-event-component .vrtx-event-component-item:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  /* Special classes */

  .grey-content-box {
    background: #f5f7f8;
    padding: 10px 20px;
    clear: left;

    > *:first-child { margin-top: 0;    }
    > *:last-child  { margin-bottom: 0; }
  }

  &.info-box {
    background: #b2b3b7;
  }
  &.dark-grey-box {
    background: @boxDarkGrey;
  }
  &.red-box {
    background: @boxPinkOrange;
  }
  &.transparent-box {
    background: transparent;
  }
  &.blue-box {
    background: @boxBlue;
    --linkColor: #153AEF;
  }
  &.grey-clip-box .icon {
    display: none;
  }
  &.full-box-center {
    margin-left: auto;
    margin-right: auto;
    width: 570px;
  }
  &.uio-info-message.red {
    background: @UiORed;
    font-size: 1.8rem;

    &,
    a {
      color: #fff;
    }
    a {
      .textUnderline(#fff);
    }
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }
}

// Thirds double vertical line

#main .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) {

  &.row-all-colored {
    padding-top: @verticalGap;
    padding-bottom: @verticalGap;
    background: @boxGreyBg;

    .vrtx-frontpage-box {
      >*:first-child { padding-top: 5px;    }
      >*:last-child  { padding-bottom: 5px; }
    }
  }

  // Tmp, not sure about padding on two-third boxes yet
  .vrtx-frontpage-box {
    &.third-box-left {
      padding-right: 39.333px;
    }
    &.third-box-right {
      padding-left: 39.666px;
    }
  }
}

// Box widths / column widths / inner grid

.left,
.right,
.half-box-left,
.half-box-right,
.thirds-left,
.thirds-middle,
.thirds-right,
.fourths-left,
.fourths-middle,
.fourths-right,
.third-box-left,
.third-box-middle,
.third-box-right,
.two-column-feed li,
.two-column-feed .vrtx-event-component-item,
.three-column-feed li,
.three-column-feed .vrtx-event-component-item,
.vrtx-list-articles-columns-2 .vrtx-list-articles,
.vrtx-list-articles-columns-3 .vrtx-list-articles,
#main .navigation-links li,
#main #vrtx-semester-links li,
#vrtx-semester .vrtx-teacher-list-participants li,
#main .vrtx-resources.vrtx-two-columns .vrtx-resource,
.vrtx-frontpage-box:not(.featured-one-big-two-column) .vrtx-featured-item:not(:only-child),
.featured-one-big-two-column .featured-row .vrtx-featured-item,
.feature-img-left .featured-row .vrtx-featured-item,
.feature-img-right .featured-row .vrtx-featured-item,
.feature-full-width .featured-row .vrtx-featured-item,
.vrtx-listing-filters-3-col .vrtx-listing-filters-section,
.vrtx-listing-filters-2-col .vrtx-listing-filters-section,
.vrtx-subfolder-menu ul[class*='resultset'],
#vrtx-organizational-unit-employees > ul > li,
#vrtx-tags ul {
  float: left;
}

div[class*='vrtx-list-articles-col-'],
#vrtx-tags ul:only-child {
  width: 100%;
}

.vrtx-list-articles-columns-2,
.vrtx-list-articles-columns-3 {
  overflow: hidden;
  margin: 0;

  ul {
    margin-bottom: 0 0 30px 0;
  }
}

div[class*='vrtx-list-articles-col-'] {
  float: none;
  padding: 0;
}

.left,
.right,
.half-box-left,
.half-box-right,
.two-column-feed li,
.two-column-feed .vrtx-event-component .vrtx-event-component-item,
.vrtx-list-articles-columns-2 .vrtx-list-articles,
#main .navigation-links li,
#main .vrtx-resources.vrtx-two-columns .vrtx-resource,
#vrtx-semester .vrtx-teacher-list-participants li,
.featured-one-big-two-column .featured-row .vrtx-featured-item,
.vrtx-frontpage-box:not(.featured-one-big-two-column) .vrtx-featured-item:not(:only-child),
.feature-img-left .featured-row .vrtx-featured-item,
.feature-img-right .featured-row .vrtx-featured-item,
.feature-full-width .featured-row .vrtx-featured-item,
.vrtx-listing-filters-2-col .vrtx-listing-filters-section,
#vrtx-organizational-unit-employees > ul > li,
.vrtx-subfolder-menu-sets-2.vrtx-subfolder-menu ul[class*='resultset'],
.two-column-subfolder .vrtx-subfolder-menu ul[class*='resultset'],
#vrtx-tags.vrtx-tags-sets-2 ul  {
  width: ~"calc(100% / 2)";
}

.thirds-left,
.thirds-middle,
.thirds-right,
.third-box-left,
.third-box-middle,
.third-box-right,
.three-column-feed li,
.three-column-feed .vrtx-event-component .vrtx-event-component-item,
.vrtx-list-articles-columns-3 .vrtx-list-articles,
#main .navigation-links.navigation-links-three-columns li,
#main > div > .navigation-links li,
#main #vrtx-semester-links li,
.featured-one-big-three-column .featured-row .vrtx-featured-item,
.vrtx-listing-filters-3-col .vrtx-listing-filters-section,
#vrtx-folder-menu ul,
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu ul[class*='resultset'],
.vrtx-subfolder-menu ul[class*='resultset'],
#vrtx-tags.vrtx-tags-sets-3 ul {
  width: ~"calc(100% / 3)";
}

.third-box-double {
  width: ~"calc((100% / 3) * 2)";
}

.col-1-4,
.fourths-left,
.fourths-middle,
.fourths-right,
#main .navigation-links.navigation-links-four-columns li,
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu ul[class*='resultset'],
.four-column-subfolder .vrtx-subfolder-menu ul[class*='resultset'],
#vrtx-tags.vrtx-tags-sets-4 ul {
  width: ~"calc(100% / 4)";
}

.col-2-4 { width: ~"calc((100% / 4) * 2)"; }
.col-3-4 { width: ~"calc((100% / 4) * 3)"; }

.col-1-5 { width: ~"calc(100% / 5)";       }
.col-2-5 { width: ~"calc((100% / 5) * 2)"; }
.col-3-5 { width: ~"calc((100% / 5) * 3)"; }

.col-1-6,
#bottom-menu .vrtx-subfolder-menu ul[class*='resultset'] {
  width: ~"calc(100% / 6)";
}

.col-2-6 { width: ~"calc((100% / 6) * 2)"; }
.col-3-6 { width: ~"calc((100% / 6) * 3)"; }

.col-4-4,
.col-5-5,
.col-6-6 {
  width: 100%;
}

.left,
.right,
.thirds-left,
.thirds-middle,
.thirds-right,
.fourths-left,
.fourths-middle,
.fourths-right {
  margin-bottom: 20px;
}

// Column padding / clearing

/* --- LEFT BOX --- */
.left,
.half-box-left,
.thirds-left,
.third-box-left,
.third-box-right,
.fourths-left,
.col-1-4:nth-child(4n+1),
.vrtx-list-articles-columns-2 .vrtx-list-articles:nth-child(2n+1),
.vrtx-list-articles-columns-3 .vrtx-list-articles:nth-child(3n+1),
#vrtx-tags.vrtx-tags-sets-2 ul:nth-child(2n+1),
#vrtx-tags.vrtx-tags-sets-3 ul:nth-child(3n+1),
#vrtx-tags.vrtx-tags-sets-4 ul:nth-child(4n+1),
.vrtx-frontpage-box:not(.featured-one-big-two-column) .vrtx-featured-item:not(:only-child):nth-child(2n+1),
.featured-one-big-two-column .featured-row .vrtx-featured-item:nth-child(2n+1),
.feature-img-left .featured-row .vrtx-featured-item:nth-child(2n+1),
.feature-img-right .featured-row .vrtx-featured-item:nth-child(2n+1),
.feature-full-width .featured-row .vrtx-featured-item:nth-child(2n+1),
#main .navigation-links li:nth-child(2n+1),
#main .navigation-links.navigation-links-three-columns li:nth-child(3n+1),
#main #vrtx-semester-links li:nth-child(3n+1),
#vrtx-semester .vrtx-teacher-list-participants li:nth-child(2n+1),
#main > div > .navigation-links li:nth-child(3n+1),
#main .navigation-links.navigation-links-four-columns li:nth-child(4n+1),
#main .vrtx-resources.vrtx-two-columns .vrtx-resource.vrtx-featured-article-left,
#main .vrtx-resources.vrtx-two-columns .vrtx-resource.vrtx-default-article-left,
.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n+1),
.two-column-subfolder .vrtx-subfolder-menu ul[class*='resultset']:nth-child(2n+1),
.four-column-subfolder .vrtx-subfolder-menu ul[class*='resultset']:nth-child(4n+1),
.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+2),
.two-column-subfolder .vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(2n+2),
.vrtx-subfolder-menu-sets-2.vrtx-subfolder-menu ul[class*='resultset']:nth-child(2n+1),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n+1),
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu ul[class*='resultset']:nth-child(4n+1),
.vrtx-subfolder-menu-sets-2.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(2n+2),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+2),
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(4n+2),
#vrtx-folder-menu ul:nth-child(3n+1),
.vrtx-listing-filters-2-col .vrtx-listing-filters-section:nth-child(2n+2),
.vrtx-listing-filters-3-col .vrtx-listing-filters-section:nth-child(3n+2),
.two-column-feed .vrtx-feed li:nth-child(2n+1),
.two-column-feed .vrtx-event-component-item:nth-child(2n+1),
.two-column-feed h2 ~ .vrtx-event-component-item:nth-child(2n+2),
.three-column-feed .vrtx-feed li:nth-child(3n+1),
.three-column-feed .vrtx-event-component-item:nth-child(3n+1),
.three-column-feed h2 ~ .vrtx-event-component-item:nth-child(3n+2),
#vrtx-organizational-unit-employees > ul > li:nth-child(2n+1),
#bottom-menu .vrtx-subfolder-menu ul[class*='resultset']:nth-child(6n+1) {
  padding-right: 40px;
  padding-left: 0;
  clear: left;
}

.thirds-left,
.third-box-left,
.vrtx-list-articles-columns-3 .vrtx-list-articles:nth-child(3n+1),
#vrtx-tags.vrtx-tags-sets-3 ul:nth-child(3n+1),
#main .navigation-links.navigation-links-three-columns li:nth-child(3n+1),
#main .navigation-links.navigation-links-three-columns li:nth-child(3n+1),
#main #vrtx-semester-links li:nth-child(3n+1),
.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n+1),
.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+2),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n+1),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+2),
#vrtx-folder-menu ul:nth-child(3n+1),
.vrtx-listing-filters-3-col .vrtx-listing-filters-section:nth-child(3n+2),
.three-column-feed .vrtx-feed li:nth-child(3n+1),
.three-column-feed .vrtx-event-component-item:nth-child(3n+1),
.three-column-feed h2 ~ .vrtx-event-component-item:nth-child(3n+2) {
  padding-right: 20px;
}

/* --- MIDDLE BOX --- */
.thirds-middle,
.third-box-middle,
.fourths-middle,
.col-1-4:nth-child(4n+2),
.col-1-4:nth-child(4n+3),
.vrtx-list-articles-columns-3 .vrtx-list-articles:nth-child(3n+2),
#vrtx-tags.vrtx-tags-sets-3 ul:nth-child(3n+2),
#vrtx-tags.vrtx-tags-sets-4 ul:nth-child(4n+2),
#vrtx-tags.vrtx-tags-sets-4 ul:nth-child(4n+3),
#main .navigation-links.navigation-links-three-columns li:nth-child(3n+2),
#main > div > .navigation-links li:nth-child(3n+2),
#main .navigation-links.navigation-links-four-columns li:nth-child(4n+2),
#main .navigation-links.navigation-links-four-columns li:nth-child(4n+3),
#main #vrtx-semester-links li:nth-child(3n+2),
#vrtx-folder-menu ul:nth-child(3n+2),
.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n+2),
.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+3),
.four-column-subfolder .vrtx-subfolder-menu ul[class*='resultset']:nth-child(4n+2),
.four-column-subfolder .vrtx-subfolder-menu ul[class*='resultset']:nth-child(4n+3),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n+2),
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu ul[class*='resultset']:nth-child(4n+2),
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu ul[class*='resultset']:nth-child(4n+3),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+3),
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(4n+3),
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(4n+4),
.vrtx-listing-filters-3-col .vrtx-listing-filters-section:nth-child(3n+3),
.three-column-feed .vrtx-feed li:nth-child(3n+2),
.three-column-feed .vrtx-event-component-item:nth-child(3n+2),
.three-column-feed h2 ~ .vrtx-event-component-item:nth-child(3n+3),
#bottom-menu .vrtx-subfolder-menu ul[class*='resultset']:nth-child(6n+2),
#bottom-menu .vrtx-subfolder-menu ul[class*='resultset']:nth-child(6n+3),
#bottom-menu .vrtx-subfolder-menu ul[class*='resultset']:nth-child(6n+4),
#bottom-menu .vrtx-subfolder-menu ul[class*='resultset']:nth-child(6n+5) {
  padding-left: 10px;
  padding-right: 10px;
  clear: none;
}

/* --- RIGHT BOX --- */
.right,
.half-box-right,
.thirds-right,
.fourths-right,
.third-box-right,
.col-1-4:nth-child(4n),
.vrtx-list-articles-columns-2 .vrtx-list-articles:nth-child(2n+2),
.vrtx-list-articles-columns-3 .vrtx-list-articles:nth-child(3n+3),
#vrtx-tags.vrtx-tags-sets-2 ul:nth-child(2n),
#vrtx-tags.vrtx-tags-sets-3 ul:nth-child(3n),
#vrtx-tags.vrtx-tags-sets-4 ul:nth-child(4n),
.vrtx-frontpage-box:not(.featured-one-big-two-column) .vrtx-featured-item:not(:only-child):nth-child(2n+2),
.featured-one-big-two-column .featured-row .vrtx-featured-item:nth-child(2n+2),
.feature-img-left .featured-row .vrtx-featured-item:nth-child(2n+2),
.feature-img-right .featured-row .vrtx-featured-item:nth-child(2n+2),
.feature-full-width .featured-row .vrtx-featured-item:nth-child(2n+2),
#main .navigation-links li:nth-child(2n+2),
#main .navigation-links.navigation-links-three-columns li:nth-child(3n+3),
#main #vrtx-semester-links li:nth-child(3n+3),
#vrtx-semester .vrtx-teacher-list-participants li:nth-child(2n+2),
#main > div > .navigation-links li:nth-child(3n+3),
#main .navigation-links.navigation-links-four-columns li:nth-child(4n+4),
#main .vrtx-resources.vrtx-two-columns .vrtx-resource.vrtx-featured-article-right,
#main .vrtx-resources.vrtx-two-columns .vrtx-resource.vrtx-default-article-right,
.vrtx-listing-filters-2-col .vrtx-listing-filters-section:nth-child(2n+3),
.vrtx-listing-filters-3-col .vrtx-listing-filters-section:nth-child(3n+4),
#vrtx-folder-menu ul:nth-child(3n),
.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n),
.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+4),
.two-column-subfolder .vrtx-subfolder-menu ul[class*='resultset']:nth-child(2n),
.two-column-subfolder .vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(2n+3),
.four-column-subfolder .vrtx-subfolder-menu ul[class*='resultset']:nth-child(4n+4),
.vrtx-subfolder-menu-sets-2.vrtx-subfolder-menu ul[class*='resultset']:nth-child(2n),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n),
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu ul[class*='resultset']:nth-child(4n),
.vrtx-subfolder-menu-sets-2.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(2n+3),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+4),
.vrtx-subfolder-menu-sets-4.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(4n+5),
.two-column-feed .vrtx-feed li:nth-child(2n),
.two-column-feed .vrtx-event-component-item:nth-child(2n),
.two-column-feed h2 ~ .vrtx-event-component-item:nth-child(2n+3),
.three-column-feed .vrtx-feed li:nth-child(3n+3),
.three-column-feed .vrtx-event-component-item:nth-child(3n+3),
.three-column-feed h2 ~ .vrtx-event-component-item:nth-child(3n+4),
#vrtx-organizational-unit-employees > ul > li:nth-child(2n),
#bottom-menu .vrtx-subfolder-menu ul[class*='resultset']:nth-child(6n) {
  padding-right: 0;
  padding-left: 40px;
  clear: none;
}

.thirds-right,
.third-box-right,
.vrtx-list-articles-columns-3 .vrtx-list-articles:nth-child(3n+3),
#vrtx-tags.vrtx-tags-sets-3 ul:nth-child(3n),
.vrtx-frontpage-box:not(.featured-one-big-two-column).featured-three-column .vrtx-featured-item:nth-child(3n+3),
.featured-one-big-three-column .vrtx-featured-item:nth-child(3n+4),
#main .navigation-links.navigation-links-three-columns li:nth-child(3n+3),
#main #vrtx-semester-links li:nth-child(3n+3),
#main > div > .navigation-links li:nth-child(3n+3),
.vrtx-listing-filters-3-col .vrtx-listing-filters-section:nth-child(3n+4),
#vrtx-folder-menu ul:nth-child(3n),
.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n),
.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+4),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu ul[class*='resultset']:nth-child(3n),
.vrtx-subfolder-menu-sets-3.vrtx-subfolder-menu .menu-title ~ ul[class*='resultset']:nth-child(3n+4),
.three-column-feed .vrtx-feed li:nth-child(3n+3),
.three-column-feed .vrtx-event-component-item:nth-child(3n+3),
.three-column-feed h2 ~ .vrtx-event-component-item:nth-child(3n+4) {
  padding-left: 20px;
}

.right,
.thirds-right,
.fourths-right {
  + * {
    clear: both;
  }
}

#vrtx-tags {
  &:after {
    .clearfix();
  }
}

// Article etc. .left/.right

body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor) #main {
  #vrtx-main-content,
  #vrtx-main-user {
    >*:not(#vrtx-main-user) {
      &.left,
      &.right {
        width: ~"calc(@{bodytextContentWidth} / 2)";
      }

      &.thirds-left,
      &.thirds-middle,
      &.thirds-right {
        width: ~"calc(@{bodytextContentWidth} / 3)";
      }

      &.fourths-left,
      &.fourths-middle,
      &.fourths-right {
        width: ~"calc(@{bodytextContentWidth} / 4)";
      }

      &.right,
      &.thirds-middle,
      &.thirds-right,
      &.fourths-middle,
      &.fourths-right {
        margin-left: 0;
      }
    }
  }
}

/* Grid clearing in right-column etc */
#main #vrtx-additional-content,
#main .vrtx-resources.vrtx-two-columns.vrtx-resource-prioritize-first,
#vrtx-content:not(.vrtx-frontpage-wide) > #vrtx-main-content:not(:last-child) [class*='half-box-'],
#vrtx-content:not(.vrtx-frontpage-wide) > #vrtx-main-content:not(:last-child) [class*='third-box-']:not(.third-box-double),
#main .one-column-subfolder {
   > .vrtx-resource,
  .vrtx-subfolder-menu ul[class*='resultset'] {
    padding-left: 0;
    padding-right: 0;
    float: none;
    width: auto;
    clear: left;
  }
}

#main .vrtx-subfolder-menu-sets-1.vrtx-subfolder-menu ul[class*='resultset'],
#main .vrtx-subfolder-menu ul[class*='resultset']:only-child {
  padding-left: 0;
  padding-right: 0;
  float: none;
  width: auto;
  clear: left;
}

/* Super wide picture */

.vrtx-frontpage-box.half-box-wide-image.vrtx-frontpage-box-picture:not(.third-box-double),
.vrtx-frontpage-box.super-wide-picture.vrtx-frontpage-box-picture:not(.third-box-double),
#vrtx-content:not(.vrtx-frontpage-wide) #vrtx-main-content:not(:last-child) [class*='third-box-'].vrtx-frontpage-box-picture:not(.third-box-double) {
  .vrtx-frontpage-box-picture {
    position: static;
    float: none;
    margin: 0 0 15px 0;

    img {
      width: auto;
    }
  }

  &[class*='half-box-'],
  &[class*='third-box-'] {
    > h2 {
      margin-top: -15px;
    }
  }

  > h2,
  .vrtx-box-content {
    margin-left: 0;
  }
}

/* Special classes */

// Person image box
.vrtx-frontpage-box.person-image-box.vrtx-frontpage-box-picture {
  .vrtx-frontpage-box-picture img {
    width: 126px;
  }
}

// Article text width in a frontpage box
.vrtx-frontpage-box.plain-text {
  max-width: 1000px;

  >h2,
  .vrtx-box-content {
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
  }
}

.top-dotted-seperator-tighter {
  clear: left;
  border-top: 1px solid @borderColor;
  margin: 15px 0 0 0;
  padding: 15px 0 0 0;
}

.vrtx-subfolder-menu {
  width: 100%;

  &:after {
    .clearfix();
  }
}

.subfolder-menu-alt {
  float: left;
  clear: left;
  margin: 30px 0 0 0;
  padding: 0 0 0 0;
  border-top: 4px solid @borderColor;

  ul {
    margin-bottom: 0;
  }
}

.align-middle-image {
  vertical-align: middle;
}

/* Additional content */

#main #vrtx-additional-content .grid-container {
  margin-bottom: @verticalGap;

  .vrtx-frontpage-box {
    margin-bottom: 0;
  }
}

@media @desktopSmallBreakpoint {
  #vrtx-frontpage {
    .vrtx-frontpage-box {
      .baseFontSize;
    }

    h1 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      letter-spacing: 0.3px;
    }

    h2 {
      font-size: 3.4rem;
      line-height: 4.1rem;
      letter-spacing: 0.3px;
    }

    .row > h2 ~ .vrtx-frontpage-box > h2,
    h3 {
      font-size: 3rem;
      line-height: 3.7rem;
      letter-spacing: 0.3px;
    }

    .vrtx-more a,
    .read-more,
    h4 {
      font-size: 2.3rem;
      line-height: 2.9rem;
      letter-spacing: 0.2px;
    }

    h4 {
      font-weight: normal;
    }

    h5 {
      font-size: 1.7rem;
      line-height: 2.8rem;
      letter-spacing: 0.3px;
      font-weight: bold;
    }
  }
}

@media @responsiveBreakpoint {

  .ungrid() {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .two-column-feed li,
  .three-column-feed li {
    float: none;
    width: auto;
  }

  #vrtx-frontpage {
    #vrtx-main-content .row-all-colored:first-child {
      top: 0;
      margin-bottom: 80px;
    }
  }

  #vrtx-frontpage #head-wrapper #head,
  #vrtx-frontpage #footer-wrapper #footers {
    max-width: none;
    min-width: 0;
    padding-left: 25px;
    padding-right: 25px;
  }

  #vrtx-frontpage #head-wrapper #head {
    padding-left: 0;
    padding-right: 0;
  }

  body.www\.uio\.no {
    &#vrtx-frontpage #head-wrapper #head,
    &#vrtx-frontpage #footer-wrapper #footers {
      max-width: none;
      min-width: 0;
      padding-left: 25px;
      padding-right: 25px;
    }
    &#vrtx-frontpage #head-wrapper #head {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .vrtx-frontpage-box {
    margin-bottom: @verticalGap;
  }

  body #main,
  body #main #vrtx-content,
  body #main #vrtx-content .vrtx-box-content {
    display: block;
  }

  #main,
  #main #total-main,
  #main #right-main,
  #main #total-main #vrtx-content,
  #main #right-main #vrtx-content,
  #main #total-main #vrtx-main-content,
  #main #right-main #vrtx-main-content,
  #main #total-main #vrtx-main-content:last-child,
  #main #right-main #vrtx-main-content:last-child,
  #main #total-main #vrtx-main-content.vrtx-empty-additional-content,
  #main #right-main #vrtx-main-content.vrtx-empty-additional-content,
  #main #total-main #vrtx-main-content.vrtx-hide-additional-content-false,
  #main #right-main #vrtx-main-content.vrtx-hide-additional-content-false,
  #main #total-main #vrtx-additional-content,
  #main #right-main #vrtx-additional-content {
    float: none;
    width: 100%;
    margin: 0;
    max-width: none;
    min-width: 0;
  }

  #main #total-main #vrtx-additional-content,
  #main #right-main #vrtx-additional-content {
    .vrtx-frontpage-box:not(.vrtx-search-main-links-box):first-child {
      margin-top: 40px;
    }
  }

  #vrtx-frontpage #main #vrtx-additional-content {
    .vrtx-frontpage-box:not(.vrtx-search-main-links-box):first-child {
      margin-top: 0px;
    }
  }

  #vrtx-related-content {
    margin: 40px 0;
  }

  #main {
    padding: 15px 15px 40px 15px;
  }

  #vrtx-frontpage #main {
    .row,
    .vrtx-back,
    .vrtx-context-box,
    h1,
    #breadcrumbs,
    #vrtx-frontpage-introduction {
      margin-left: 0;
      max-width: none;
      min-width: 0;
    }
    #breadcrumbs {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  #main #right-main,
  #main #total-main {
    .left,
    .right,
    .thirds-left,
    .thirds-middle,
    .thirds-right,
    .fourths-left,
    .fourths-middle,
    .fourths-right,
    .vrtx-frontpage-box,
    .vrtx-featured-item:nth-child(n),
    .vrtx-list-articles-columns-2 .vrtx-list-articles:nth-child(n),
    .vrtx-list-articles-columns-3 .vrtx-list-articles:nth-child(n),
    .navigation-links li:nth-child(n),
    #vrtx-semester-links li:nth-child(n),
    .vrtx-teacher-list-participants li:nth-child(n),
    #vrtx-tags ul:nth-child(n),
    #vrtx-organizational-unit-employees > ul > li:nth-child(n),
    .vrtx-resources.vrtx-two-columns .vrtx-resource,
    .vrtx-listing-filters .vrtx-listing-filters-section:nth-child(n),
    .vrtx-subfolder-menu ul[class*='resultset']:nth-child(n) {
      .ungrid();
    }

    .grid-container.row-all-colored {
      margin-left: 0;
      margin-right: 0;
    }

    .vrtx-feed li:nth-child(n),
    .vrtx-event-component-item:nth-child(n) {
      float: none;
      width: auto;
      padding-left: 0;
      padding-right: 0;
    }

    #vrtx-organizational-unit-employees > ul > li {
      &:nth-child(n) {
        overflow: hidden;
      }
      &:nth-last-child(2) {
        margin-bottom: 40px;
      }
    }

    .vrtx-subfolder-menu ul[class*='resultset']:nth-child(n) {
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: 30px;
      }

      /*+ ul[class*='resultset']:nth-child(n) {
        margin-top: 0;
      }*/
    }

    ul.left,
    ul.thirds-left,
    ul.thirds-middle,
    ul.fourths-left,
    ul.fourths-middle {
      margin-bottom: 0;
    }

    div.left,
    div.thirds-left,
    div.thirds-middle,
    div.fourths-left,
    div.fourths-middle {
      margin-bottom: 20px;
    }

    .vrtx-frontpage-box,
    #vrtx-semester-links li {
      height: auto !important;
      /* min-height: 0 !important; */
    }

    .vrtx-frontpage-box {
      &.vrtx-frontpage-box-picture {
        .vrtx-frontpage-box-picture {
          margin-right: 0;
          margin-left: 0;
          float: none;
        }
        &[class*='half-box-'],
        &[class*='third-box-'] {
          .vrtx-more,
          .vrtx-frontpage-box-picture {
            left: 15px;
          }
        }
      }

      .vrtx-box-content {
        .vrtx-feed ul li:nth-child(n):not(:last-child),
        .vrtx-event-component .vrtx-event-component-item:nth-child(n):not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }

  #vrtx-frontpage {
    .vrtx-frontpage-box {
      .baseFontSize;
    }

    h1 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      letter-spacing: 0.3px;
    }

    h2 {
      font-size: 3.4rem;
      line-height: 4.1rem;
      letter-spacing: 0.3px;
    }

    .row > h2 {
      margin-left: 15px;
      margin-right: 15px;
    }

    .row > h2 ~ .vrtx-frontpage-box > h2,
    h3 {
      font-size: 3rem;
      line-height: 3.7rem;
      letter-spacing: 0.3px;
    }

    .vrtx-more a,
    .read-more,
    h4 {
      font-size: 2.3rem;
      line-height: 2.9rem;
      letter-spacing: 0.2px;
    }

    h4 {
      font-weight: normal;
    }

    h5 {
      font-size: 1.7rem;
      line-height: 2.8rem;
      letter-spacing: 0.3px;
      font-weight: bold;
    }

    #main {
      padding: 15px 0 0 0;

      .vrtx-back,
      h1,
      #vrtx-frontpage-introduction,
      .vrtx-frontpage-box {
        padding-left: 15px;
        padding-right: 15px;
      }

      .vrtx-back,
      h1,
      #vrtx-frontpage-introduction {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      h1 {
        width: auto;
      }

      .vrtx-frontpage-box {
        border-left: none;
        border-right: none;
        margin-bottom: 80px;

        &.no-margin-bottom,
        &:last-child {
          margin-bottom: 0;
        }

        &.grey-box,
        &.grey-clip-box,
        &.grey-box-light,
        &.red {
          > *:first-child { padding-top: 20px;    }
          > *:last-child  { padding-bottom: 20px; }

          &[class*='half-box-'],
          &[class*='third-box-'] {
            &:not(.super-wide-picture):not(.half-box-wide-image).vrtx-frontpage-box-picture > *:nth-child(2) {
              padding-top: 20px;
            }
          }

          &.white-box-green-header,
          &.white-box-orange-header,
          &.white-box-red-header {
            > h2:first-child {
              padding-top: 7px;
            }
          }
        }

        &.grey-box,
        &.grey-clip-box,
        &.grey-box-light,
        &.red,
        &:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) + .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
          > *:first-child { padding-top: 20px;    }
          > *:last-child  { padding-bottom: 20px; }

          &[class*='half-box-'],
          &[class*='third-box-'] {
            &:not(.super-wide-picture):not(.half-box-wide-image).vrtx-frontpage-box-picture > *:nth-child(2) {
              padding-top: 20px;
            }
          }

          &.white-box-green-header,
          &.white-box-orange-header,
          &.white-box-red-header {
            > h2:first-child {
              padding-top: 7px;
            }
          }
        }

        &:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) + .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
          > *:last-child { padding-bottom: 0px; }
        }

        &.grey-box:not(.responsive-hide) + .grey-box,
        &.grey-clip-box:not(.responsive-hide) + .grey-clip-box,
        &.grey-box-light:not(.responsive-hide) + .grey-box-light,
        &.red:not(.responsive-hide) + .red {
          margin-top: -80px;
        }

        &:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red):not(.responsive-hide) + .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
          margin-top: -18px;
        }

        &.big-image {
          padding-left: 0;
          padding-right: 0;

          li > *:not(.vrtx-image) {
            padding-left: 15px;
            padding-right: 15px;
          }
          h2,
          a.all-messages,
          .vrtx-more {
            margin-left: 15px;
            margin-right: 15px;
          }
          .vrtx-feed.with-images ul li a.vrtx-image:first-child {
            margin-top: -15px;
          }
        }
      }

      .grid-container {
        margin-top: 0;
        margin-bottom: @verticalGap;

        .row {
          width: 100%;
          max-width: none;
          min-width: 0;
          padding: 0;
          margin: 0;
        }

        &.row-one-colored .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
          &[class*='half-box-'],
          &[class*='third-box-'] {
            &:not(.super-wide-picture):not(.half-box-wide-image).vrtx-frontpage-box-picture  > *:nth-child(2) {
              padding-top: 0;
            }
          }
          > *:first-child { padding-top: 0;    }
          > *:last-child  { padding-bottom: 0; }
        }

        &.row-all-hidden-boxes-responsive {
          display: none;
        }

        &.row-all-no-margin-bottom {
          margin-bottom: 0;
        }

        // Same following colors get combined and gray border between
        &.row-last-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes):not(.row-all-hidden-boxes-responsive) + .row-first-colored {
          margin-top: -80px;
        }

        &:not(.row-last-colored):not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes):not(.row-all-hidden-boxes-responsive) + .grid-container:not(.row-first-colored) {
          margin-top: -18px;
        }

        &:not(.row-last-colored) + .grid-container:not(.row-first-colored) {
          padding-top: 18px;
        }

        &.row-all-colored {
          padding-top: ~"calc(@{verticalGap} / 2)";
          padding-bottom: ~"calc(@{verticalGap} / 2)";
        }
        &:last-child {
          margin-bottom: 0;

          .vrtx-frontpage-box:last-child:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
            margin-bottom: 80px;
          }
        }
      }
    }

    h1.hidden:first-child + #vrtx-main-content,
    #vrtx-main-content:first-child {
      .grid-container {
        &.row-all-colored:first-child {
          margin-top: -15px;
        }
      }
    }
  }

  #vrtx-frontpage:not(.total-main) {
    .row-all-colored {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  #vrtx-frontpage,
  #vrtx-program-frontpage,
  #vrtx-program-option,
  #vrtx-semester,
  #vrtx-course-description {
    #main .vrtx-frontpage-box {
      // Fullwidth with no picture
      &:not(.vrtx-frontpage-box-picture):not(.half-box-left):not(.half-box-right):not(.third-box-left):not(.third-box-middle):not(.third-box-right) {
        &:not(.vrtx-more-false) {
          > h2 {
            max-width: none;
          }
          &.vrtx-header-false {
            padding-top: 0;
          }
        }
        > h2 {
          padding-right: 0;
        }
        .vrtx-more {
          position: static;
          margin-top: 30px;
          font-size: 1.7rem;
        }
      }
    }
  }
}
