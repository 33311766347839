@responsiveBreakpoint: ~"only screen and (max-width: 877px)";

/*
	This font software is the property of Commercial Type.

	You may not modify the font software, use it on another website, or install it on a computer.

	License information is available at http://commercialtype.com/eula
	For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

	Copyright (C) 2013 Schwartzco Inc.
	1809-VLZSIV
*/
@font-face {
  font-family: "Graphik";
  src: url("/vrtx/dist/resources/fonts/graphik/graphik-regular-web.woff2")
      format("woff2"),
    url("/vrtx/dist/resources/fonts/graphik/graphik-regular-web.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphikmedium";
  src: url("/vrtx/dist/resources/fonts/graphik/graphik-medium-web.woff2")
      format("woff2"),
    url("/vrtx/dist/resources/fonts/graphik/graphik-medium-web.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphikcompact";
  src: url("/vrtx/dist/resources/fonts/graphik/graphikcompact-regular-web.woff2")
      format("woff2"),
    url("/vrtx/dist/resources/fonts/graphik/graphikcompact-regular-web.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphikcompact-medium";
  src: url("/vrtx/dist/resources/fonts/graphik/graphikcompact-medium-web.woff2")
      format("woff2"),
    url("/vrtx/dist/resources/fonts/graphik/graphikcompact-medium-web.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphiksemibold";
  src: url("/vrtx/dist/resources/fonts/graphik/graphik-semibold-web.woff2")
      format("woff2"),
    url("/vrtx/dist/resources/fonts/graphik/graphik-semibold-web.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphikblackitalic";
  src: url("/vrtx/dist/resources/fonts/graphik/graphik-blackitalic-web.woff2")
      format("woff2"),
    url("/vrtx/dist/resources/fonts/graphik/graphik-blackitalic-web.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gza";
  src: url("/vrtx/dist/resources/fonts/gza/gzaregular-web.woff2") format("woff2"),
    url("/vrtx/dist/resources/fonts/gza/gzaregular-web.woff") format("woff"),
    url("/vrtx/dist/resources/fonts/gza/gzaregular-italic-web.woff2") format("woff2"),
    url("/vrtx/dist/resources/fonts/gza/gzaregular-italic-web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gzasemibold";
  src: url("/vrtx/dist/resources/fonts/gza/gzasemibold-web.woff2") format("woff2"),
    url("/vrtx/dist/resources/fonts/gza/gzasemibold-web.woff") format("woff"),
    url("/vrtx/dist/resources/fonts/gza/gzasemibold-italic-web.woff2") format("woff2"),
    url("/vrtx/dist/resources/fonts/gza/gzasemibold-italic-web.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gzabold";
  src: url("/vrtx/dist/resources/fonts/gza/gzabold-web.woff2") format("woff2"),
    url("/vrtx/dist/resources/fonts/gza/gzabold-web.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "BrushScriptMT";
  src: url("/vrtx/dist/resources/fonts/brush-script-mt.woff2") format("woff2"),
    url("/vrtx/dist/resources/fonts/brush-script-mt.woff") format("woff");
  font-display: swap;
}


/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-display: swap;
  src: url("/vrtx/dist/resources/fonts/josefin-sans-bolditalic.woff2") format('woff2');
} 

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-display: swap;
  src: url("/vrtx/dist/resources/fonts/josefin-sans-bold.woff2") format('woff2');
 }


// Don't change this one
html {
  font-size: 62.5%; // makes 0.1rem = 1px (browser normally use zoom scaling,
  //                     but support font scaling also with font-size/line-height in rem)
}

#breadcrumbs a {
  padding-left: 2em;
}

a {
  color: var(--textColor);
  text-underline-offset: 0.1em;
  &:focus,
  &:hover {
    color: var(--linkColor);
    .textUnderlineHover(var(--linkColor));
  }
  &[name] {
    color: var(--textColor);
    text-decoration: none;
  }
}

html:not(.special-page-config-header-minimal) body,
html:not(.special-page-config-header-minimal)
  #main
  #vrtx-main-content
  .vrtx-box-content,
#filters-form input[type="submit"] {
  font-family: var(
    --mainFontStack
  ); // change it in variables.less if you want to change it (as it used elsewhere also)
  color: var(--textColor);
  font-size: 1.8rem;
  line-height: 1.5;
}

html.special-page-config-header-minimal body,
html.special-page-config body,
html.special-page-config-header-minimal body .special-lead p,
html.special-page-config-header-minimal body .special-lead p {
  font-size: 2.2rem;
}

html.special-page-config-header-minimal body,
html.special-page-config #main #vrtx-main-content .vrtx-frontpage-box *,
html.special-page-config-header-minimal
  #main
  #vrtx-main-content
  .vrtx-frontpage-box
  * {
  font-family: var(--mainFontStack);
}

* {
  .boxSizing (@border: border-box);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.small-text {
  font-size: 1.6rem;
  line-height: 2.5rem;
}

p + p.small-text {
  margin-top: -10px;
}

/* tests iselin*/

p {
  margin: 10px 0;
}

ul {
  list-style-type: none;
}

ul,
ol {
  margin: 20px 0 40px;

  ul,
  ol {
    margin-bottom: 0;
  }

  li {
    > p:first-child {
      margin-top: 0;
    }
    > p:last-of-type {
      margin-bottom: 0;
    }
  }
}
ol {
  li {
    margin-left: 20px;
  }
}
li {
  margin-bottom: 10px;
}
ul li {
  margin-left: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    content: "\25A0";
    margin-left: -19px;
    position: absolute;
    top: 6px;
    text-align: left;
    font-size: 0.5em;
    display: inline-block;
    width: 20px;
    height: 2em;
  }

  li {
    &:before {
      content: "\25A1";
    }
    li {
      &:before {
        content: "\25C7";
      }
    }
  }

  &.success:before {
    color: var(--listSuccessColor);
    font-size: 1.6rem;
  }
}
p + ul:not(.only-links),
p + ol {
  &,
  + ol.right,
  + ul.right,
  + ul.thirds-middle,
  + ol.thirds-middle,
  + ul.thirds-right,
  + ol.thirds-right {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
ul,
ol {
  p + ul,
  p + ol {
    margin-top: 0;
  }
}

// no list bullets in customize cookies
body.marketing-container-fixed ul li:before {
  content: "";
}

// Main menu
.sidebar-menu-wrapper {
  a {
    font-size: 2.5rem;
    font-family: var(--mediumFontStack);
    font-weight: 600;
    margin-bottom: 30px;
  }
}

dl dl {
  margin: 10px 0;
}
dt {
  font-family: var(--mediumFontStack);
  font-weight: 600;
}
dd {
  margin: 0 0 10px 0;
}

blockquote {
  font-size: 3rem;
  line-height: 1.5;
  letter-spacing: 0px;
  font-family: var(--secondaryFontStack);

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  &.blockquote-small {
    font-size: 1.9rem;
    line-height: 2.8rem;
    border-left: 2px solid black;
    padding-left: 50px;

    > *:first-child {
      margin-top: 10px;
    }
    > *:last-child {
      margin-bottom: 10px;
    }

    /* Align line at left side with text */
    &:before,
    &:after {
      content: " ";
      display: block;
      border-left: 2px solid white;
      margin-left: -52px;
      height: 1px;
    }
    &:before {
      margin-bottom: -0.7ex;
    }
    &:after {
      margin-top: -0.7ex;
    }
  }
}

hr {
  .floatfix();
  border-bottom: 1px solid var(--borderColor);
  margin: 40px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
html.special-page-config-header-minimal
  #main
  #vrtx-content
  .vrtx-frontpage-box:not(.special-big-title)
  h2, h2:not(.menu-label), h4, h5,
.vrtx-event-component-title,
.vrtx-feed a.item-title,
.vrtx-resources .vrtx-resource a.vrtx-title,
body#vrtx-blog-listing .vrtx-title,
.promoted-article .vrtx-box-content h3,
#vrtx-hinn-programme-listing .title,
#vrtx-hinn-exchange-listing .title,
#filters-form span.filter-name,
#vrtx-structured-event .time-and-place-columns .column .header,
.large-links ul.only-links li a:not(.button):not(.button-large),
.vrtx-paging-wrapper .vrtx-marked,
.vrtx-paging-wrapper .marked,
#filters-form .filter-name,
#filters-form .search-field-label,
#vrtx-filter-event-listing .title {
  font-family: var(--mediumFontStack);
  font-weight: 600;
}

#vrtx-filter-event-listing .day {
  font-family: var(--smallTxtMediumFontStack);
  font-weight: 600;
}
#vrtx-filter-event-listing .date .month,
#vrtx-hinn-programme-presentation .vrtx-field-faculty {
  font-family: var(--smallTxtFontStack);
}

h1,
body h1,
#vrtx-structured-article h1,
body#vrtx-frontpage h1,
#vrtx-frontpage #main .feature-full-width.full-width-picture h2 {
  font-size: 4.5rem;
  line-height: 5.17rem;
  letter-spacing: 0;
  //font-family: "Gzabold", Georgia, serif;
  font-family: var(--mainFontStack);
  font-weight: 600;
  color: var(--textColor);
}

html.special-page-config #vrtx-content h1,
html.special-page-config-header-minimal #vrtx-content h1 {
  //font-family: "Gzabold", Georgia, serif;
  font-family: var(--mainFontStack);
  font-size: 5.4rem;
  line-height: 7.5rem;
}

/* facts container */
.not-for-ansatte .vrtx-facts-container > *:first-child {
  letter-spacing: 0;
}

/* frontpage  +  article */
h2 a.read-more,
.vrtx-toc-header,
#vrtx-blog-listing #main .vrtx-resources .vrtx-resource a.vrtx-title,
.not-for-ansatte .vrtx-facts-container > *:first-child,
#vrtx-frontpage #main h2,
#vrtx-frontpage.marketing-container-fixed #marketing-customize-container h2,
h2,
#vrtx-frontpage #main .vrtx-frontpage-box.img-special-left h2,
#vrtx-frontpage #main .vrtx-frontpage-box.img-special-right h2,
#vrtx-frontpage #main .vrtx-frontpage-box.img-special-left h2 a.read-more,
#vrtx-frontpage #main .vrtx-frontpage-box.img-special-right h2 a.read-more {
  font-size: 3.1rem;
  line-height: 4.3rem;
  letter-spacing: 0;
}

.display-as-h3,
.vrtx-toc-style-like-h3 .vrtx-toc-header,
h3,
#vrtx-frontpage #main h3,
h3 a.read-more,
caption,
#vrtx-frontpage.marketing-container-fixed #marketing-customize-container h3 {
  font-size: 2.5rem;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.display-as-h4,
.vrtx-toc-style-like-h4 .vrtx-toc-header,
#vrtx-frontpage #main h4,
h4 {
  font-size: 2.1rem;
  line-height: 2.9rem;
  letter-spacing: 0;
}

h5,
#vrtx-frontpage #main h5 {
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: 0;
}

h6,
small {
  font-size: 1.7rem;
  line-height: 2.7rem;
  font-weight: normal;
  font-style: italic;
  letter-spacing: 0;
}

/* frontpage + feeds - events + news  */
#vrtx-frontpage #main,
#main {
  .vrtx-more a,
  .read-more {
    font-size: 2.3rem;
    line-height: 2.8rem;
    letter-spacing: 0;
  }

  .vrtx-feed,
  .vrtx-event-component .vrtx-event-component-item {
    .vrtx-event-component-misc,
    .published-date {
      font-size: 1.6rem;
      line-height: 1.4;
      letter-spacing: 0.15px;
      color: var(--publishedDateGrey);
    }
  }
  .vrtx-feed.with-images,
  .vrtx-event-component.with-images .vrtx-event-component-item {
    .vrtx-list-item-content:only-child {
      .vrtx-event-component-misc,
      .published-date {
        color: var(--textColor);
      }
    }
  }
  .row-color-blue,
  .row-color-red {
    .vrtx-box-content .items li .published-date,
    .vrtx-event-component
      .vrtx-event-component-item
      .vrtx-event-component-misc {
      color: var(--textColor);
    }
  }
}

// introduction:
#main .vrtx-introduction,
#vrtx-structured-article .vrtx-introduction,
#vrtx-frontpage #vrtx-frontpage-introduction .vrtx-introduction,
#vrtx-frontpage .vrtx-introduction,
.vrtx-organizational-unit-introduction,
.vrtx-frontpage-box.feature-full-width.full-width-picture p,
body #main .vrtx-resources .vrtx-resource a.vrtx-title,
.marketing-container-fixed
  #marketing-customize-container
  .marketing-customize-introduction {
  font-family: "Graphikmedium";
  font-size: 2.3rem;
  line-height: 1.5;
  letter-spacing: 0;
}

// introduction special page
html.special-page-config-header-minimal.special-page-config-header-minimal,
html.special-page-config.special-page-config-header-minimal,
html.special-page-config,
html.special-page-config-header-minimal {
  body
    #main
    #total-main
    #vrtx-main-content
    #vrtx-frontpage-introduction
    .vrtx-introduction {
    p,
    * {
      font-size: 2.4rem;
      line-height: 1.7;
      letter-spacing: 0;
      font-family: "Graphikmedium";
    }
  }
}

// search + // Buttons
body {
  input[type="password"],
  input[type="number"],
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  .vrtx-button:not(form),
  .vrtx-button-larger:not(form),
  .button,
  .button-large,
  .button-large-blue,
  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  .ui-dialog.ui-widget button,
  button {
    letter-spacing: 0;
  }
}

// Accordion font sizes
#vrtx-frontpage,
#vrtx-structured-article,
body {
  #main {
    h2.accordion {
      font-size: 2.7rem;
    }
    h3.accordion {
      font-size: 2.3rem;
    }
    h4.accordion {
      font-size: 2rem;
    }
  }
}

// Fix line-height:
#vrtx-frontpage.marketing-container-fixed
  #marketing-customize-container
  h3.accordion {
  line-height: 30px;
}

/* person presentation fonts */
#vrtx-person {
  .vrtx-hinn-person-card {
    h1 {
      margin-bottom: 10px;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
    h2 {
      font-family: var(--mainFontStack);
      font-size: 2.7rem;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
  .vrtx-hinn-addresses-container h3 {
    margin-top: 5px;
  }
}

/* margins */
h1,
body h1,
#vrtx-structured-article h1,
#vrtx-frontpage #main .feature-full-width.full-width-picture h2 {
  margin-bottom: 20px;
}

body#vrtx-frontpage h1 {
  margin-bottom: 40px;
  margin-bottom: 60px;
}

body#vrtx-frontpage #vrtx-frontpage-introduction {
  margin-top: -30px;
}

h1 {
  margin-top: 30px; /* new */
  margin-bottom: 40px;
}

h2,
.vrtx-toc-header {
  margin-top: 50px;
  margin-bottom: 10px;
}
#vrtx-frontpage #main h2:not(.vrtx-box-content h2),
#main #vrtx-frontpage-box h2 {
  margin-bottom: 30px;
}
h3:not(.accordion-content h3) {
  margin-top: 50px;
  margin-bottom: 10px;
}
h4,
h5 {
  margin-top: 25px;
  margin-bottom: 10px;
}
h2 + h3:not(.accordion-content) {
  margin-top: 0;
}
h3 + h4,
h4 + h5 {
  margin-top: 0;
}

p {
  //margin: 10px 0 25px;
  margin: 10px 0 20px 0;
}

code {
  white-space: normal; // Remove?
}

pre {
  background-color: var(--codeBg);
  margin: 15px 0;
  padding: 15px 15px 15px 16px;
  white-space: pre;
  overflow: auto;
  overflow-y: hidden;
  border: 2px dashed var(--color-dark);
  border-left: 6px solid var(--color-dark);
  font-family: monospace, monospace;
  color: var(--textColor);
  font-size: 1.4rem;
  line-height: 1.8rem;

  &.shell,
  &.shell-green {
    background: var(--shell-green-dark);
    border: none;
    color: var(--shell-text-color);
  }
  &.shell-green {
    color: var(--shell-green);
  }
}

pre code {
  font-family: inherit;
  white-space: inherit;
}

sub,
sup {
  position: relative;
  font-size: 0.813em;
  line-height: 0;
  height: 0;
}

sup {
  bottom: 1ex;
}
sub {
  top: 0.8ex;
}

strong, b {
  font-family: var(--mediumFontStack);
  font-weight: 600;
}

/* padding */
body .ui-accordion.ui-widget h3.ui-accordion-header {
  padding-top: 15px;
  padding-bottom: 15px;
}

// image text, byline
/* image description */
.not-for-ansatte {
  .vrtx-imagetext,
  .published-date-label,
  #main .vrtx-resources .vrtx-resource .published-date,
  .published-date,
  #main .vrtx-authors,
  span.last-modified-date,
  .vrtx-event-component-misc,
  .vrtx-social-components span,
  .image-captioned figcaption,
  .results span.category,
  .results .vrtx-programme-entry span:not(.title),
  .results .vrtx-exchange-destination-entry span:not(.title),
  .active-filters,
  .clear-filters {
    color: var(--publishedDateGrey);
    font-size: 1.6rem;
    font-family: var(--smallTxtFontStack);
    line-height: 1.5;
    letter-spacing: 0.01em;
  }
}

// Responsive only
@media @responsiveBreakpoint {
  h1,
  body#vrtx-frontpage h1 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    letter-spacing: 0px;
    /* new */
    font-size: 3.2rem;
    line-height: 3.62rem;
    margin-bottom: 20px;
  }

  h2,
  blockquote,
  h2 a.read-more,
  .vrtx-toc-header,
  #vrtx-blog-listing #main .vrtx-resources .vrtx-resource a.vrtx-title,
  .not-for-ansatte .vrtx-facts-container > *:first-child,
  #vrtx-frontpage #main h2,
  #vrtx-frontpage.marketing-container-fixed #marketing-customize-container h2,
  #vrtx-frontpage #main .vrtx-frontpage-box.img-special-left h2,
  #vrtx-frontpage #main .vrtx-frontpage-box.img-special-right h2,
  #vrtx-frontpage #main .vrtx-frontpage-box.img-special-left h2 a.read-more,
  #vrtx-frontpage #main .vrtx-frontpage-box.img-special-right h2 a.read-more {
    font-size: 2.6rem;
    line-height: 3.62rem;
  }

  h3 {
    font-size: 2.3rem;
    line-height: 2.76rem;
  }

  h4 {
    font-size: 2.1rem;
    line-height: 2.9rem;
  }
  h5 {
    font-size: 1.8rem;
    line-height: 2.376rem;
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  html.special-page-config-header-minimal
    #main
    #vrtx-main-content
    #vrtx-frontpage-introduction
    .vrtx-introduction
    p,
  #main .vrtx-introduction,
  #vrtx-structured-article .vrtx-introduction,
  #vrtx-frontpage #vrtx-frontpage-introduction .vrtx-introduction,
  #vrtx-frontpage .vrtx-introduction,
  .vrtx-organizational-unit-introduction,
  .vrtx-frontpage-box.feature-full-width.full-width-picture p,
  body #main .vrtx-resources .vrtx-resource a.vrtx-title,
  .marketing-container-fixed
    #marketing-customize-container
    .marketing-customize-introduction {
    font-size: 2rem;
    line-height: 2.87rem;
  }

  body#vrtx-frontpage #vrtx-frontpage-introduction {
    margin-top: 0;
  }

  p,
  ul,
  ol {
    margin-bottom: 20px;
  }
  p + p.small-text {
    margin-top: 0px;
  }
  .main {
    p + ul,
    p + ol {
      &,
      + ol.right,
      + ul.right,
      + ul.thirds-middle,
      + ol.thirds-middle,
      + ul.thirds-right,
      + ol.thirds-right {
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
    ul,
    ol {
      p + ul,
      p + ol {
        margin-top: 0;
      }
    }

    ul,
    ol {
      margin: 20px 0 20px;
    }

    .vrtx-button:not(form),
    .vrtx-button-larger:not(form),
    .button,
    .button-large,
    .button-large-blue,
    input[type="button"],
    input[type="submit"],
    input[type="reset"],
    .ui-dialog.ui-widget button,
    button {
      font-size: 2rem;
      letter-spacing: 0;
    }
  }
  /* person presentation fonts */
  #vrtx-person {
    .vrtx-hinn-person-card {
      h2 {
        font-size: 2.3rem;
      }
    }
  }
}
//mixins
.boxSizing (@border) {
  -webkit-box-sizing: @border;
  -moz-box-sizing: @border;
  box-sizing: @border;
}
.transition (@seconds, @prop: all, @easing: ease-in-out) {
  -webkit-transition: @prop @seconds @easing;
  -moz-transition: @prop @seconds @easing;
  -o-transition: @prop @seconds @easing;
  transition: @prop @seconds @easing;
}
.floatfix() {
  float: left;
  width: 100%;
  clear: left;
}
.textUnderline(@underlineColor) {
  text-decoration: underline;
  text-decoration-color: @underlineColor;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.06em;
}
.textUnderlineHover(@underlineColor) {
  text-decoration: underline;
  text-decoration-color: @underlineColor;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.06em;
}
